import { parseISO } from "date-fns";
import dayjs from "dayjs";
import React from "react";
import toast from "react-hot-toast";
import { utils, writeFile } from "xlsx";
import CustomTable from "../../atomic/organisms/Table.organisms";
import ContainerInput from "../../components/ContainerInput";
import Template from "../../components/Template";
import { getCustomer } from "../../services/report.services";
import FiltersCustomerReport from "./ui/filter-customer";

const initalDataFilter = {
  companyId: null,
  filterMonth: [
    parseISO(dayjs().format("YYYY-MM-DD")),
    parseISO(dayjs().format("YYYY-MM-DD")),
  ],
  program: "",
  group: "",
};

const CustomerReport = () => {
  const [data, setData] = React.useState([]);
  const [load, setLoad] = React.useState(false);

  const onSearchData = async (value) => {
    if (value.companyId === null) {
      toast.error("Debe seleccionar una empresa.");
      return;
    }

    if (value.filterMonth.length === 0) {
      toast.error("Debe seleccionar un periodo.");
      return;
    }

    value = {
      ...value,
      filterMonth: [
        dayjs(value.filterMonth[0]).utc().format("YYYY-MM-DD"),
        dayjs(value.filterMonth[1]).utc().format("YYYY-MM-DD"),
      ],
    };

    setLoad(true);

    try {
      const response = await getCustomer(value);
      setLoad(false);
      console.log(response);

      setData(response);
    } catch (err) {
      setData([]);
      setLoad(false);
      console.log(err);
    }
  };

  const onDownloadCSV = () => {
    if (data.length === 0) {
      toast.error("No hay registros para descargar.");

      return;
    }

    const DATE = dayjs().format("YYYY-MM-DD");

    let newData = data.map((el) => ({
      Nombres: `${el?.nombre_reserva || ""} ${
        el?.apellido_paterno_cliente || ""
      } ${el?.apellido_materno_cliente || ""}`,
      Teléfono: el?.telefono_reserva || "",
      Programa: el?.pograma_reserva || "",
      Segmentación: el?.name_rservagrupa || "",
      PAX: el?.pax_reserva || "",
      Región: el?.region || "",
      Comuna: el?.comunas || "",
      Dirección: el?.direccion || "",
      Fecha: dayjs(el?.fechareserva_reserva).utc().format("DD/MM/YYYY HH:mm"),
    }));

    let wb = utils.book_new();
    let ws = utils.json_to_sheet(newData);
    utils.book_append_sheet(wb, ws, `Clientes`);
    writeFile(wb, `${"Clientes"}-${DATE}.xlsx`);
  };

  // useEffect(() => {
  //   onSearchData(initalDataFilter);
  // }, []);

  return (
    <Template
      title="Reporte de clientes"
      classmaincontainer="absolute top-16 bottom-0 left-0 right-0"
    >
      <FiltersCustomerReport
        onSearchData={onSearchData}
        initialState={initalDataFilter}
        onLoadData={load}
        handlePrint={onDownloadCSV}
        rowsNumber={data.length}
      />

      <ContainerInput className="p-0">
        <CustomTable
          columns={[
            {
              id: "id_cliente",
              label: "Nombres",
              minWidth: 80,
              align: "left",
              format: (el) => {
                const filterNameInfo = data.find(
                  (item) => Number(item.id_cliente) === Number(el)
                );

                const name = filterNameInfo?.nombre_reserva || "";
                const firstlastname =
                  filterNameInfo?.apellido_paterno_reserva || "";
                const secondlastname =
                  filterNameInfo?.apellido_materno_reserva || "";

                const fullName = `${name} ${firstlastname} ${secondlastname}`;

                return <div className="text-left capitalize">{fullName}</div>;
              },
            },
            {
              id: "region",
              label: "Región",
              minWidth: 80,
              align: "left",
              format: (el) => {
                return <div className="text-left">{el}</div>;
              },
            },
            {
              id: "comunas",
              label: "Comuna",
              minWidth: 80,
              align: "left",
              format: (el) => {
                return <div className="text-left">{el}</div>;
              },
            },
            {
              id: "pograma_reserva",
              label: "Programa",
              minWidth: 80,
              align: "left",
              format: (el) => {
                return <div className="text-left uppercase">{el}</div>;
              },
            },
            {
              id: "name_rservagrupa",
              label: "Segmentación",
              minWidth: 80,
              align: "left",
              format: (el) => {
                return <div className="text-left">{el}</div>;
              },
            },
            {
              id: "pax_reserva",
              label: "PAX",
              minWidth: 80,
              align: "left",
              format: (el) => {
                return <div className="text-left">{el}</div>;
              },
            },
            {
              id: "telefono_reserva",
              label: "Teléfono",
              minWidth: 80,
              align: "left",
              format: (el) => {
                return <div className="text-left">(+56) {el}</div>;
              },
            },
            {
              id: "correo_reserva",
              label: "Correo",
              minWidth: 80,
              align: "left",
              format: (el) => {
                return <div className="text-left">{el}</div>;
              },
            },
            {
              id: "fechareserva_reserva",
              label: "Fecha de reserva",
              minWidth: 80,
              align: "left",
              format: (el) => {
                return (
                  <div className="text-left">
                    {dayjs(el).format("DD/MM/YYYY")}
                  </div>
                );
              },
            },
          ]}
          rows={data}
          load={load}
          error={false}
          disableButtons={false}
          pagination={true}
          rowsPerPagination={100}
        />
      </ContainerInput>
    </Template>
  );
};

export default CustomerReport;
