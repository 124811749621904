import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import ContainerInput from "../../../components/ContainerInput";
import Template from "../../../components/Template";
import {
  createDetailCustomerRifa,
  fetchDetailCustomerRifaByOne,
  fetchDetailRifa,
  updateDetailCustomerRifa,
} from "../../../services/rifa.services";

import { Edit, Save } from "@mui/icons-material";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { getComunas } from "../../../functions/utilidades.function";
import DynamicTable from "../../planning/ui/dynamicTable";
import RifaBreadCrumb from "../bread-crumb.rifa";

const DetailRifasCustomer = () => {
  const { id } = useParams();

  const [dataRifaCustomer, setDataRifaCustomer] = useState([]);
  const [dataRifa, setDataRifa] = useState(null);
  const [dataCommunes, setDataCommunes] = useState([]);

  const [loading, setLoading] = useState(false);

  const raffleTicketsSold = useCallback(() => {
    return dataRifaCustomer.filter((el) => el?.id_cliente > 0).length;
  }, [dataRifaCustomer]);

  async function fetchData() {
    setLoading(true);
    try {
      const response = await fetchDetailCustomerRifaByOne(id);

      setDataRifaCustomer(response);
    } catch (err) {
      setDataRifaCustomer([]);
    } finally {
      setLoading(false);
    }
  }

  async function fetchDataRifa() {
    setLoading(true);
    try {
      let response = await fetchDetailRifa();

      response = response.find((el) => Number(el.id_rdetail) === Number(id));

      setDataRifa(response);
    } catch (err) {
      setDataRifa([]);
    } finally {
      setLoading(false);
    }
  }

  async function handleSave(data, item) {
    if (!data?.telf_cliente || data?.telf_cliente === "") {
      toast.error("El teléfono es obligatorio");
      return;
    }

    let findComuna = dataCommunes.find(
      (el) => el.label === data?.comuna_cliente
    );

    const newData = {
      ...data,
      comuna_cliente: findComuna?.label || "",
    };

    if (item) {
      await updateDetailCustomerRifa(newData);
    } else {
      await createDetailCustomerRifa({
        ...newData,
        id_rdetail: id,
      });
    }

    await fetchData();
  }

  const handleGetRegiones = () => {
    const region = getComunas();

    let comunas = [];

    region.forEach((el) => {
      comunas.push(...el.comunas);
    });

    comunas = comunas.map((el, idx) => ({
      value: el,
      label: el,
    }));

    setDataCommunes(comunas);
  };

  useEffect(() => {
    fetchDataRifa();
    handleGetRegiones();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Template title="Detalle de Rifas">
      <div className="pb-5">
        <RifaBreadCrumb
          name={dataRifa?.name_rifa || ""}
          link={"/my-rifas"}
          linkName={"Mis rifas"}
        />
      </div>

      <h2 className="text-1xl font-bold mb-3">Detalle de rifas</h2>
      <div className="grid grid-cols-12 gap-3 bg-slate-300 p-4 rounded-md mb-5 text-sm">
        <div className="col-span-12 sm:col-span-3">
          Nombre:{" "}
          <span className="block text-purple-900 font-semibold">
            {dataRifa?.name_rifa || ""}{" "}
          </span>
        </div>
        <div className="col-span-12 sm:col-span-3">
          Cantidad por rifa:{" "}
          <span className="block text-purple-900 font-semibold">
            {raffleTicketsSold()} de {dataRifa?.amount_rifa || 0}
          </span>
        </div>

        <div className="col-span-12 sm:col-span-3">
          Fecha creación:
          <span className="block text-purple-900 font-semibold">
            {dayjs(dataRifa?.createdat_rifa).format("DD/MM/YYYY")}
          </span>
        </div>
        <div className="col-span-12 sm:col-span-3">
          Fecha de sorteo:
          <span className="block text-purple-900 font-semibold">
            {dayjs(dataRifa?.drawdate_rifa).format("DD/MM/YYYY")}
          </span>
        </div>
        <div className="col-span-12 sm:col-span-12">
          Observaciones:
          <span className="block text-purple-900 font-semibold">
            {dataRifa?.observations_rifa || 0}
          </span>
        </div>
      </div>

      <ContainerInput className="!p-0">
        <DynamicTable
          load={loading}
          isReadMode={false}
          columns={[
            {
              id: "number_rcustomer",
              label: "Número de rifa",
              minWidth: 120,
              align: "left",
              input: "number",
              disable: true,
            },
            {
              id: "nombre_cliente",
              label: "Nombres",
              minWidth: 200,
              align: "left",
              input: "text",
            },
            {
              id: "apepaterno_cliente",
              label: "Apellido paterno",
              minWidth: 200,
              align: "left",
              input: "text",
            },
            {
              id: "apematerno_cliente",
              label: "Apellido materno",
              minWidth: 200,
              align: "left",
              input: "text",
            },
            {
              id: "correo_cliente",
              label: "Correo electrónico",
              minWidth: 200,
              align: "left",
              input: "text",
            },
            {
              id: "comuna_cliente",
              label: "Comuna",
              minWidth: 200,
              align: "left",
              input: "select",
              options: dataCommunes,
            },
            // {
            //   id: "comuna_cliente",
            //   label: "Comuna",
            //   minWidth: 200,
            //   align: "left",
            //   input: "autocomplete",
            //   autoCompleteOptions: dataCommunes,
            // },
            {
              id: "telf_cliente",
              label: "Teléfono",
              minWidth: 200,
              align: "left",
              input: "text",
            },
            {
              id: "number_rcustomer",
              label: "Guardar",
              minWidth: 140,
              align: "left",
              format: (item) => {
                let data = dataRifaCustomer.find(
                  (el) => Number(el.number_rcustomer) === Number(item)
                );

                return (
                  <button
                    className={`${
                      data?.id_rcustomer ? "bg-purple-700" : "bg-green-500"
                    }  text-white px-2 py-1 rounded-md`}
                    onClick={() => handleSave(data, data?.id_rcustomer)}
                  >
                    {data?.id_rcustomer ? (
                      <>
                        <Edit /> Editar
                      </>
                    ) : (
                      <>
                        <Save /> Guardar
                      </>
                    )}
                  </button>
                );
              },
            },
          ]}
          rows={dataRifaCustomer}
          setRows={setDataRifaCustomer}
        />
      </ContainerInput>
    </Template>
  );
};

export default DetailRifasCustomer;
