import React from "react";

import {
  get_empresa_id,
  get_empresa_alias,
} from "../services/empresa.services";

const useEmpresa = (value, type = "id") => {
  const [dataEmpresa, setDataEmpresa] = React.useState({
    data: false,
    loading: false,
    err: false,
  });

  React.useEffect(() => {
    async function obtainEmpresa() {
      try {
        setDataEmpresa({ data: false, load: true, err: false });
        const response =
          type === "id"
            ? await get_empresa_id("empSedeId", value)
            : type === "empId"
            ? await get_empresa_id("empId", value)
            : await get_empresa_alias(value);

        setDataEmpresa({ data: response, load: false, err: false });
      } catch (err) {
        console.log(err);
        setDataEmpresa({ data: false, load: false, err: true });
      }
    }

    obtainEmpresa();

    return null;
  }, [value, type]);

  return dataEmpresa;
};

export default useEmpresa;
