import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomTable from "../../../atomic/organisms/Table.organisms";
import ContainerInput from "../../../components/ContainerInput";
import Template from "../../../components/Template";
import { fetchDetailRifa } from "../../../services/rifa.services";
import FilterRifa from "../filters.rifa";
import ModalRifa from "../modal-rifa";

const CustomerRifas = () => {
  const [dataRifa, setDataRifa] = useState([]);

  const [loading, setLoading] = useState(false);
  const [openCrud, setOpenCrud] = useState(false);
  const [rifaSelected, setRifaSelected] = useState(null);

  async function fetchData() {
    setLoading(true);
    try {
      const response = await fetchDetailRifa();

      setDataRifa(response);
    } catch (err) {
      setDataRifa([]);
    } finally {
      setLoading(false);
    }
  }

  async function handleSearch(value) {
    let response = await fetchDetailRifa();

    if (value?.searchName !== "") {
      response = response.filter((el) => {
        const name_rifa = el?.name_rifa ? el?.name_rifa.toLowerCase() : "";
        const observations_rifa = el?.observations_rifa
          ? el?.observations_rifa.toLowerCase()
          : "";

        return (
          name_rifa.includes(value.searchName.toLowerCase()) ||
          observations_rifa.includes(value.searchName.toLowerCase())
        );
      });
    }

    setDataRifa(response);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Template title="Mis rifas">
      <FilterRifa
        canCreate={false}
        onLoadData={loading}
        onSearchData={handleSearch}
        onOpenAddModal={setOpenCrud}
      />

      <ContainerInput className="!p-0">
        <CustomTable
          columns={[
            {
              id: "id_rdetail",
              label: "Nombre",
              minWidth: 90,
              align: "left",
              format: (el) => {
                let findRifa = dataRifa.find((rifa) => rifa.id_rdetail === el);

                return (
                  <Link
                    to={`/my-rifas/${findRifa?.id_rdetail || ""}`}
                    className="text-xs underline text-blue-800 hover:text-blue-800 font-semibold"
                  >
                    {findRifa?.name_rifa || ""}
                  </Link>
                );
              },
            },
            {
              id: "amount_rifa",
              label: "Cantidad por rifa",
              minWidth: 30,
              align: "left",
            },
            {
              id: "drawdate_rifa",
              label: "Fecha de sorteo",
              minWidth: 80,
              align: "left",
              format: (el) => {
                return dayjs(el).utc().format("DD/MM/YYYY");
              },
            },
            {
              id: "observations_rifa",
              label: "Observaciones",
              minWidth: 80,
              align: "left",
            },
            {
              id: "createdat_rifa",
              label: "Fecha de creación",
              minWidth: 80,
              align: "left",
              format: (el) => {
                return dayjs(el).utc().format("DD/MM/YYYY");
              },
            },
          ]}
          rows={dataRifa}
          load={loading}
          error={false}
          pagination={true}
        />
      </ContainerInput>

      <ModalRifa
        rifaSelected={rifaSelected}
        clearSelected={() => setRifaSelected(null)}
        open={openCrud}
        setOpen={setOpenCrud}
        callBack={() => {
          fetchData();
        }}
      />
    </Template>
  );
};

export default CustomerRifas;
