/* eslint-disable no-unused-vars */
import React, { useState } from "react";

import { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import Head from "../../components/Head";

import { Alert } from "@mui/material";
import { motion } from "framer-motion";
import FormReserva from "../reservas/formReserva";

const fadeIn = (direction, delay) => {
  return {
    hidden: {
      y: direction === "up" ? 80 : direction === "down" ? -80 : 0,
      opacity: 0,
      x: direction === "left" ? 80 : direction === "right" ? -80 : 0,
    },
    show: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: {
        type: "tween",
        duration: 0.5,
        delay: delay,
        ease: [0.25, 0.25, 0.25, 0.75],
      },
    },
  };
};

const PublicReservations = () => {
  const { userId } = useParams();

  const TITLE = "Reservar";

  const [reservationAdded, setReservationAdded] = useState(false);

  //RCS = 4
  // 5 = Echaurren
  // 8 = Bulnes
  // 14 = Eastman

  //GALA = 7

  const empId = 4;
  const empSedeId = 5;

  return (
    <div className="w-full p-5">
      <Head>
        <meta charSet="utf-8" />
        <title>{TITLE}</title>
        <meta name="description" content="POS" />
        <title>{`Reservar`}</title>
      </Head>
      <main>
        {!reservationAdded && (
          <motion.div
            variants={fadeIn("down", 1.4)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.1 }}
            className="form-inputs"
          >
            <FormReserva
              isPublic={true}
              idEmp={empId}
              idUser={userId}
              idEmpSede={empSedeId}
              callback={() => {
                setReservationAdded(true);
              }}
            />
          </motion.div>
        )}

        {reservationAdded && (
          <div className="w-full text-center center p-10">
            <Alert severity="success">
              ¡Gracias!, Tu reserva ya está registrada.
            </Alert>
          </div>
        )}
      </main>
      <Toaster />
    </div>
  );
};

export default PublicReservations;
