import * as React from "react";
import { Link, useHistory } from "react-router-dom";

import { TableBar } from "@mui/icons-material";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

import { AddShoppingCart, ExpandMoreOutlined } from "@mui/icons-material";

import "moment/locale/es";
import Moment from "react-moment";

import NotFound from "../../../atomic/organisms/NotFound.organisms";
import ContainerInput from "../../../components/ContainerInput";
import { nameCamarero } from "../../../functions/nameCamarero";

import { clearText } from "../../../functions/utilidades.function";
import dayjs from "dayjs";

/*
  TABLE 
*/

const ItemAcordion = ({ row }) => {
  let history = useHistory();

  const toUpperCaseFilter = (d) => {
    if (!d) {
      return d;
    }

    if (d === "") {
      return d;
    }

    return d.toUpperCase();
  };

  const handleRedirectCartProduct = (codigo_pedido) => {
    history.push(`/orders/add/${codigo_pedido}`);
  };

  const itemDisabled = row.id_estadopedido === 4 || row.id_estadopedido === 5;

  const clientName = row?.id_reserva
    ? `${clearText(row?.nombre_reserva)} ${clearText(
        row?.apellido_paterno_reserva
      )} ${clearText(row?.apellido_materno_reserva)}`
    : `${clearText(row?.nombre_cliente)} ${clearText(
        row?.apepaterno_cliente
      )} ${clearText(row?.apematerno_cliente)}`;

  return (
    <Paper
      variant="outlined"
      style={{
        margin: 10,
        width: 300,
        minWidth: 300,
        display: "inline-block",
      }}
    >
      <div
        style={{
          padding: 8,
          borderBottom: "1px solid #dfdfdf",
          display: "inline-flex",
          width: "100%",
        }}
      >
        <div style={{ width: "70%", textAlign: "left" }}>
          <Link
            to={`orders/view/${row.codigo_pedido}`}
            style={{
              textDecoration: "revert",
              color: "#0c0cb8",
            }}
          >
            {row.codigo_pedido}
          </Link>
        </div>
        <div
          style={{
            width: "30%",
            textAlign: "right",
          }}
        >
          {dayjs.utc(row.fechareg_pedido).format("HH:mm")}
        </div>
      </div>

      <div
        style={{
          padding: 8,
          display: "inline-flex",
          width: "100%",
        }}
      >
        <div style={{ width: "auto", textAlign: "left" }}>
          <Chip
            size="small"
            className="w-max"
            label={
              <>
                {!row.codigo_mesa ? (
                  <>Directo</>
                ) : (
                  <>
                    <TableBar style={{ fontSize: 12 }} />
                    Mesa #{row.codigo_mesa}
                  </>
                )}
              </>
            }
            variant="outlined"
          />
        </div>
        <div
          style={{
            width: "80%",
            textAlign: "right",
            textTransform: "uppercase",
          }}
        >
          {/* <Chip label={row.nombre_estadopedido} /> */}
        </div>
      </div>
      <div
        style={{
          textTransform: "uppercase",
          textAlign: "left",
          padding: 5,
        }}
      >
        {row?.typecaja_pedido === 1 && (
          <>
            {!clientName ? (
              <>
                <b>Cliente:</b> Desconocido
              </>
            ) : (
              <>
                <b>Cliente:</b> {clientName}
              </>
            )}
          </>
        )}
        {row?.typecaja_pedido === 2 && (
          <>
            <>
              <b>Cliente:</b> {row.fullname_pedido}
            </>
          </>
        )}
      </div>
      <div
        style={{
          textTransform: "uppercase",
          textAlign: "left",
          padding: 5,
        }}
      >
        <b>{nameCamarero()}:</b> {row.usu_nombre}
      </div>

      <div
        style={{
          textTransform: "uppercase",
          textAlign: "left",
          padding: 5,
        }}
      >
        <b>Productos:</b> {row.rowsProductos}
      </div>
      <div
        style={{
          padding: 5,
        }}
      >
        <Button
          disabled={itemDisabled ? true : false}
          fullWidth
          color="inherit"
          style={{
            background: itemDisabled ? "silver" : "",
            color: itemDisabled ? "white" : "green",
            border: itemDisabled ? "2px transparent solid" : "2px green solid",
          }}
          size="small"
          onClick={() => handleRedirectCartProduct(row.codigo_pedido)}
        >
          <AddShoppingCart /> AGREGAR
        </Button>
      </div>
    </Paper>
  );
};

const Acordion = (props) => {
  let { data, idx } = props;

  const rows = data;
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={expanded === idx}
      onChange={handleChange(idx)}
      style={{
        marginBottom: 5,
        boxShadow: "none",
        borderRadius: 8,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreOutlined />}
        aria-controls={`${idx}-panel1bh-header`}
        id={`${idx}-panel1bh-header`}
        style={{
          width: "100%",
          border: expanded === idx ? "1px #e0024d solid" : "1px #cbcbcb solid",
          color: expanded === idx && "#e0024d",
          borderRadius: 10,
        }}
      >
        <Typography sx={{ width: "100%" }}>
          {rows.label} ({rows.rows.length})
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        <ContainerInput
          style={{
            padding: "15px 10px",
            textAlign: "center",
            marginTop: "5px",
          }}
        >
          {data.rows.map((data) => (
            <ItemAcordion row={data} idx={idx} />
          ))}
        </ContainerInput>
      </AccordionDetails>
    </Accordion>
  );
};

const TableBodyComponent = (props) => {
  let { dataPedido } = props;

  if (dataPedido.load) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (dataPedido.err) {
    return <NotFound texto="¡Ocurrió un problema!" />;
  }

  if (dataPedido.data.length === 0 && !dataPedido.load) {
    return <NotFound texto="No se encontró registros." />;
  }

  return (
    <Grid container>
      {dataPedido.data.map((row, idx) => {
        return (
          <Grid item xs={12}>
            <Acordion idx={idx} key={idx} data={row} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default TableBodyComponent;
