import * as React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import {
  Checkbox,
  Chip,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";

import { ShoppingCart } from "@mui/icons-material";

import { useDispatch, useSelector } from "react-redux";
import { actionTypes } from "../../../../../redux/carta/actions";

import Input from "../../../../../components/Forms/Input";
import InputCount from "./Components/Count";

import { formatMoney } from "../../../../../functions/formatMoney";
import "./style.cart.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogContainer = ({ data, onClose, ...props }) => {
  if (data === 0) {
    return (
      <DialogContent style={{ paddingTop: 20 }}>
        <div className="emptyText">
          <Typography variant="h5" component="h5">
            Tu carito esta vacío =(
          </Typography>{" "}
          <br />
          <Typography variant="span" component="span">
            Cuando tengas algo en tu carrito podrás continuar, esto aparecerá
            aquí. ¿Empezamos?
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            style={{ display: "block", margin: "auto", marginTop: "20px" }}
            onClick={onClose}
          >
            Iniciar
          </Button>
        </div>
      </DialogContent>
    );
  }

  return (
    <DialogContent className="dialogContainer">{props.children}</DialogContent>
  );
};

const DialogFooter = ({ data, ...props }) => {
  if (data === 0) {
    return null;
  }

  return <DialogActions {...props}>{props.children}</DialogActions>;
};

const ItemRow = (props) => {
  let { idx, item, handleAddProduct, handleText, handle2x1, priceProducts } =
    props;

  return (
    <ListItem key={idx} className="listMiniCart__listitem">
      <Grid container spacing={1}>
        <Grid item className="imageItem" xs={12} sm={8} lg={8}>
          <div className="container__title">
            <div className="__name">
              {item.nombre_plato}{" "}
              {item.amount_proagotado && (
                <Chip
                  label={`Disponible ${item.amount_proagotado}`}
                  color="warning"
                  size="small"
                />
              )}
            </div>
            <div className="__category">{item.nombre_categoriapla}</div>
            <div className="__price">{formatMoney(priceProducts)}</div>
          </div>
        </Grid>
        <Grid item xs={12} sm={4} lg={4}>
          <div className="w-max mb-4">
            Aplicar 2x1{" "}
            <Checkbox
              onChange={() => handle2x1(item.id_plato, !item.dosXuno)}
              checked={item?.dosXuno === true ? true : false}
            />
          </div>
          <div className="container__count">
            <InputCount
              style={{ width: "100%" }}
              addCount={(el) => handleAddProduct(item, el)}
              maxCount={item.amount_proagotado}
              valueTotAmount={item.totAmount}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <Input
            fullWidth
            value={item.text}
            type="textarea"
            title="Descripción"
            minRows={2}
            onChange={(e) => handleText(item.id_plato, e.target.value)}
          />
        </Grid>
      </Grid>
    </ListItem>
  );
};

/*
    MAIN COMPONENT
*/

const Cart = (props) => {
  let { open, setOpen, handleCreatePedido } = props;

  const dispatch = useDispatch();

  const { products, priceProducts } = useSelector(
    (state) => state.reducer_pedido
  );

  const onUpdateProduct = (rows) => {
    dispatch({
      type: actionTypes.updateProductText,
      payload: { rows },
    });
  };

  const onAddProduct = (row, count) => {
    dispatch({
      type: actionTypes.addProduct,
      payload: { row, count },
    });
  };

  const onProductsDelete = (index) => {
    dispatch({
      type: actionTypes.deleteProduct,
      payload: { idx_delete: index },
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRedirect = () => {
    handleClose();
    handleCreatePedido();
  };

  const handleAddProduct = (product, count = 1) => {
    if (parseInt(count) > 0) {
      onAddProduct(product, parseInt(count));
    } else {
      let findIdxProduct = products.findIndex(
        (el) => el.id_plato === product.id_plato
      );

      onProductsDelete(findIdxProduct);
    }
  };

  const handle2x1 = (id_plato, is2x1 = true) => {
    const idx = products.findIndex((el) => el.id_plato === id_plato);

    if (is2x1) {
      products[idx] = { ...products[idx], dosXuno: true };
    } else {
      products[idx] = { ...products[idx], dosXuno: false };
    }

    onUpdateProduct(products);
  };

  const handleText = (id_plato, texto) => {
    const idx = products.findIndex((el) => el.id_plato === id_plato);

    products[idx] = { ...products[idx], texto };

    onUpdateProduct(products);
  };

  return (
    <Dialog
      fullWidth
      open={open}
      TransitionComponent={Transition}
      maxWidth={"sm"}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle style={{ borderBottom: "1px solid #cfcfcf" }}>
        <ShoppingCart style={{ fontSize: 18, marginRight: 5 }} />
        {"Carrito"}
      </DialogTitle>
      <DialogContainer
        data={products.length}
        style={{ padding: 0 }}
        onClose={handleClose}
      >
        <List component="ul" className="listMiniCart">
          {products.map((item, idx) => (
            <ItemRow
              key={idx}
              idx={idx}
              item={item}
              priceProducts={priceProducts}
              handleAddProduct={handleAddProduct}
              handle2x1={handle2x1}
              handleText={handleText}
            />
          ))}
        </List>
      </DialogContainer>
      <DialogFooter
        data={products.length}
        style={{ borderTop: "1px solid #cfcfcf" }}
      >
        <Button color="inherit" variant={"text"} onClick={handleClose}>
          Salir
        </Button>
        <Button color={"error"} variant={"contained"} onClick={handleRedirect}>
          Pedir
          {/* <Currency
            currency="CLP"
            quantity={priceProducts}
            pattern="$ ##,### "
          /> */}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default Cart;
