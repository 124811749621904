/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";

import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import Head from "../../../components/Head";

import { Button, Grid } from "@mui/material";
import { motion } from "framer-motion";
import { Controller, useForm } from "react-hook-form";
import InputDefault from "../../../atomic/atoms/input.atoms";
import { getComunas } from "../../../functions/utilidades.function";
import {
  createDetailCustomerRifaPublic,
  deleteDetailCustomerRifaPublic,
  fetchDetailCustomerRifaByOnePublic,
  fetchDetailRifaByOnePublic,
  fetchRifaPublic,
  updateDetailCustomerRifaPublic,
} from "../../../services/rifa.services";
import dayjs from "dayjs";

const fadeIn = (direction, delay) => {
  return {
    hidden: {
      y: direction === "up" ? 80 : direction === "down" ? -80 : 0,
      opacity: 0,
      x: direction === "left" ? 80 : direction === "right" ? -80 : 0,
    },
    show: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: {
        type: "tween",
        duration: 0.5,
        delay: delay,
        ease: [0.25, 0.25, 0.25, 0.75],
      },
    },
  };
};

const DEFAULT_RAFFLE_NUMBER = 35;

const defaultValues = {
  number_rcustomer: null,
  nombre_cliente: null,
  apepaterno_cliente: null,
  apematerno_cliente: null,
  correo_cliente: null,
  comuna_cliente: null,
  telf_cliente: null,
};

const RaffleBox = ({
  raffleNumberSelected,
  raffleNumber,
  onRaffleSelected,
  dataRaffles,
}) => {
  const generateBoxes = (number, raffles, raffleSelected) => {
    if (typeof number !== "number" || number <= 0) {
      return <div>No se puede generar cajas sin un número válido.</div>;
    }

    const boxes = [];
    for (let i = 0; i < number; i++) {
      const rafflesData = raffles.find((el) => el.number_rcustomer === i + 1);
      const disabled = rafflesData?.id_rdetail > 0;

      boxes.push(
        <div
          key={i}
          className={`
            ${
              raffleSelected === i + 1
                ? "border-4 border-spacing-1 border-red-950"
                : ""
            }
            ${disabled ? "bg-gray-400" : "bg-red-600  hover:bg-red-700"}
            cursor-pointer
            text-white
            p-4 
            rounded-md 
            mt-1
            w-10 
            h-10 
            flex 
            items-center
            justify-center`}
          onClick={() => {
            onRaffleSelected?.(i + 1);
          }}
        >
          {i + 1}
        </div>
      );
    }

    return boxes;
  };

  return (
    <div className="flex flex-wrap w-full gap-1">
      {generateBoxes(raffleNumber, dataRaffles, raffleNumberSelected)}
    </div>
  );
};

const RaffleForm = ({
  handleSubmit,
  handleDeleteRaffle,
  onSubmit,
  control,
  raffleNumber = DEFAULT_RAFFLE_NUMBER,
  watch,
  loading,
}) => {
  const [regiones, setRegiones] = React.useState([]);

  const handleGetRegiones = () => {
    const dataRegiones = getComunas();

    setRegiones(
      dataRegiones.map((el) => ({
        label: el.region,
        value: el.region,
        comunas: el.comunas.map((el) => ({
          label: el,
          value: el,
        })),
      }))
    );
  };

  useEffect(() => {
    handleGetRegiones();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1} className="!mt-2">
        <Grid item xs={12} sm={12} lg={12}>
          <Controller
            name="nombre_cliente"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                value={value || ""}
                onChange={onChange}
                type="text"
                title="Nombre"
                loading={loading}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <Controller
            name="apepaterno_cliente"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                value={value || ""}
                onChange={onChange}
                type="text"
                title="Apellido paterno"
                loading={loading}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <Controller
            name="apematerno_cliente"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                value={value || ""}
                onChange={onChange}
                type="text"
                title="Apellido materno"
                loading={loading}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <Controller
            name="telf_cliente"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                value={value || ""}
                onChange={onChange}
                type="tel"
                title="Teléfono"
                loading={loading}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <Controller
            name="region_cliente"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                value={value || ""}
                onChange={onChange}
                type="select"
                title="Región"
                loading={loading}
                options={regiones}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <Controller
            name="comuna_cliente"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                value={value || ""}
                onChange={onChange}
                type="select"
                title="Comunas"
                loading={loading}
                options={
                  regiones.find((el) => el.value === watch("region_cliente"))
                    ?.comunas || []
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <Controller
            name="correo_cliente"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                value={value || ""}
                onChange={onChange}
                type="email"
                title="Correo electrónico"
                loading={loading}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          lg={12}
          className="flex justify-end gap-2 !pt-4"
        >
          <Button
            disabled={watch("number_rcustomer") === null || loading}
            variant="contained"
            size="medium"
            type="submit"
            color="secondary"
          >
            {watch("id_rcustomer") ? "Editar" : "Guardar"}
          </Button>

          {watch("id_rcustomer") && (
            <Button
              disabled={watch("number_rcustomer") === null || loading}
              variant="contained"
              size="medium"
              type="button"
              color="error"
              onClick={() => handleDeleteRaffle(watch("id_rcustomer"))}
            >
              Eliminar
            </Button>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

const PublicCustomer = () => {
  const { rifaCode, rifaDetailCode } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [loadingDetail, setLoadingDetail] = React.useState(false);

  const [isNotFound, setIsNotFound] = React.useState(false);

  const TITLE = "Mis Rifas";

  const [dataRifa, setDataRifa] = React.useState({
    name_rifa: "",
    type_rifa: "",
    amount_rifa: 0,
    description_rifa: "",
  });
  const [dataDetailCustomerRifa, setDataDetailCustomerRifa] = React.useState(
    []
  );
  const [dataDefailRifa, setDataDefailRifa] = React.useState([
    {
      usu_nombre: "",
    },
  ]);

  const { control, reset, handleSubmit, watch } = useForm({
    defaultValues,
  });

  const empId = 4;
  const empSedeId = 5;

  const onRaffleSubmit = async (data) => {
    if (!data?.telf_cliente || data?.telf_cliente === "") {
      toast.error("El teléfono es obligatorio");
      return;
    }

    setLoadingDetail(true);

    if (data?.id_rcustomer) {
      await updateDetailCustomerRifaPublic(
        { ...data, id_rdetail: rifaDetailCode },
        empId,
        empSedeId
      );

      toast.success("Rifa actualizada correctamente");
    } else {
      await createDetailCustomerRifaPublic(
        {
          ...data,
          id_rdetail: rifaDetailCode,
        },
        empId,
        empSedeId
      );

      toast.success("Rifa creada correctamente");

      reset(defaultValues);
    }

    await fetchDataCustomerRifas(rifaDetailCode);
    setLoadingDetail(false);
  };

  const onRaffleDelete = async (id) => {
    setLoadingDetail(true);

    await deleteDetailCustomerRifaPublic(id, empId, empSedeId);

    await fetchDataCustomerRifas(rifaDetailCode);
    setLoadingDetail(false);

    reset(defaultValues);
  };

  const handleRaffleNumber = (raffleNumber) => {
    const data = dataDetailCustomerRifa.find(
      (el) => Number(el?.number_rcustomer) === raffleNumber
    );

    reset({
      ...data,
      number_rcustomer: raffleNumber,
    });
  };

  async function fetchDataRifa(id) {
    setLoading(true);
    try {
      let response = await fetchRifaPublic(empSedeId);
      response = response.find((el) => Number(el.id_rifa) === Number(id));

      if (!response) {
        setIsNotFound(true);
        return;
      }

      setDataRifa(response);
      setIsNotFound(false);
    } catch (err) {
      console.log("err", err);
      setDataRifa(null);
    } finally {
      setLoading(false);
    }
  }

  async function fetchDataCustomerRifas(id) {
    setLoadingDetail(true);
    try {
      const response = await fetchDetailCustomerRifaByOnePublic(id);

      setDataDetailCustomerRifa(response);
    } catch (err) {
      setDataDetailCustomerRifa([]);
      setLoadingDetail(false);
    } finally {
      setLoadingDetail(false);
    }
  }

  async function fetchDataRifaDetail(id) {
    setLoading(true);
    try {
      const response = await fetchDetailRifaByOnePublic(id);

      if (response.length === 0) {
        setIsNotFound(true);
        return;
      }

      setIsNotFound(false);
      setDataDefailRifa(response);
    } catch (err) {
      setDataDefailRifa([]);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchDataRifa(rifaCode);
  }, [rifaCode]);

  useEffect(() => {
    fetchDataRifaDetail(rifaDetailCode);
  }, [rifaDetailCode]);

  useEffect(() => {
    fetchDataCustomerRifas(rifaDetailCode);
  }, [rifaDetailCode]);

  if (loading) {
    return (
      <div className="w-full h-screen bg-gray-100">
        <main className="flex flex-col justify-center items-center h-full">
          <h1 className="font-bold text-center mb-3 text-xl">Cargando...</h1>
        </main>
      </div>
    );
  }

  if (isNotFound) {
    return (
      <div className="w-full h-screen bg-gray-100">
        <main className="flex flex-col justify-center items-center h-full">
          <div className="text-2xl mb-2 text-purple-800">¯\_(ツ)_/¯</div>
          <h1 className="font-bold text-center mb-3 text-xl">
            Lo sentimos, no se encontró la rifa o tal vez fue eliminada.
          </h1>
        </main>
      </div>
    );
  }

  return (
    <div className="w-full h-screen p-5 bg-gray-100">
      <Head>
        <meta charSet="utf-8" />
        <title>{TITLE}</title>
        <meta name="description" content="POS" />
        <title>{`Mis rifas`}</title>
      </Head>
      <main className="flex flex-col justify-center items-center h-full">
        <motion.div
          variants={fadeIn("down", 0.5)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: 0.3 }}
          className="w-full max-w-md mx-auto p-5 bg-white rounded-md shadow-md"
        >
          <h1 className="font-bold text-center mb-3 text-xl uppercase">
            {dataRifa?.name_rifa} <br /> ({dataDefailRifa[0].usu_nombre})
          </h1>
          <h2 className="font-bold text-center mb-3 text-lg">
            {dataRifa?.type_rifa}
          </h2>

          <p className="text-left mb-3 text-sm text-gray-500 mt-4">
            La fecha de la rifa es el:{" "}
            <b> {dayjs(dataRifa?.drawdate_rifa).format("DD/MM/YYYY")}</b>
          </p>

          <p className="text-left mb-3 text-sm text-gray-500 mt-4">
            Selecciona el número de rifa y completa los datos.
          </p>
          <RaffleBox
            raffleNumber={dataRifa?.amount_rifa || DEFAULT_RAFFLE_NUMBER}
            onRaffleSelected={handleRaffleNumber}
            raffleNumberSelected={watch("number_rcustomer")}
            dataRaffles={dataDetailCustomerRifa}
          />
          <RaffleForm
            handleSubmit={handleSubmit}
            handleDeleteRaffle={onRaffleDelete}
            onSubmit={onRaffleSubmit}
            control={control}
            raffleNumber={dataRifa?.amount_rifa || DEFAULT_RAFFLE_NUMBER}
            watch={watch}
            loading={loadingDetail}
          />
        </motion.div>
      </main>
      <Toaster />
    </div>
  );
};

export default PublicCustomer;
