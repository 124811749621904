import { useEffect, useState } from "react";
import InputAutoComplete from "../../../atomic/molecule/autocomplete.molecule";
import ContainerInput from "../../../components/ContainerInput";
import { get_producto } from "../../../services/productos.services";
import DynamicTable from "../../planning/ui/dynamicTable";
import { formatMoney } from "../../../functions/formatMoney";

const CheckInventory = ({ products, setProducts }) => {
  const [dataPlatos, setDataPlatos] = useState([]);

  const fetchPlatos = async () => {
    try {
      let response = await get_producto();

      setDataPlatos(response);
    } catch (err) {
      setDataPlatos([]);
      console.log(err);
    }
  };

  const handleChange = (values, idx) => {
    if (!idx) {
      setProducts(values);
      return;
    }

    const value = values[idx];

    const { value_product_by_event_total = 0, prod_stock = 0 } = value;

    values[idx] = {
      ...value,
      tota_buy: Math.abs(prod_stock - value_product_by_event_total),
      name_medida: value.name_medida,
    };

    setProducts(values);
  };

  useEffect(() => {
    fetchPlatos();
  }, []);

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 md:col-span-12">
        <div className="p-0 mb-4">
          <InputAutoComplete
            option={dataPlatos.map((el) => ({
              value: el.prod_id,
              label: `#${el.prod_id} - ${el.prod_nombre}`,
            }))}
            label="Agregar productos"
            name="productos"
            value={null}
            handleChange={(e) => {
              const findPlato = dataPlatos.find(
                (el) => Number(el.prod_id) === Number(e.target.value)
              );

              if (findPlato) {
                let existPlato = products.some(
                  (el) => el.prod_id === findPlato.prod_id
                );

                if (!existPlato) {
                  setProducts([
                    ...products,
                    {
                      prod_id: findPlato.prod_id,
                      prod_nombre: findPlato.prod_nombre,
                      name_medida: findPlato.name_medida,
                      prod_precio: findPlato.prod_precio,
                      prod_stock: 0,
                      value_product_by_event_total: 0,
                      tota_buy: 0,
                    },
                  ]);
                }
              }
            }}
            className="w-80"
          />
        </div>
        <ContainerInput className="p-0">
          <DynamicTable
            columns={[
              {
                id: "delete",
                label: "Eliminar",
                input: "delete",
                minWidth: 10,
                align: "left",
              },
              {
                id: "prod_id",
                label: "Código",
                minWidth: 80,
                align: "left",
              },
              {
                id: "prod_nombre",
                label: "Producto",
                minWidth: 150,
                align: "left",
                format: (el) => {
                  return el.toUpperCase();
                },
              },
              {
                id: "prod_observation",
                label: "Observaciones",
                minWidth: 150,
                align: "left",
                input: "text",
              },
              {
                id: "name_medida",
                label: "Medida",
                minWidth: 60,
                align: "left",
                format: (el) => {
                  return el.toUpperCase();
                },
              },
              {
                id: "prod_precio",
                label: "Precio",
                minWidth: 60,
                align: "left",
                format: (el) => {
                  return formatMoney(el);
                },
              },
              {
                id: "prod_stock",
                label: "Stock",
                minWidth: 60,
                align: "left",
              },
              {
                id: "value_product_by_event_total",
                label: "Cantidad",
                minWidth: 60,
                align: "left",
                input: "number",
              },
              {
                id: "tota_buy",
                label: "Total a comprar",
                minWidth: 60,
                align: "left",
              },
            ]}
            rows={products}
            load={false}
            pagination={false}
            isReadMode={false}
            error={false}
            setRows={($rows, $idx) => handleChange($rows, $idx)}
          />
        </ContainerInput>
      </div>
    </div>
  );
};

export default CheckInventory;
