import { PutObjectCommand } from "@aws-sdk/client-s3";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import LoadingPage from "../../../components/LoadingPage";
import { s3Client } from "../../../libs/s3Client";
import {
  finalizePurchase,
  getQuestionSupplier,
} from "../../../services/purchase.services";
import ChooseProducts from "./choose-products.purchase";
import RatePurchase from "./rate.purchase";
import StepperPurchase from "./stepper.purchase";
import UploadReceipts from "./upload-receipts.purchase";
import FinalConfirm from "./final-confirm.purchase";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalFinalPurchase = (props) => {
  const {
    dataSelected,
    setDataSelected,
    clearSelected,
    callBack,
    open,
    setOpen,
  } = props;

  const [loadPage, setLoadPage] = React.useState(false);

  const handleClose = () => {
    setOpen(false);

    callBack();
  };

  const handleUpload = async (inputFile) => {
    if (!inputFile) {
      return;
    }

    const file = inputFile[0];
    const fileExtension = file.name.split(".").pop();
    const fileName = `${uuidv4()}.${fileExtension}`;

    const bucketParams = {
      Bucket: "ubuntu-storage",
      Key: `purchase/receipts/${fileName}`,
      Body: file,
      ACL: "public-read",
    };

    try {
      await s3Client.send(new PutObjectCommand(bucketParams));
      return fileName;
    } catch (err) {
      console.log(err);
    }
  };

  const handleUploadReceipts = async (data) => {
    for (let receipt of data) {
      const nameFile = await handleUpload(receipt.receiptFile);
      receipt.receiptNameFile = nameFile;
    }

    return data;
  };

  const handleFinalize = async (data) => {
    setLoadPage(true);

    try {
      const newData = await handleUploadReceipts(data.purchase.receipts);

      const response = await finalizePurchase({ ...data, receipts: newData });
      setLoadPage(false);

      return response;
    } catch (err) {
      setLoadPage(false);
      console.log(err);

      return false;
    }
  };

  const handleSave = async (number) => {
    if (number === 1) {
      const listSuppliersId = [
        ...new Set(
          dataSelected.products.map((el) => Number(el?.suppliers_name))
        ),
      ];

      if(listSuppliersId.length === 0) return false;

      const response = await getQuestionSupplier(listSuppliersId);

      setDataSelected((prev) => ({
        ...prev,
        supplierList: response || [],
      }));

      return true;
    }

    if (number === 2) {
      return true;
    }

    if (number === 3) {
      return await handleFinalize(dataSelected);
    }

    return false;
  };

  React.useEffect(() => {
    if (open) {
    } else {
      clearSelected();
    }
  }, [open]);

  return (
    open && (
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className="!sticky !top-0 !z-10" color="secondary">
          <Toolbar variant="regular">
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Finalización de orden de compra #
              {dataSelected?.purchase?.code_purchase}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="p-6">
          <LoadingPage open={loadPage} />
          <StepperPurchase
            steps={[
              {
                title: "Seleccionar productos",
                component: (
                  <ChooseProducts
                    data={dataSelected.products}
                    dataPurchase={dataSelected.purchase}
                    setData={(newItems) =>
                      setDataSelected((prev) => ({
                        ...prev,
                        products: newItems,
                      }))
                    }
                  />
                ),
              },
              {
                title: "Calificar de la compra",
                component: (
                  <RatePurchase
                    dataPurchase={dataSelected.purchase}
                    dataProducts={dataSelected.products}
                    dataSuppliers={dataSelected.supplierList}
                    setDataSuppliers={(newItems) =>
                      setDataSelected((prev) => ({
                        ...prev,
                        supplierList: newItems,
                      }))
                    }
                  />
                ),
              },
              {
                title: "Subir comprobantes",
                component: (
                  <UploadReceipts
                    dataPurchase={dataSelected.purchase}
                    setDataPurchase={(newItems) =>
                      setDataSelected((prev) => ({
                        ...prev,
                        purchase: newItems,
                      }))
                    }
                  />
                ),
              },
            ]}
            finalStep={
              <FinalConfirm
                purchaseData={dataSelected.purchase}
                productsData={dataSelected.products}
              />
            }
            onActiveStep={handleSave}
            onCloseAction={handleClose}
          />
        </div>
      </Dialog>
    )
  );
};

export default ModalFinalPurchase;
