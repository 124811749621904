import { Card, CardContent, Typography, Skeleton } from "@mui/material";
import NotFound from "../../../atomic/organisms/NotFound.organisms";

const CardReport = (props) => {
  const { style, title, dataReport } = props;

  const { data, err, load } = dataReport;

  if (load) {
    return (
      <Card
        className="ContainerInput !p-0"
        variant="outlined"
        style={{ borderRadius: 12 }}
      >
        <CardContent className="!p-2">
          <Skeleton variant="rectangular" animation="wave" />
          <br />
          <Skeleton variant="rectangular" animation="wave" />
        </CardContent>
      </Card>
    );
  }

  if (err) {
    return <div>Error</div>;
  }

  if (!load && data.length === 0) {
    return (
      <Card
        className="ContainerInput !p-0"
        variant="outlined"
        style={{ borderRadius: 12 }}
      >
        <CardContent className="!p-2">
          <NotFound texto="No se encontró registros." />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card
      className="ContainerInput !p-0"
      variant="outlined"
      style={{ ...style, borderRadius: 12 }}
    >
      <CardContent className="!p-2">
        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
          {title}
        </Typography>
        {props.children}
      </CardContent>
    </Card>
  );
};

export default CardReport;
