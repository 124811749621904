import React, { useCallback, useEffect, useState } from "react";

import InputAutoComplete from "../../atomic/molecule/autocomplete.molecule";
import TableSoldOut from "../../atomic/organisms/Table.organisms";
import ContainerInput from "../../components/ContainerInput";

import {
  addMassive_agotadoproducto,
  add_agotadoproducto,
  delete_agotadoproducto,
  edit_agotadoproducto,
  get_agotadoproducto,
} from "../../services/agotadoproducto.services";
import { get_plato } from "../../services/plato.services";

import { Button, Divider, Grid } from "@mui/material";

import InputDefault from "../../atomic/atoms/input.atoms";

import { toast } from "react-hot-toast";
import LoadingPage from "../../components/LoadingPage";
import DialogAddProductAll from "./Dialog/AllProducts.soldout";
import DialogEditSoldOut from "./Dialog/DialogEdit.soldout";

const PlatosSoldOutComponente = () => {
  const [loadPage, setLoadPage] = useState(false);
  const [platos, setPlatos] = useState([]);
  const [productSoldOut, setProductSoldOut] = useState([]);
  const [modalAllProduct, setModalAllProduct] = useState(false);

  const [rowSelected, setRowSelected] = useState({
    id_proagotado: null,
    amount_proagotado: 0,
    nombre_plato: null,
  });

  const [openEdit, setOpenEdit] = useState(false);

  const initAddProduct = {
    id: null,
    amount: 0,
    name: "",
  };

  const [addProduct, setAddProduct] = useState(initAddProduct);

  const columnTable = [
    {
      id: "nombre_plato",
      label: "Nombre del plato",
      minWidth: 130,
      align: "left",
      date: false,
    },
    {
      id: "amount_proagotado",
      label: "Cantidad",
      minWidth: 130,
      align: "left",
      date: false,
    },
  ];

  const handleLoadPage = (value) => {
    setLoadPage(value);
  };

  const handleChange = async (e) => {
    if (e.preventDefault) e.preventDefault();
    const { value } = e.target;

    const findPlato = platos.find((el) => el.value === value);

    if (!value || value === "") {
      setAddProduct({ ...addProduct, id: "", name: findPlato?.label || "" });
    }

    setAddProduct({ ...addProduct, id: value, name: findPlato?.label || "" });
  };

  const handleAddProduct = async () => {
    if (!addProduct.id) {
      toast.error("Tiene que seleccionar un plato.");
      return;
    }

    try {
      handleLoadPage(true);

      console.log(addProduct);

      const response = await add_agotadoproducto(addProduct);
      handleLoadPage(false);

      if (response) {
        setProductSoldOut(response);
      }
    } catch (err) {
    } finally {
      setAddProduct(initAddProduct);
    }
  };

  const handleAddProductAll = async (products) => {
    try {
      handleLoadPage(true);

      const filterProductsExist = products.filter((el) => {
        const index = productSoldOut.findIndex(
          (item) => item.id_plato === el.id
        );
        return index === -1;
      });

      const response = await addMassive_agotadoproducto(filterProductsExist);
      setProductSoldOut(response);

      handleLoadPage(false);
    } catch (er) {}
  };

  /**
   * GET
   */

  const handleGetProducts = async () => {
    try {
      const response = await get_plato();

      const formatPlato = response.map((el) => ({
        value: el.id_plato,
        label: `${el.nombre_plato} ${el?.nombre_area ? -el?.nombre_area : ""}`,
        area: el.nombre_area,
      }));

      setPlatos(formatPlato);
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetSoldoutProducts = async () => {
    try {
      handleLoadPage(true);
      const response = await get_agotadoproducto();

      handleLoadPage(false);
      setProductSoldOut(response);
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * DELETE
   */

  const handleDeleteSoldOut = async (row) => {
    try {
      handleLoadPage(true);
      const response = await delete_agotadoproducto(row.id_proagotado);
      setProductSoldOut(response);

      handleLoadPage(false);
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * MODIFY
   */

  const handleModifySoldOut = async (data) => {
    try {
      handleLoadPage(true);
      setOpenEdit(false);

      const response = await edit_agotadoproducto(data);
      setProductSoldOut(response);

      toast.success("Se modifico correctamente el registro.");

      handleLoadPage(false);
    } catch (err) {
      setOpenEdit(true);
      toast.success("Ocurrió un problema :/");
      console.log(err);
    }
  };

  const handleShowSoldOut = async (row) => {
    setOpenEdit(true);
    setRowSelected({
      id_proagotado: row.id_proagotado,
      amount_proagotado: row.amount_proagotado,
      nombre_plato: row.nombre_plato,
    });
  };

  const platosToBeAdded = useCallback(
    () =>
      platos
        .filter((el) => {
          const index = productSoldOut.findIndex(
            (item) => item.id_plato === el.value
          );
          return index === -1;
        })
        .filter((el) => el.area === "Cocina - Fondos"),
    [productSoldOut, platos]
  );

  useEffect(() => {
    handleGetSoldoutProducts();
    handleGetProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LoadingPage open={loadPage} setOpen={setLoadPage} />
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          style={{
            textAlign: "right",
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setModalAllProduct(true)}
          >
            Todos los productos
          </Button>
        </Grid>
      </Grid>

      <br />
      <ContainerInput
        style={{
          borderRadius: "0px",
        }}
      >
        <Grid container spacing={1} style={{ padding: 10 }}>
          <Grid item xs={12}>
            <InputAutoComplete
              option={platos}
              name="plato"
              label="Selecciona el plato"
              value={""}
              handleChange={handleChange}
            />
          </Grid>

          {addProduct.id && (
            <>
              <Grid item xs={4}>
                <InputDefault
                  title="Nombre plato"
                  type="text"
                  value={addProduct?.name || ""}
                  disabled
                />
              </Grid>
              <Grid item xs={2}>
                <InputDefault
                  title="Cantidad"
                  type="number"
                  value={addProduct.amount}
                  onChange={(e) => {
                    const inputValue = Number(e.target.value);

                    setAddProduct((el) => {
                      return {
                        ...el,
                        amount: Number(e.target.value) <= 0 ? 0 : inputValue,
                      };
                    });
                  }}
                  inputProps={{
                    min: 1,
                  }}
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <div style={{ height: "85%", display: "flex" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="success"
                    onClick={() => handleAddProduct()}
                  >
                    Agregar
                  </Button>
                </div>
              </Grid>
            </>
          )}
        </Grid>
        <Divider />

        <TableSoldOut
          rows={productSoldOut}
          columns={columnTable}
          actionDelete={handleDeleteSoldOut}
          actionEdit={handleShowSoldOut}
        />
      </ContainerInput>

      <DialogEditSoldOut
        open={openEdit}
        setOpen={setOpenEdit}
        rowSelected={rowSelected}
        callback={handleModifySoldOut}
      />

      <DialogAddProductAll
        dataPlatos={platosToBeAdded()}
        open={modalAllProduct}
        setOpen={setModalAllProduct}
        callback={handleAddProductAll}
      />
    </>
  );
};

export default PlatosSoldOutComponente;
