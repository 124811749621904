/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import ContainerInput from "../../components/ContainerInput";
import Template from "../../components/Template";

import LoadingPage from "../../components/LoadingPage";

import TableUser from "../../components/Tables/TableUser";

import DialogCrudUser from "./DialogCrudUser";
import DialogEliminarUser from "./DialogEliminarUser";

import { get_typeuser, get_user } from "../../services/user.services";

import { Button, IconButton } from "@mui/material";

import { Add, Search } from "@mui/icons-material/";

import InputDefault from "../../atomic/atoms/input.atoms";
import { nameCamarero } from "../../functions/nameCamarero";
import { checkToken } from "../../services/auth.services";
import {
  get_data_company_all,
  get_subsidaries,
} from "../../services/empresa.services";

const FilterUser = (props) => {
  const { onOpenAddModal, onSearch } = props;
  const [dataTypeUser, setDataTypeUser] = useState([]);
  const [company, setCompany] = useState([]);
  const [subsidiary, setSubsidiary] = useState([]);

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      searchName: "",
      userType: "",
      idEmp: "",
      idSubsidiary: "",
    },
  });

  const fetchTypeUser = async () => {
    try {
      const response = await get_typeuser();
      setDataTypeUser(
        response.map((el) => ({
          label: el.tipousu_id === 1 ? nameCamarero() : el.tipousu_nombre,
          value: el.tipousu_id,
        }))
      );
    } catch (err) {
      console.log(err.message);
    }
  };

  const fetchCompany = async () => {
    try {
      const response = await get_data_company_all();

      setCompany(
        response.map((el) => ({
          label: el.nom_emp,
          value: el.id_emp,
        }))
      );
    } catch (err) {
      console.log(err.message);
    }
  };

  const fetchSubsidiary = async () => {
    try {
      const response = await get_subsidaries(watch("idEmp"));

      setSubsidiary(
        response.map((el) => ({
          label: el.nom_empsede,
          value: el.id_empsede,
        }))
      );
    } catch (err) {
      console.log(err.message);
    }
  };

  React.useEffect(() => {
    if (watch("idEmp") > 0) {
      fetchSubsidiary();
    }
  }, [watch("idEmp")]);

  React.useEffect(() => {
    fetchTypeUser();
    fetchCompany();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSearch)} autoComplete="off">
      <div className="mb-4 w-full grid grid-cols-12 gap-2">
        <div className="col-span-6 md:col-span-3">
          <Controller
            name="searchName"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputDefault
                loading={false}
                fullWidth
                value={value}
                onChange={onChange}
                title={"Buscar usuario, nombre o email"}
                type={"text"}
              />
            )}
          />
        </div>
        <div className="col-span-4 md:col-span-2 flex gap-3">
          <Controller
            name="idEmp"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputDefault
                loading={false}
                fullWidth
                value={value}
                onChange={onChange}
                title={"Empresa"}
                type={"select"}
                options={company}
              />
            )}
          />
        </div>
        <div className="col-span-4 md:col-span-2 flex gap-3">
          <Controller
            name="idSubsidiary"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputDefault
                loading={false}
                fullWidth
                value={value}
                onChange={onChange}
                title={"Sucursal"}
                type={"select"}
                options={subsidiary}
              />
            )}
          />
        </div>
        <div className="col-span-4 md:col-span-3 flex gap-3">
          <Controller
            name="userType"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputDefault
                loading={false}
                fullWidth
                value={value}
                onChange={onChange}
                title={"Tipo de usuario"}
                type={"select"}
                options={dataTypeUser}
              />
            )}
          />

          <IconButton
            type="submit"
            color="default"
            className="ml-auto col-span-1"
          >
            <Search />
          </IconButton>
        </div>
        <div
          className="col-span-12 md:col-span-12 flex justify-end
        "
        >
          <Button
            color="primary"
            onClick={() => onOpenAddModal(true)}
            className="button-default ml-auto w-full md:w-auto"
          >
            Crear <Add />
          </Button>
        </div>
      </div>
    </form>
  );
};

const Usuario = () => {
  let history = useHistory();

  const [loadPage, setLoadPage] = useState(false);

  const [dataUser, setDataUser] = useState({ load: false, data: [] });
  const [openAdd, setOpenAdd] = useState(false);

  const [filterData, setFilterData] = useState({
    searchName: "",
    userType: null,
    idEmp: null,
    idSubsidiary: null,
  });

  const [openEliminarUser, setOpenEliminarUser] = useState(false);

  const [dataViewData, setDataViewData] = useState({});
  const [dataViewEliminarData, setDataViewEliminarData] = useState("");

  //obtain the user data

  const getUserData = async () => {
    setLoadPage(true);
    try {
      const response = await get_user();

      setDataUser({ data: response, load: false });
      setLoadPage(false);
    } catch (err) {
      setLoadPage(false);
    }
  };

  const handleViewData = (data) => {
    setOpenAdd(true);
    setDataViewData(data);
  };

  const handleViewDataEliminar = (data) => {
    setOpenEliminarUser(true);
    setDataViewEliminarData(data);
  };

  const onSearch = async (data) => {
    setFilterData(data);

    setLoadPage(true);
    const response = await get_user();
    setLoadPage(false);

    let newData = response;

    if (data?.searchName) {
      newData = newData.filter((el) => {
        const name = el?.usu_nombre?.toLowerCase() ?? "";
        const email = el?.usu_email?.toLowerCase() ?? "";
        const username = el?.usu_username?.toLowerCase() ?? "";

        const searchName = data?.searchName.toLowerCase();

        return (
          name.includes(searchName) ||
          email.includes(searchName) ||
          username.includes(searchName)
        );
      });
    }

    if (Number(data.idEmp) > 0) {
      newData = newData.filter((el) => el.id_emp === Number(data.idEmp));
    }

    if (Number(data.idSubsidiary) > 0) {
      newData = newData.filter(
        (el) => el.id_empsede === Number(data.idSubsidiary)
      );
    }

    if (Number(data.userType) > 0) {
      newData = newData.filter((el) => el.tipousu_id === Number(data.userType));
    }

    setDataUser({ data: newData, load: false });
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }
  }, []);

  return (
    <Template title="Reservas">
      <LoadingPage open={loadPage} setOpen={setLoadPage} />

      <FilterUser onOpenAddModal={setOpenAdd} onSearch={onSearch} />

      <ContainerInput
        style={{
          textAlign: "center",
          borderRadius: "0px",
          padding: "0px",
          marginTop: "20px",
        }}
      >
        <TableUser
          rows={dataUser.data}
          setRow={(el) => setDataUser({ ...dataUser, data: el })}
          handleViewData={handleViewData}
          handleViewDataEliminar={handleViewDataEliminar}
          filterData={filterData}
        />
      </ContainerInput>

      <DialogCrudUser
        open={openAdd}
        setOpen={setOpenAdd}
        handleGetUsuarios={getUserData}
        data={dataViewData}
        setData={setDataViewData}
      />
      <DialogEliminarUser
        open={openEliminarUser}
        setOpen={setOpenEliminarUser}
        data={dataViewEliminarData}
        handleGetUsuarios={getUserData}
      />
    </Template>
  );
};

export default Usuario;
