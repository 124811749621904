import { Alert, CircularProgress, Grid, Typography } from "@mui/material";

import "./style.datapedido.scss";

import "moment/locale/es";

import NotFound from "../../../../atomic/organisms/NotFound.organisms";
import { nameCamarero } from "../../../../functions/nameCamarero";
import ItemInput from "../Components/ItemInput";
import { clearText } from "../../../../functions/utilidades.function";
import dayjs from "dayjs";

const DataPedido = (props) => {
  const { infoPedido } = props;

  const data = infoPedido.data;

  const clientName = data?.id_reserva
    ? `${clearText(data?.nombre_reserva)} ${clearText(
        data?.apellido_paterno_reserva
      )} ${clearText(data?.apellido_materno_reserva)}`
    : `${clearText(data?.nombre_cliente)} ${clearText(
        data?.apepaterno_cliente
      )} ${clearText(data?.apematerno_cliente)}`;

  if (infoPedido.load) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (infoPedido.err) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <NotFound title="¡Ocurrió un problema!" />
      </div>
    );
  }

  return (
    <Grid container spacing={2}>
      {data?.id_reserva >= 0 && data?.address_cliente === "" && (
        <Grid item xs={12}>
          <Alert severity="error" className="mb-4 w-full animate-pulse uppercase text-left border-2 border-red-500">
            El cliente no cuenta con dirección, por favor ingrese la dirección
            antes de pagar.
          </Alert>
        </Grid>
      )}

      <Grid item xs={12}>
        <ItemInput
          label="Apertura"
          value={`${dayjs.utc(data.fechareg_pedido).format("DD/MM/YYYY HH:mm")}`}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        {data?.typecaja_pedido === 1 && (
          <ItemInput label="Cliente" value={`${clientName}`} fullWidth />
        )}
        {data?.typecaja_pedido === 2 && (
          <ItemInput
            label="Cliente"
            value={`${data?.fullname_pedido || ""}`}
            fullWidth
          />
        )}
      </Grid>
      {data?.typecaja_pedido === 1 && (
        <Grid item xs={12} sm={6}>
          <ItemInput
            label="Clasificación"
            value={`${data.clasificacion_cliente || "Sin clasificación"}`}
            fullWidth
          />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <ItemInput label="Cod. Pedido" value={data.codigo_pedido} fullWidth />
      </Grid>
      {/* <Grid item xs={6}>
        <ItemInput label="Cod. Reserva" value={data.id_reserva} />
      </Grid> */}
      <Grid item xs={12} md={6}>
        <ItemInput
          label={nameCamarero()}
          value={data.usu_nombre}
          style={{ textTransform: "inherit" }}
          fullWidth
        />
      </Grid>
      {data?.typecaja_pedido === 1 && (
        <Grid item xs={6} md={6}>
          <ItemInput label="Cod. Mesa" value={data.codigo_mesa} fullWidth />
        </Grid>
      )}

      {data?.typecaja_pedido === 1 && (
        <Grid item xs={6} md={6}>
          <ItemInput label="Personas" value={data.cantidad_mesa} fullWidth />
        </Grid>
      )}

      {data.id_estadopedido > 3 && (
        <Grid item xs={12}>
          <ItemInput
            label="Estado"
            value={data.nombre_estadopedido}
            style={{ width: "300px" }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default DataPedido;
