import { useEffect, useState } from "react";
import ContainerInput from "../../../components/ContainerInput";
import DynamicTable from "../../planning/ui/dynamicTable";
import OrderInfoPurchase from "./order-info.purchase";

import { get_supplier } from "../../../services/supplier.services";

const ChooseProducts = ({ data, setData, dataPurchase }) => {
  const [dataSuppliers, setDataSuppliers] = useState([]);

  const columns = [
    {
      id: "prod_id",
      label: "Código",
      minWidth: 80,
      align: "left",
    },
    {
      id: "prod_nombre",
      label: "Producto",
      minWidth: 150,
      align: "left",
      format: (el) => {
        if (!el) return "";

        return el.toUpperCase();
      },
    },
    {
      id: "observation_pupro",
      label: "Observaciones",
      minWidth: 150,
      align: "left",
    },
    {
      id: "suppliers_name",
      label: "Proveedores",
      minWidth: 100,
      align: "left",
      input: "select",
      options: dataSuppliers,
    },
    {
      id: "name_medida",
      label: "Medida",
      minWidth: 60,
      align: "left",
      format: (el) => {
        if (!el) return "";

        return el.toUpperCase();
      },
    },
    {
      id: "qtypaied_pupro",
      label: "Total a comprar",
      minWidth: 60,
      align: "left",
      input: "number",
    },
    {
      id: "pricepaied_pupro",
      label: "Total a pagar",
      minWidth: 80,
      align: "left",
      input: "text",
    },
    {
      id: "delete",
      label: "Eliminar",
      input: "delete",
      minWidth: 10,
      align: "left",
    },
  ];

  useEffect(() => {
    async function fetchSuppliers() {
      try {
        let response = await get_supplier();

        setDataSuppliers(response.map((el) => ({
          value: el.id_supplier,
          label: el.name_supplier,
        })));
      } catch (err) {
        console.log(err);
      }
    }

    fetchSuppliers();
  }, []);

  return (
    <>
      <OrderInfoPurchase
        selectedInfo={{
          codigo: dataPurchase?.code_purchase,
          priority: dataPurchase?.priority_purchase,
          purchaseDate: dataPurchase?.date_purchase,
          description: dataPurchase?.description_purchase,
        }}
        isComponentFinal={true}
      />
      <div className="p-2 mt-4">
        <h3 className="text-sm font-semibold">Productos a comprar</h3>
      </div>
      <ContainerInput className="p-0">
        <DynamicTable
          columns={columns}
          rows={data}
          load={false}
          pagination={true}
          isReadMode={false}
          setRows={($rows) => setData($rows)}
        />
      </ContainerInput>
    </>
  );
};

export default ChooseProducts;
