import { Print } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputDefault from "../../../atomic/atoms/input.atoms";
import { get_empresa_sucursal } from "../../../services/empresa.services";
import BasicDateRangePicker from "../../quotation/ui/BasicDateRangePicker.quotation";
import { get_group } from "../../../services/reservas.services";
import { getUser } from "../../../services/auth.services";

const FiltersCustomerReport = ({
  initialState,
  onSearchData,
  onLoadData,
  handlePrint,
}) => {
  const [dataCompanies, setDataCompanies] = useState([]);
  const [dataGroup, setDataGroup] = useState([]);
  const [loadCompanies, setLoadCompanies] = useState(false);

  const dataProgramas = [
    {
      value: "cumpleanos",
      label: "Cumpleaños",
    },
    {
      value: "aniversario",
      label: "Aniversario",
    },
    {
      value: "recreacion",
      label: "Recreación",
    },
    {
      value: "Otros",
      label: "Otros",
    },
  ];

  const { control, handleSubmit } = useForm({
    defaultValues: initialState,
  });

  useEffect(() => {
    const handleGetCompanies = async () => {
      try {
        setLoadCompanies(true);
        const response = await get_empresa_sucursal();
        setLoadCompanies(false);

        setDataCompanies(response);
      } catch (err) {
        console.log(err);
      }
    };

    handleGetCompanies();
  }, []);

  useEffect(() => {
    const handleFetchGroup = async () => {
      try {
        const response = await get_group();
        setDataGroup(
          response.map((el) => ({
            label: el.name_rservagrupa,
            value: el.id_rservagrupa,
          }))
        );
      } catch (err) {}
    };

    // SOLO PARA EL CLIENTE 4 (DOÑA ANITA)
    getUser()?.empresa.id_emp === 4 && handleFetchGroup();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSearchData)} autoComplete="off">
      <div className="mb-4 w-full grid grid-cols-12 gap-2">
        <div className="col-span-2 self-center">
          <Controller
            name="companyId"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                value={value}
                onChange={onChange}
                type="select"
                disabled={loadCompanies}
                title={"Empresa"}
                options={dataCompanies}
              />
            )}
          />
        </div>
        <div className="col-span-2 self-center">
          <Controller
            name="program"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                loading={false}
                fullWidth
                value={value}
                onChange={onChange}
                type="select"
                title={"Programa"}
                options={dataProgramas}
              />
            )}
          />
        </div>
        <div className="col-span-2 self-center">
          <Controller
            name="group"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                loading={false}
                fullWidth
                value={value}
                onChange={onChange}
                type="select"
                title={"Segmentación"}
                options={dataGroup}
              />
            )}
          />
        </div>

        <div className="col-span-3 self-center">
          <Controller
            name="filterMonth"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <BasicDateRangePicker value={value} onChange={onChange} />
            )}
          />
        </div>

        <div className="col-span-2 self-center">
          <Button
            disabled={onLoadData}
            variant="contained"
            color="primary"
            className="ml-auto w-full md:w-auto"
            type="submit"
          >
            Buscar
          </Button>
          <IconButton
            size="large"
            color="primary"
            onClick={handlePrint}
            className="!ml-2"
            disabled={onLoadData}
          >
            <Print />
          </IconButton>
        </div>
      </div>
    </form>
  );
};

export default FiltersCustomerReport;
