import React, { useEffect, useState } from "react";

import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { actionTypes } from "../../../redux/menu/actions";
import { actionTypesPlato } from "../../../redux/plato/actions";

import { get_menu } from "../../../services/menu.services";

import ContainerInput from "../../../components/ContainerInput";

import { Delete, Edit, Search } from "@mui/icons-material";
import NotFound from "../../../atomic/organisms/NotFound.organisms";
import LoadingPage from "../../../components/LoadingPage";
import { get_plato } from "../../../services/plato.services";
import ModalDeleteCategoria from "./Dialog/ConfirmDelete/confirmDeleteCategoria";
import ModalAddEditCategoria from "./Dialog/CrudForm";
import ModalPlatos from "./Dialog/Platos";
import InputDefault from "../../../atomic/atoms/input.atoms";

const ListCategory = (props) => {
  const dispatch = useDispatch();

  const { dataMenu, onSearchData } = props;

  const { dataSelected_categoria } = useSelector((state) => state.reducer_menu);

  const data = dataMenu.data;
  const loading = dataMenu.load;

  const [loadingPlatos, setLoadingPlatos] = useState(false);
  const [search, setSearch] = useState("");

  const handleSelectedCategoria = (data) =>
    dispatch({
      type: actionTypes.setDataSelected_categoria,
      payload: data,
    });

  const handleOpenCategory = (data) =>
    dispatch({
      type: actionTypes.openModalAddEdit_categoria,
      payload: data,
    });

  const handleDeleteCategoria = (data) =>
    dispatch({
      type: actionTypes.openModalDelete_categoria,
      payload: data,
    });

  const handlenGetPlatos = (data) =>
    dispatch({
      type: actionTypesPlato.getDataPlato,
      payload: data,
    });

  const handleOpenAddCategory = () => {
    handleOpenCategory(true);
    handleSelectedCategoria({
      id_categoriapla: "",
      nombre_categoriapla: "",
    });
  };

  const handleEdit = (value) => {
    handleOpenCategory(true);
    handleSelectedCategoria({ ...dataSelected_categoria, ...value });
  };

  const handleDelete = (value) => {
    handleSelectedCategoria({ ...dataSelected_categoria, ...value });
    handleDeleteCategoria(true);
  };

  const handleSelectCategory = async (value) => {
    setLoadingPlatos(true);
    handlenGetPlatos({ data: [], load: true, err: false });

    const dataPlatos = await get_plato();

    setLoadingPlatos(false);

    handleSelectedCategoria({ ...dataSelected_categoria, ...value });

    const filter = dataPlatos.filter(
      (el) => el.id_categoriapla === value?.id_categoriapla
    );

    handlenGetPlatos({ data: filter, load: false, err: false });
  };

  const ListCategory = () => {
    if (loading) {
      return (
        <div>
          <h3>Cargando...</h3>
        </div>
      );
    }

    if (data.length === 0) {
      return (
        <>
          <NotFound texto="No se encontró registros." />
          <ListItemButton onClick={() => handleOpenAddCategory(true)}>
            <ListItemText
              id={"asd"}
              primary={
                <Button variant="outlined" color="inherit" fullWidth>
                  AGREGAR
                </Button>
              }
            />
          </ListItemButton>
        </>
      );
    }

    return (
      <>
        {data.map((value, idx) => {
          const labelId = `list-secondary-label-${idx}`;
          return (
            <ListItem
              key={idx}
              onClick={() => {
                handleSelectCategory(value);
              }}
              style={{
                background:
                  value.id_categoriapla ===
                    dataSelected_categoria?.id_categoriapla && "#33058d40",
              }}
              secondaryAction={
                <>
                  <IconButton color="warning" onClick={() => handleEdit(value)}>
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="error"
                    disabled={value.platos.length !== 0}
                    onClick={() => handleDelete(value)}
                  >
                    <Delete />
                  </IconButton>
                </>
              }
              disablePadding
            >
              <ListItemButton>
                <ListItemText
                  id={labelId}
                  primary={`${
                    value.orden_categoriapla
                      ? `${value.orden_categoriapla})`
                      : ""
                  } ${value.nombre_categoriapla} (${value.platos.length})`}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
        <ListItem disablePadding>
          <ListItemButton onClick={() => handleOpenAddCategory(true)}>
            <ListItemText
              id={"asd"}
              primary={
                <Button variant="outlined" color="inherit" fullWidth>
                  AGREGAR
                </Button>
              }
            />
          </ListItemButton>
        </ListItem>
      </>
    );
  };

  return (
    <>
      {/* <LoadingPage open={loadingPlatos} /> */}
      <List
        dense={true}
        sx={{ width: "100%" }}
        subheader={
          <ListSubheader
            style={{ textAlign: "left" }}
            className="!mb-4 p-2 border-b-2 border-gray-200 backdrop-blur-sm bg-white"
          >
            <form
              className="!w-full flex gap-2"
              onSubmit={(e) => {
                e.preventDefault();
                onSearchData(search);
              }}
            >
              <InputDefault
                title={"Buscar categoría..."}
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <IconButton type="submit" color="warning">
                <Search />
              </IconButton>
            </form>
          </ListSubheader>
        }
      >
        <ListCategory />
      </List>
    </>
  );
};

const Categoria = () => {
  const dispatch = useDispatch();

  const { dataMenu } = useSelector((state) => state.reducer_menu);

  const onGet = (data) =>
    dispatch({
      type: actionTypes.getDataMenu,
      payload: data,
    });

  const onSearchData = async (inputSearch) => {
    onGet({ ...dataMenu, load: true, data: [], err: false });
    let response = await get_menu(1);

    if (inputSearch) {
      response = response.filter((el) =>
        el.nombre_categoriapla.toLowerCase().includes(inputSearch.toLowerCase())
      );
    }

    onGet({ ...dataMenu, load: false, data: response, err: false });
  };

  useEffect(() => {
    async function obtainDataMenu() {
      try {
        onGet({ ...dataMenu, load: true, data: [], err: false });
        const response = await get_menu(1);

        onGet({ ...dataMenu, load: false, data: response, err: false });
      } catch (err) {
        console.log(err);
        onGet({ ...dataMenu, load: false, data: [], err: true });
      }
    }

    obtainDataMenu();

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ContainerInput
        style={{
          marginTop: 0,
          marginBottom: 0,
          padding: "0px",
          textAlign: "center",
          margin: "5px",
        }}
        className={"h-[calc(85vh-100px)] overflow-auto"}
      >
        <ListCategory dataMenu={dataMenu} onSearchData={onSearchData} />
      </ContainerInput>

      <ModalAddEditCategoria />
      <ModalDeleteCategoria />
      <ModalPlatos />
    </>
  );
};

export default Categoria;
