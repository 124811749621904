let initialValues = { products: [], amountProducts: 0, priceProducts: 0 };

let ProductData = JSON.parse(localStorage.getItem("cart_products_pedido"))
  ? JSON.parse(localStorage.getItem("cart_products_pedido"))
  : initialValues;

const calculateTot = (data) => {
  let totPriceProducts = 0;
  let totRowsProducts = 0;

  data.forEach((element) => {
    totRowsProducts++;

    totPriceProducts += element.totAmount * element.precio_plato;
  });

  return { totPriceProducts, totRowsProducts };
};

export const reducer_pedido = (state = ProductData, action) => {
  switch (action.type) {
    case "ADD_PRODUCT":
      /*
        initial state
      */

      let product = { ...action.payload.row };
      let countInput = action.payload.count;

      let jsonAddProduct = {};
      let productStatus = [...state.products];

      /*
        check if product exist
      */

      let id_product = productStatus.findIndex(
        (el) => el.id_plato === product.id_plato
      );

      /*
        if product exist add count or update
      */

      if (id_product >= 0) {
        let totAmount = parseInt(countInput);

        productStatus[id_product] = {
          ...productStatus[id_product],
          totAmount,
        };

        jsonAddProduct = productStatus;
      } else {
        jsonAddProduct = [
          ...productStatus,
          { ...product, totAmount: countInput },
        ];
      }

      /*
        calculate total and save in localStorage
      */

      let productsAdd = {
        ...state,
        products: jsonAddProduct,
        amountProducts: calculateTot(jsonAddProduct).totRowsProducts,
        priceProducts: calculateTot(jsonAddProduct).totPriceProducts,
      };

      localStorage.setItem("cart_products", JSON.stringify(productsAdd));

      return productsAdd;

    case "PRODUCT_DELETE":
      let { idx_delete } = action.payload;
      let prodsDelete = [...state.products];

      prodsDelete.splice(idx_delete, 1);

      let productDelete = {
        ...state,
        products: prodsDelete,
        amountProducts: calculateTot(prodsDelete).totRowsProducts,
        priceProducts: calculateTot(prodsDelete).totPriceProducts,
      };

      localStorage.setItem("cart_products", JSON.stringify(productDelete));

      return productDelete;

    case "PRODUCT_CLEAR":
      //clear local storage
      localStorage.removeItem("cart_products");

      let initialClear = {
        products: [],
        amountProducts: 0,
        priceProducts: 0,
      };

      return initialClear;

    case "UPDATETEXT_PRODUCT":
      const jsonUpdate = action.payload.rows;

      let productsUpdate = {
        ...state,
        products: jsonUpdate,
        amountProducts: calculateTot(jsonUpdate).totRowsProducts,
        priceProducts: calculateTot(jsonUpdate).totPriceProducts,
      };

      localStorage.setItem("cart_products", JSON.stringify(productsUpdate));

      return productsUpdate;
    default:
      return state;
  }
};
