import * as React from "react";

import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { actionTypesPlato } from "../../../../redux/plato/actions";

import { useDispatch, useSelector } from "react-redux";

import {
  Add,
  Delete,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Settings,
} from "@mui/icons-material";

import NotFound from "../../../../atomic/organisms/NotFound.organisms";
import { formatMoney } from "../../../../functions/formatMoney";
import { delete_insumo_plato } from "../../../../services/plato.services";

// import Moment from "react-moment";
// import "moment/locale/es";

const TableInsumos = (props) => {
  let { open, setOpen, data, idrow } = props;

  const dispatch = useDispatch();

  const { dataPlato } = useSelector((state) => state.reducer_plato);

  const onGet = (data) =>
    dispatch({
      type: actionTypesPlato.getDataPlato,
      payload: data,
    });

  const handleDeleteInsumo = async (element, idx) => {
    let newData = [...dataPlato.data];

    let newInsumo = newData[idrow].productos.filter((item, index) => {
      return index !== idx;
    });

    /* SI NO EXISTE MAS REGISTROS QUITAR LA TABLA */

    if (newInsumo.length === 0) {
      setOpen(false);
    }

    newData[idrow].productos = [...newInsumo];

    try {
      await delete_insumo_plato(element.id_platoproducto);
      onGet({ ...dataPlato, data: newData });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Table
              size="small"
              aria-label="products"
              style={{ border: "1px #eee solid" }}
            >
              <TableHead style={{ background: "#eee" }}>
                <TableRow>
                  <TableCell style={{ minWidth: 80 }}>Código</TableCell>
                  <TableCell style={{ minWidth: 180 }}>Producto</TableCell>
                  <TableCell style={{ minWidth: 100 }}>Categoría</TableCell>
                  <TableCell style={{ minWidth: 100 }}>
                    Medida por unidad
                  </TableCell>
                  <TableCell style={{ minWidth: 100 }}>Cantidad</TableCell>
                  <TableCell style={{ minWidth: 100 }}>Total</TableCell>
                  <TableCell style={{ minWidth: 100 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((el, idx) => (
                  <TableRow
                    hover
                    key={idx}
                    tabIndex={-1}
                    sx={{ "& > *": { borderBottom: "unset" } }}
                  >
                    <TableCell component="th" scope="row">
                      {el.prod_cod}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {el.prod_nombre}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {el.cat_nombre}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {el.value_medida} ({el.name_medida})
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {el.prod_medida_valor}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {Number(el.value_medida) * Number(el.prod_medida_valor)} (
                      {el.name_medida})
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <IconButton
                        color="error"
                        onClick={() => handleDeleteInsumo(el, idx)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

const ItemTable = (props) => {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  let { dataSelected, row, idx } = props;

  let taxes = 19;
  let sellPrice = row.precio_plato - (row.precio_plato * taxes) / 100;
  let totalCost = row.productos.reduce(
    (acc, el) => acc + el?.prod_medida_valor * el?.prod_precio,
    0
  );
  let totalCostAverage = (totalCost / row.precio_plato) * 100;
  let marginBenefit = sellPrice - totalCost;

  const onSetDataSelected = (data) => {
    dispatch({
      type: actionTypesPlato.setDataSelected,
      payload: data,
    });
  };

  const onOpenAddEdit = (data) =>
    dispatch({
      type: actionTypesPlato.openModalAddEdit_plato,
      payload: data,
    });

  const onOpenDelete = (data) =>
    dispatch({
      type: actionTypesPlato.openModalDelete,
      payload: data,
    });

  const onOpenProducts = (data) =>
    dispatch({
      type: actionTypesPlato.openModalProducts,
      payload: data,
    });

  const handleEdit = (data) => {
    onSetDataSelected({ ...dataSelected, ...data });
    onOpenAddEdit(true);
  };

  const handleDelete = (data) => {
    onSetDataSelected({ ...dataSelected, ...data });
    onOpenDelete(true);
  };

  const handleAddProduct = (data) => {
    onSetDataSelected({ ...dataSelected, ...data });
    onOpenProducts(true);
  };

  return (
    <React.Fragment>
      <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
        <TableCell component="th">
          <IconButton
            aria-label="expand row"
            size="small"
            disabled={row.productos.length === 0}
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell align={"left"}>{row.orden_plato}</TableCell>
        <TableCell align={"left"}>{row.nombre_plato}</TableCell>
        <TableCell align={"left"}>{row.nombre_area}</TableCell>
        <TableCell align={"left"}>
          {row.ispriority_plato === 1 ? "Si" : "No"}
        </TableCell>
        <TableCell align={"left"}>{formatMoney(row.precio_plato)}</TableCell>
        <TableCell align={"left"}>{taxes}%</TableCell>
        <TableCell align={"left"}>{formatMoney(sellPrice)}</TableCell>
        <TableCell align={"left"}>
          {formatMoney(Math.round(totalCost))} ({totalCostAverage.toFixed(2)}%)
        </TableCell>
        <TableCell align={"left"}>
          {formatMoney(Math.round(marginBenefit))}
        </TableCell>
        <TableCell align={"center"} width={50}>
          <div className="flex gap-1">
            <IconButton color="success" onClick={() => handleAddProduct(row)}>
              <Add />
            </IconButton>
            <IconButton color="warning" onClick={() => handleEdit(row)}>
              <Edit />
            </IconButton>
            <IconButton color="error" onClick={() => handleDelete(row)}>
              <Delete />
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
      <TableInsumos
        open={open}
        setOpen={setOpen}
        data={row.productos}
        idrow={idx}
      />
    </React.Fragment>
  );
};

const TableProducto = (props) => {
  const { dataSelected } = useSelector((state) => state.reducer_plato);

  const { dataPlato } = props;

  const rows = dataPlato.data;
  const loading = dataPlato.load;

  if (loading) {
    return (
      <div className="relative">
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="w-full h-full bg-white rounded-lg shadow-md">
            <div className="flex flex-col items-center justify-center">
              <CircularProgress color="secondary" size={20} />
              <h3 className="text-center text-gray-500 mt-2">Cargando...</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (rows.length === 0) {
    return <NotFound texto="No se encontró registros." />;
  }

  return (
    <>
      <TableContainer sx={{ maxHeight: 700, overflow: "auto", border: "1px solid #eee" }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          size={"small"}
        >
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  minWidth: 10,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              ></TableCell>
              <TableCell
                align={"left"}
                style={{ minWidth: 50, fontWeight: "bold" }}
              >
                Orden
              </TableCell>
              <TableCell
                align={"left"}
                style={{ minWidth: 150, fontWeight: "bold" }}
              >
                Nombre
              </TableCell>
              <TableCell
                align={"left"}
                style={{ minWidth: 80, fontWeight: "bold" }}
              >
                Área
              </TableCell>
              <TableCell
                align={"left"}
                style={{ minWidth: 120, fontWeight: "bold" }}
              >
                Alta demora
              </TableCell>
              <TableCell
                align={"left"}
                style={{ minWidth: 70, fontWeight: "bold" }}
              >
                Precio al público
              </TableCell>{" "}
              <TableCell
                align={"left"}
                style={{ minWidth: 70, fontWeight: "bold" }}
              >
                Impuesto
              </TableCell>
              <TableCell
                align={"left"}
                style={{ minWidth: 60, fontWeight: "bold" }}
              >
                Precio neto
              </TableCell>
              <TableCell
                align={"left"}
                style={{ minWidth: 140, fontWeight: "bold" }}
              >
                Costo total
              </TableCell>
              <TableCell
                align={"left"}
                style={{ minWidth: 70, fontWeight: "bold" }}
              >
                Margen beneficio
              </TableCell>
              <TableCell
                colSpan={1}
                align={"center"}
                style={{ width: 50, fontSize: 21, color: "#5c5c5c" }}
              >
                <Settings />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => (
              <ItemTable
                dataSelected={dataSelected}
                idx={idx}
                key={idx}
                row={row}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableProducto;
