import axios from "axios";
import { config } from "../config";

import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

const URI = `${baseurl}/api/planning`;

export const addPlanning = (data) => {
  const url = `${URI}/add`;

  return axios
    .post(url, data, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const updatePlanning = (data, cod) => {
  const url = `${URI}/update/${cod}`;

  return axios
    .patch(url, data, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getPlanning = () => {
  const url = `${URI}/get`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const findPlanningByCod = (cod) => {
  const url = `${URI}/find_planning/${cod}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const addPlaningMenu = (params) => {
  const url = `${URI}/add_menu`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getPlaningMenu = (id, type) => {
  const url = `${URI}/get_menu/${id}/${type}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const addPlaningTrasNoche = (params) => {
  const url = `${URI}/add_trasnoche`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getPlaningTrasNoche = (id) => {
  const url = `${URI}/get_trasnoche/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const addPlaningTortas = (params) => {
  const url = `${URI}/add_torta`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getPlaningTortas = (id) => {
  const url = `${URI}/get_torta/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const addPlaningManteleria = (params) => {
  const url = `${URI}/add_manteleria`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getPlaningManteleria = (id) => {
  const url = `${URI}/get_manteleria/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const addPlaningCeremony = (params) => {
  const url = `${URI}/add_ceremony`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getPlaningCeremony = (id) => {
  const url = `${URI}/get_ceremony/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getFeePayment = (id) => {
  const url = `${URI}/get_fee_payment/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const addFeePayment = (params) => {
  const url = `${URI}/add_fee_payment`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const addPlRcs = (params) => {
  const url = `${URI}/pl_rcs/create`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
}

export const fetchPlRcs = () => {
  const url = `${URI}/pl_rcs/get`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
}