/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import * as React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { Grid } from "@mui/material/";

import { Controller, useForm } from "react-hook-form";

import { Close } from "@mui/icons-material";

import toast from "react-hot-toast";
import InputDefault from "../../../atomic/atoms/input.atoms";
import {
  createDetailRifa,
  deleteDetailRifa,
  updateDetailRifa,
} from "../../../services/rifa.services";

import { validateRut } from "@fdograph/rut-utilities";

const defaultValues = {
  id_rdetail: 0,
  usu_id: 0,
  id_rifa: 0,
};

const ModalRifaDetail = ({
  id,
  open,
  setOpen,
  userSelected,
  clearSelected,
  callBack,
}) => {
  const { control, reset, handleSubmit } = useForm({
    defaultValues,
  });

  const [loadPage, setLoadPage] = React.useState(false);

  const handleAddEdit = async (data) => {
    if (!validateRut(data.usu_rut, true)) {
      toast.error("El RUT ingresado no es válido");
      return;
    }

    try {
      setLoadPage(true);
      if (data?.id_rdetail > 0) {
        await updateDetailRifa(data?.id_rdetail, data);

        toast.success("Usuario modificado correctamente");
      } else {
        await createDetailRifa(data);

        toast.success("Usuario creado correctamente");
      }

      await callBack();
    } catch (err) {
      toast.error("Hubo un error al crear el usuario");
    } finally {
      setLoadPage(false);
      setOpen(false);
    }
  };

  const handleDelete = async (data) => {
    try {
      setLoadPage(true);
      await deleteDetailRifa(data.usu_id, data.id_rdetail);
      setLoadPage(false);

      toast.success("El usuario fue eliminado correctamente");

      await callBack();

      setLoadPage(false);
      setOpen(false);
    } catch (err) {
      toast.error("Hubo un error al eliminar el usuario");
      setLoadPage(false);
    }
  };

  React.useEffect(() => {
    if (open && userSelected?.id_rdetail > 0) {
      reset({ ...userSelected, id_rifa: id });
    } else {
      reset({ ...defaultValues, id_rifa: id });
      clearSelected();
    }
  }, [open]);

  if (userSelected?.delete) {
    return (
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth={"sm"}
      >
        <form onSubmit={handleSubmit(handleDelete)} autoComplete="off">
          <DialogTitle>¡Importante!</DialogTitle>
          <DialogContent>
            ¿Estas seguro de eliminar el usuario?
            <b className="uppercase text-red-600">{userSelected?.name_user}</b>?
          </DialogContent>
          <DialogActions>
            <Button
              color="inherit"
              variant="text"
              disabled={loadPage}
              onClick={() => {
                setOpen(false);
              }}
            >
              Salir
            </Button>
            <Button
              type="submit"
              color="error"
              variant="contained"
              disabled={loadPage}
            >
              Eliminar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth={"sm"}
      >
        <form onSubmit={handleSubmit(handleAddEdit)} autoComplete="off">
          <DialogTitle>
            {userSelected?.id_rdetail ? "Editar usuario" : "Agregar usuario"}
            <IconButton
              aria-label="close"
              onClick={() => setOpen(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={1} className="!mt-2">
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="usu_nombre"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputDefault
                      required
                      value={value || ""}
                      onChange={onChange}
                      type="text"
                      title="Nombre"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="usu_email"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputDefault
                      required
                      value={value || ""}
                      onChange={onChange}
                      type="text"
                      title="Correo electrónico"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="usu_phone"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputDefault
                      value={value || ""}
                      onChange={onChange}
                      type="text"
                      title="Número telefónico"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={12}>
                <span className="text-xs text-gray-500">
                  Ingrese el RUT sin puntos ni guión. Ejemplo: 12345678K, esto
                  será la contraseña del usuario.
                </span>
                <Controller
                  name="usu_rut"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputDefault
                      required
                      value={value || ""}
                      onChange={onChange}
                      type="text"
                      title="RUT"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="inherit"
              variant="text"
              disabled={loadPage}
              onClick={() => {
                setOpen(false);
              }}
            >
              Salir
            </Button>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              disabled={loadPage}
            >
              {userSelected?.id_rdetail ? "Editar" : "Agregar"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ModalRifaDetail;
