import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import toast from "react-hot-toast";
import LoadingPage from "../../../components/LoadingPage";
import {
  addPurchaseEvent,
  getInventory,
  getInventoryByPlato,
} from "../../../services/purchase.services";
import StepperPurchase from "./stepper.purchase";

import { addPlRcs } from "../../../services/planning.services";
import CheckInventory from "./check-inventory.purchase";
import ChooseEventsPurchase from "./choose-events.purchase";
import FinalPurchase from "./final.purchase";
import SummaryPurchase from "./summary.purchase";
import UpdateInformation from "./update-information.purchase";

const TYPE_PURCHASE = {
  EVENTS: 0,
  PRODUCTS: 1,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalPurchase = (props) => {
  const { callBack, open, setOpen } = props;

  const [typePurchase, setTypePurchase] = React.useState(0);

  const [ponderado, setPonderado] = React.useState(0);

  const [seletedEvent, setSelectedEvent] = React.useState([]);
  const [selectePlatos, setSelectedPlatos] = React.useState([]);

  const [disabledNextButton, setDisabledNextButton] = React.useState(false);

  const [tabEventos, setTabEventos] = React.useState(0);

  const [selectedInventory, setSelectedInventory] = React.useState([]);
  const [selectedInfo, setSelectedInfo] = React.useState({
    priority: "",
    purchaseDate: "",
    description: "",
  });
  const [purchaseData, setPurchaseData] = React.useState({
    purchaseId: 0,
    purchaseCode: 0,
  });

  const [loadPage, setLoadPage] = React.useState(false);

  const handleClose = () => {
    setOpen(false);

    callBack();
  };

  const handleSaveEvent = async (data) => {
    setLoadPage(true);

    try {
      const response = await addPurchaseEvent(data);

      setPurchaseData({
        purchaseId: response.insertId,
        purchaseCode: response.codePurchase,
      });

      setLoadPage(false);
      toast.success("Evento guardado correctamente");
      return true;
    } catch (err) {
      setLoadPage(false);
      toast.error("Hubo un error al guardar el evento");
      return false;
    }
  };

  const handleFetchInventory = async (events) => {
    try {
      setLoadPage(true);

      let response = await getInventory({
        events,
      });

      response = response.map((el) => ({
        ...el,
        tota_buy: Math.abs(el?.prod_stock - el?.value_product_by_event_total),
        prod_observation: "",
      }));

      setLoadPage(false);

      setSelectedInventory(response);
    } catch (err) {
      setLoadPage(false);
      console.log(err);
    }
  };

  const handleFetchInventoryByPlato = async (platos) => {
    try {
      setLoadPage(true);

      let response = await getInventoryByPlato(platos);

      response = response.map((el) => ({
        ...el,
        tota_buy: Math.abs(el?.prod_stock - el?.value_product_by_event_total),
        prod_observation: "",
      }));

      setLoadPage(false);

      setSelectedInventory(response);
    } catch (err) {
      setLoadPage(false);
      console.log(err);
    }
  };

  const handleSave = async (number) => {
    if (number === 1) {
      if (typePurchase === TYPE_PURCHASE.EVENTS) {
        const events = seletedEvent.filter((el) => el.selected === true);

        if (events.length === 0) {
          toast.error("Debe seleccionar al menos un evento");
          return false;
        }

        // Prepare the next step
        await handleFetchInventory(events);

        return true;
      } else {
        const percentage = selectePlatos.reduce(
          (acc, el) => acc + Number(el?.percentage),
          0
        );

        if (percentage !== 100) {
          toast.error("La suma de los porcentajes debe ser 100");
          return;
        }

        // add the dishes to DB for RCS
        await addPlRcs(selectePlatos);

        if (selectePlatos.length === 0) {
          toast.error("Debe seleccionar al menos un plato");
          return false;
        }

        const newData = selectePlatos.map((el) => ({
          id_plato: el.id_plato,
          quantity: Number(el?.quantity),
        }));

        // Prepare the next step
        await handleFetchInventoryByPlato(newData);

        return true;
      }
    }

    if (number === 2) {
      return true;
    }

    if (number === 3) {
      return true;
    }

    if (number === 4) {
      const response = await handleSaveEvent({
        events: seletedEvent,
        info: selectedInfo,
        products: selectedInventory,
      });

      return response;
    }

    return false;
  };

  /**
   * This effect is used to validate the next button
   */
  
  React.useEffect(() => {
    if (tabEventos === 0) {
      setDisabledNextButton(false);
    }

    if (tabEventos === 1) {
      const percentage = selectePlatos.reduce(
        (acc, el) => acc + Number(el?.percentage),
        0
      );

      if (percentage === 100) {
        setDisabledNextButton(false);
      } else {
        setDisabledNextButton(true);
      }
    }
  }, [tabEventos, ponderado, selectePlatos]);

  React.useEffect(() => {
    if (open) {
      setSelectedEvent([]);
      setSelectedPlatos([]);
    } else {
      setSelectedEvent([]);
      setSelectedPlatos([]);

      setSelectedInventory([]);
      setSelectedInfo({
        priority: "",
        purchaseDate: "",
        description: "",
      });
      setPurchaseData({
        purchaseId: 0,
        purchaseCode: 0,
      });
    }
  }, [open]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className="!sticky !top-0 !z-10" color="secondary">
        <Toolbar variant="regular">
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Creación de orden de compra
          </Typography>
        </Toolbar>
      </AppBar>
      <div className="p-6">
        <LoadingPage open={loadPage} />
        <StepperPurchase
          buttonDisabled={disabledNextButton}
          steps={[
            {
              title: "Seleccionar eventos",
              component: (
                <ChooseEventsPurchase
                  seletedEvent={seletedEvent}
                  onSelectEvent={setSelectedEvent}
                  setTypePurchase={setTypePurchase}
                  selectedPlatos={selectePlatos}
                  onSelectPlatos={setSelectedPlatos}
                  ponderado={ponderado}
                  setPonderado={setPonderado}
                  setDisabledNextButton={setDisabledNextButton}
                  tabEventos={tabEventos}
                  setTabEventos={setTabEventos}
                />
              ),
            },
            {
              title: "Verificar el inventario",
              component: (
                <CheckInventory
                  products={selectedInventory}
                  setProducts={setSelectedInventory}
                />
              ),
            },
            {
              title: "Actualizar información",
              component: (
                <UpdateInformation
                  selectedInfo={selectedInfo}
                  setSelectedInfo={setSelectedInfo}
                />
              ),
            },
            {
              title: "Resumen",
              component: (
                <SummaryPurchase
                  selectedInfo={selectedInfo}
                  selectedEvent={seletedEvent}
                  selectedProducts={selectedInventory}
                />
              ),
            },
          ]}
          finalStep={
            <FinalPurchase
              selectedInfo={selectedInfo}
              selectedEvent={seletedEvent}
              selectedProducts={selectedInventory}
              purchaseData={purchaseData}
            />
          }
          onActiveStep={handleSave}
          onCloseAction={handleClose}
        />
      </div>
    </Dialog>
  );
};

export default ModalPurchase;
