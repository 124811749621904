import * as React from "react";

import Autocomplete from "@mui/material/Autocomplete";

import Input from "../atoms/input.atoms";

const InputAutoComplete = (props) => {
  const { handleChange, name, option, label, defaultValue } = props;

  const [mainValue, setMainValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");

  return (
    <Autocomplete
      {...props}
      value={mainValue}
      sx={{
        fontSize: 12,
        "& input": {
          padding: 7,
          fontSize: 12,
        },
      }}
      onChange={(event, newValue) => {
        handleChange({ target: { name, value: newValue?.value || "" } });
        setMainValue(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      id={name}
      options={option}
      size="medium"
      renderInput={(params) => (
        <Input {...params} name={name} variant="outlined" label={label} />
      )}
    />
  );
};

export default InputAutoComplete;
