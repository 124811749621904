import React from "react";

// import { makeStyles } from "@material-ui/core/styles";

import { TextField } from "@mui/material";

const InputDefault = React.memo(
  ({ title, loading = false, value, options, isReadMode, type, ...props }) => {
    if (isReadMode) {
      return (
        <div className="text-xs text-gray-500 mb-4 bg-slate-200 p-2 rounded-lg relative mt-2">
          <div className="flex items-center justify-between absolute top-[-15px] left-1 right-0 w-full">
            <p className="text-xs">{title && `${title}:`}</p>
          </div>
          <p className="text-sm">{value}</p>
        </div>
      );
    }

    if (type === "date" || type === "datetime-local") {
      return (
        <TextField
          disabled={props.loading}
          fullWidth
          label={title}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          className={`${props.className} InputDefault`}
          style={{
            width: "100%",
          }}
          value={value}
          type={type}
          {...props}
        />
      );
    }

    if (type === "textarea") {
      return (
        <TextField
          disabled={props.loading}
          style={{ padding: "0px", width: "100%" }}
          fullWidth
          label={title}
          variant="outlined"
          multiline
          className={`InputDefault`}
          value={value}
          {...props}
        />
      );
    }

    if (type === "select") {
      return (
        <TextField
          disabled={props.loading}
          select
          variant="outlined"
          value={value || ""}
          className={`InputDefault`}
          style={{
            width: "100%",
          }}
          SelectProps={{
            native: true,
          }}
          {...props}
        >
          <option value="">[{title}]</option>
          {options.map((option, idx) => (
            <option key={idx} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      );
    }

    return (
      <TextField
        disabled={props.loading}
        fullWidth
        label={title}
        variant="outlined"
        className={`${props.className} InputDefault`}
        style={{
          width: "100%",
        }}
        value={value}
        type={type}
        {...props}
      />
    );
  },
  (prevProps, nextProps) => {
    // Evita renders si las props no han cambiado
    return (
      prevProps.value === nextProps.value &&
      prevProps.type === nextProps.type &&
      prevProps.options === nextProps.options &&
      prevProps.title === nextProps.title
    );
  }
);

export default InputDefault;
