/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { actionTypes } from "../../../redux/pedido/actions";

import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import LoadingPage from "../../../components/LoadingPage";
import Template from "../../../components/Template/TemplateCarta";
import FormPedido from "./Components/FormPedido";
import ListProducts from "./Components/ListProducts";

import { ArrowBack } from "@mui/icons-material";
import { Breadcrumbs, Button, IconButton, Typography } from "@mui/material";

import {
  add_producto_pedido,
  get_pedido_id,
} from "../../../services/pedidos.services";

import { getUser } from "../../../services/auth.services";

import toast from "react-hot-toast";
import { nameSockets } from "../../../functions/nameSockets";

const Checkout = (props) => {
  let history = useHistory();
  let { socket } = props;
  const dispatch = useDispatch();

  const [loadPage, setLoadPage] = useState(false);

  const { products } = useSelector((state) => state.reducer_carta);

  let initialValues = {
    codigo_mesa: "",
    // name: "",
    // lastname: "",
    // number: "",
    // email: "",
  };

  const { control, handleSubmit } = useForm({
    defaultValues: initialValues,
  });

  async function checkPedido(codigo) {
    try {
      let response = await get_pedido_id(codigo);

      if (response === "") {
        throw new Error("No se encontró el pedido");
      }

      return response;
    } catch (err) {
      setLoadPage(false);
      console.log(err);
      throw new Error("Error al consultar el pedido");
    }
  }

  const onClearProductCart = () => {
    dispatch({
      type: actionTypes.clearProduct,
    });
  };

  const handleClearCart = () => {
    onClearProductCart();
  };

  const handlePedir = async (data) => {
    setLoadPage(true);

    let dataPedido;

    try {
      dataPedido = await checkPedido(data.codigo_pedido);
    } catch (err) {
      setLoadPage(false);
      toast.error("El código que ingreso no existe.");

      console.error("error", err);
      return null;
    }

    if (dataPedido.id_estadopedido === 4 || dataPedido.id_estadopedido === 5) {
      setLoadPage(false);
      toast.error("El código que ingreso ya se encuentrá cerrado.");
      return null;
    }

    try {
      const jsonProduct = products.map((el) => ({
        id_plato: el.id_plato,
        amount: el.totAmount,
        texto: "",
        dosXuno: el?.dosXuno ? 1 : 0,
      }));

      const json = {
        codigo_pedido: data.codigo_pedido,
        id_pedido: dataPedido.id_pedido,
        products: jsonProduct,
      };

      await add_producto_pedido(json);
      toast.success("Productos agregados correctamente.");

      // refrescar todos los productos para cocina
      socket.emit("client:refreshProducts", getUser().empresa.id_empsede);

      // refrescar todos los productos de ese pedido (ID, CODIGO)
      socket.emit("client:refreshProductStatus", {
        id_empsede: getUser().empresa.id_empsede,
        cod: data.codigo_pedido,
      });

      // refresar todads las ordenes porque se ingreso uno nuevo
      socket.emit(
        nameSockets.MAIN.client.refreshOrders,
        getUser().empresa.id_empsede
      );

      handleClearCart();

      history.push(`/carta/pedido/${data.codigo_pedido}`);
    } catch (err) {
      toast.error("No se puedo agregar los productos.");
      console.error("error", err);
    }

    setLoadPage(false);
  };

  const handleBack = () => {
    history.push("/carta");
  };

  useEffect(() => {
    if (products.length === 0) {
      history.push("/carta");
    }
  }, []);

  return (
    <Template title="Checkout">
      <LoadingPage open={loadPage} setOpen={setLoadPage} />
      <div className="navProduct">
        <Breadcrumbs
          aria-label="breadcrumb"
          style={{ padding: "20px 5px 20px 5px" }}
        >
          <IconButton onClick={() => handleBack()} size="small">
            <ArrowBack />
          </IconButton>

          <Typography color="text.primary">Resumen del pedido</Typography>
        </Breadcrumbs>
      </div>

      <form onSubmit={handleSubmit(handlePedir)} autoComplete="off">
        <div style={{ padding: 10, marginBottom: 5 }}>
          <ListProducts />
        </div>
        <div style={{ padding: 10, marginTop: 5 }}>
          <FormPedido control={control} />
        </div>
        <div style={{ position: "sticky", bottom: "10px", zIndex: 2 }}>
          <Button fullWidth variant="contained" color="secondary" type="submit">
            PEDIR
          </Button>
        </div>
      </form>
      <br />
    </Template>
  );
};

export default Checkout;
