import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";

import StepCheckOut from "./Checkout";
import StepCliente from "./Cliente";
import StepMesas from "./Mesas";
import StepReserva from "./Reserva";

import LoadingPage from "../../../components/LoadingPage";
import StepperComponent from "./Component/StepperComponent";

import CloseIcon from "@mui/icons-material/Close";

import randomId from "random-id";

import { getUser } from "../../../services/auth.services";
import {
  add_pedido,
  number_opentables,
  search_clientcarta,
} from "../../../services/pedidos.services";

import toast from "react-hot-toast";

import { PointOfSale } from "@mui/icons-material";
import TabPanel from "../../../components/TabPanel";
import { nameSockets } from "../../../functions/nameSockets";
import PedidoSecundario from "./PedidoSecundario";
import "./style.mesas.scss";

const TYPE_CAJA = {
  MAIN: 1,
  SECONDARY: 2,
};

const CustomDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ButtonValidate = (props) => {
  const { selected, steps, activeStep, handleNext, handleAction } = props;

  const { mesa } = selected;

  const stepLength = steps.length;

  if (activeStep === 0) {
    return (
      <Button
        onClick={handleNext}
        variant="contained"
        color="success"
        disabled={mesa.id_mesa === ""}
      >
        Siguiente
      </Button>
    );
  }

  if (activeStep === stepLength) {
    return (
      <Button onClick={handleAction} variant="contained" color="error">
        Aperturar
      </Button>
    );
  }

  return (
    <Button onClick={handleNext} variant="contained" color="success">
      Siguiente
    </Button>
  );
};

const StepsByCompany = (isDHuella, typeCaja) => {
  if (isDHuella && typeCaja === TYPE_CAJA.MAIN) {
    return ["Mesa", "Reserva"];
  }

  if (isDHuella && typeCaja === TYPE_CAJA.SECONDARY) {
    return ["Checkout"];
  }

  return ["Mesa", "Cliente", "Reserva"];
};

const Apertura = (props) => {
  const {
    open,
    setOpen,
    socket,
    onRedirect,
    isCloseCajaMain,
    isNotActiveToday,
    dataPedido,
  } = props;

  const [loadPage, setLoadPage] = useState(false);

  const [value, setValue] = React.useState(0);

  const isDHuella = getUser()?.empresa?.id_emp === 4;

  const INITIAL_VALUES = {
    typeCaja: TYPE_CAJA.MAIN,
    pedido: {
      codigo_pedido: "",
    },
    mesa: {
      id_mesa: "",
      codigo_mesa: "",
      amount_people: 0,
    },
    reserva: {
      id_reserva: "",
      fechareserva_reserva: "",
      nombres_reserva: "",
      apellidos_reserva: "",
      released_reserva: 0,
    },
    cliente: {
      id_cliente: "",
      nombre_cliente: "",
      apepaterno_cliente: "",
      apematerno_cliente: "",
      telf_cliente: "",
      correo_cliente: "",
    },
  };

  const [selected, setSelected] = useState(INITIAL_VALUES);

  const steps = StepsByCompany(isDHuella, selected.typeCaja);

  const [dataTable, setDataTable] = useState({
    data: [],
    load: false,
    err: false,
  });

  const [dataCliente, setDataCliente] = useState({
    data: [],
    load: false,
    err: false,
  });

  const [dataReserva, setDataReserva] = useState({
    data: [],
    load: false,
    err: false,
  });

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeValue = (event, newValue) => {
    setValue(newValue);

    if (newValue === 0) {
      handleReset(0);
      setSelected({ ...INITIAL_VALUES, typeCaja: TYPE_CAJA.MAIN });
    }

    if (newValue === 1) {
      handleReset(1);
      setSelected({ ...INITIAL_VALUES, typeCaja: TYPE_CAJA.SECONDARY });
    }
  };

  /*
    STEPPER
  */

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = (number) => {
    setActiveStep(number);
  };

  const handleSkip = () => {
    if (activeStep === 0) {
      setSelected({
        ...selected,
        reserva: {
          ...selected.reserva,
          id_reserva: "",
        },
      });
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      let newSkipped = new Set(prevSkipped.values());

      newSkipped.add(activeStep);

      return newSkipped;
    });
  };

  /*
    HANDLE SELECTED TABLE
  */

  const handleGetNumberOpenTables = async (id_mesa) => {
    try {
      return await number_opentables({
        id_mesa,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSetSelectedOnlyMesa = async (data) => {
    let clienteSelected = INITIAL_VALUES.cliente;

    setLoadPage(true);

    if (data.id_mesa) {
      const cliente = await searchClientCarta(data.id_mesa);

      if (cliente) {
        clienteSelected = {
          apematerno_cliente: cliente.apematerno_cliente,
          apepaterno_cliente: cliente.apepaterno_cliente,
          correo_cliente: cliente.correo_cliente,
          id_cliente: cliente.id_cliente,
          nombre_cliente: cliente.nombre_cliente,
          telf_cliente: cliente.telf_cliente,
        };

        let step = isDHuella ? 2 : 1;
        setActiveStep(step);
      }
    }

    setLoadPage(false);

    setSelected({
      ...INITIAL_VALUES,
      cliente: clienteSelected,
      pedido: {
        ...INITIAL_VALUES.pedido,
      },
      mesa: data,
    });
  };

  const handleSetSelected = async (data, type) => {
    if (!type) {
      setSelected({
        ...selected,
        ...data,
      });

      return;
    }

    setSelected({
      ...selected,
      [type]: {
        ...data,
      },
    });
  };

  const handleAperturar = async () => {
    try {
      if (
        isDHuella &&
        selected.typeCaja === TYPE_CAJA.MAIN &&
        !selected.reserva?.id_reserva
      ) {
        toast.error("Es necesario seleccionar la reserva.");
        return null;
      }

      if (
        isDHuella &&
        selected.typeCaja === TYPE_CAJA.SECONDARY &&
        !selected.cliente?.nombre_cliente
      ) {
        toast.error("Es necesario seleccionar el nombre del cliente.");
        return null;
      }

      const codGenerated = await generate_codpedido(
        selected.cliente.id_mesa,
        selected.mesa.codigo_mesa,
        selected.reserva.id_reserva
      );

      setLoadPage(true);

      const { codigo_pedido } = await add_pedido({
        codigo_pedido: codGenerated,
        id_mesa: selected.mesa.id_mesa,
        id_cliente: selected.cliente.id_cliente,
        amount_people: selected.mesa.amount_people,
        id_reserva: selected.reserva.id_reserva,
        type_caja: selected.typeCaja,
        optionalFullName: selected.cliente?.nombre_cliente,
        released_reserva: selected.reserva?.released_reserva || 0,
      });

      toast.success("Orden aperturada correctamente.");

      setLoadPage(false);

      /*
        HANDLE GET PEDIDO
      */

      // refresar todads las ordenes porque se ingreso uno nuevo
      const EMIT_REFRESH =
        selected.typeCaja === TYPE_CAJA.MAIN
          ? nameSockets.MAIN.client.refreshOrders
          : nameSockets.SECONDARY.client.refreshOrders;

      socket.emit(EMIT_REFRESH, getUser().empresa.id_empsede);

      handleClose();

      onRedirect(codigo_pedido);
    } catch (err) {
      setLoadPage(false);
      console.log(err);
    }

    /* HERE! */
  };

  const generate_codpedido = async (id_mesa, codigo_mesa, id_reserva) => {
    const user = getUser().name || "";

    const initUser = user.split(" ")[0] || "";

    const newInitUser = initUser.substr(0, 2).toUpperCase();

    const fecha = new Date();

    const formatoMap = {
      hhh: fecha.getHours(),
      hmm: fecha.getMinutes(),
      hss: fecha.getSeconds(),
      dd: fecha.getDate(),
      mm: fecha.getMonth() + 1,
      yy: parseInt(fecha.getFullYear().toString().slice(-2)),
      yyyy: fecha.getFullYear(),
    };

    let newNumberOpen = randomId(4, "0");

    if (id_mesa) {
      const numberOpen = await handleGetNumberOpenTables(id_mesa);
      newNumberOpen = parseInt(numberOpen) + 1;
    }

    if (id_reserva) {
      return `${id_reserva}`;
    }

    return `${newInitUser}${formatoMap.dd}-${formatoMap.hhh}${formatoMap.hmm}-${codigo_mesa}X${newNumberOpen}`;
  };

  const searchClientCarta = async (id_mesa) => {
    const response = await search_clientcarta({
      id_mesa,
    });

    return response;
  };

  useEffect(() => {
    if (open) {
      setSelected(INITIAL_VALUES);

      handleReset(0); /* RESET ORDEN */
      setValue(0); /* RESET TABS */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CustomDialogTitle
          style={{ borderBottom: "1px #eee solid", background: "#f5f5f5" }}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {"Aperturar pedido"}
        </CustomDialogTitle>
        <DialogContent style={{ padding: "20px 8px" }}>
          <LoadingPage open={loadPage} setOpen={setLoadPage} />

          {/**
           * DEJANDO HUELLA
           */}

          {isDHuella && (
            <>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChangeValue}
                  aria-label="menu tabas"
                >
                  <Tab label="Menú principal" />
                  <Tab label="Menú Secundario" />
                </Tabs>
              </Box>
            </>
          )}

          <TabPanel value={value} index={0}>
            {isNotActiveToday && (
              <div style={{ textAlign: "center", margin: "auto" }}>
                <PointOfSale style={{ fontSize: 40 }} /> <br />
                <Typography component={"h6"} variant={"h6"}>
                  Tu cuenta no se encuentrá habilitada para hoy, si necesitas
                  que este activada, comunícalo al cajero/administrador.
                </Typography>
              </div>
            )}

            {isCloseCajaMain && (
              <div style={{ textAlign: "center", margin: "auto" }}>
                <PointOfSale style={{ fontSize: 40 }} /> <br />
                <Typography component={"h5"} variant={"h5"}>
                  No existe caja abierta para el dia de hoy.
                </Typography>
              </div>
            )}

            {!isNotActiveToday && !isCloseCajaMain && (
              <StepperComponent
                activeStep={activeStep}
                isStepOptional={isStepOptional}
                isStepSkipped={isStepSkipped}
                steps={steps}
                mesas={
                  <StepMesas
                    dataTable={dataTable}
                    setDataTable={setDataTable}
                    selected={selected}
                    handleSetSelectedOnlyMesa={handleSetSelectedOnlyMesa}
                  />
                }
                reserva={
                  <StepReserva
                    dataReserva={dataReserva}
                    dataPedido={dataPedido}
                    setDataReserva={setDataReserva}
                    selected={selected}
                    handleSetSelected={handleSetSelected}
                  />
                }
                cliente={
                  <StepCliente
                    dataCliente={dataCliente}
                    setDataCliente={setDataCliente}
                    selected={selected}
                    handleSetSelected={handleSetSelected}
                  />
                }
                checkOut={
                  <StepCheckOut
                    selected={selected}
                    handleSetSelected={handleSetSelected}
                  />
                }
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PedidoSecundario
              selected={selected}
              handleSetSelected={handleSetSelected}
            />
          </TabPanel>
        </DialogContent>

        <DialogActions
          style={{ borderTop: "1px #eee solid", background: "#f5f5f5" }}
        >
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            color="error"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Atrás
          </Button>

          {/* {isStepOptional(activeStep) && (
            <Button color="warning" onClick={handleSkip} sx={{ mr: 1 }}>
              Omitir
            </Button>
          )} */}

          <ButtonValidate
            data={null}
            steps={steps}
            selected={selected}
            activeStep={activeStep}
            handleNext={handleNext}
            handleAction={handleAperturar}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Apertura;
