import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomTable from "../../../atomic/organisms/Table.organisms";
import ContainerInput from "../../../components/ContainerInput";
import Template from "../../../components/Template";
import {
  fetchDetailCustomerRifaByOne,
  fetchDetailRifaByOne,
  fetchRifa,
} from "../../../services/rifa.services";

import FilterDetailRifa from "./detail-filters.rifa";
import ModallDetailRifa from "./modal-detail-rifa";
import RifaBreadCrumb from "../bread-crumb.rifa";
import { IconButton } from "@mui/material";
import { Check, Download, Print } from "@mui/icons-material";
import { utils, writeFile } from "xlsx";
import { getUrlFront } from "../../../functions/utilidades.function";
import ValidatorDialog from "./modal-detail-validator";

const DetailRifasCustomer = () => {
  const { id } = useParams();

  const [dataDetailRifa, setDataDetailRifa] = useState([]);
  const [dataRifa, setDataRifa] = useState(null);

  const [loading, setLoading] = useState(false);
  const [usuarioSelected, setUsuarioSelected] = useState(null);

  const [openValidator, setOpenValidator] = useState(false);
  const [validatorData, setValidatorData] = useState(null);

  const numberRaffleTicketsSold = useCallback(() => {
    if (dataDetailRifa.length === 0) {
      return 0;
    }

    return dataDetailRifa.reduce(
      (acc, el) => acc + el?.amountcustomer_rifa || 0,
      0
    );
  }, [dataDetailRifa]);

  const [openCrud, setOpenCrud] = useState(false);

  async function handleSearch(value) {
    let response = await fetchDetailRifaByOne(id);

    if (value?.searchName !== "") {
      response = response.filter((el) => {
        const usu_nombre = el?.usu_nombre ? el?.usu_nombre.toLowerCase() : "";
        const usu_rut = el?.usu_rut ? el?.usu_rut.toLowerCase() : "";
        const usu_phone = el?.usu_phone ? el?.usu_phone.toLowerCase() : "";
        const usu_email = el?.usu_email ? el?.usu_email.toLowerCase() : "";

        return (
          usu_nombre.includes(value.searchName.toLowerCase()) ||
          usu_rut.includes(value.searchName.toLowerCase()) ||
          usu_phone.includes(value.searchName.toLowerCase()) ||
          usu_email.includes(value.searchName.toLowerCase())
        );
      });
    }

    setDataDetailRifa(response);
  }

  async function fetchData() {
    setLoading(true);
    try {
      const response = await fetchDetailRifaByOne(id);

      setDataDetailRifa(response);
    } catch (err) {
      setDataDetailRifa([]);
    } finally {
      setLoading(false);
    }
  }

  async function fetchDataRifa() {
    setLoading(true);
    try {
      let response = await fetchRifa();

      console.log("response", response);

      response = response.find((el) => Number(el.id_rifa) === Number(id));

      setDataRifa(response);
    } catch (err) {
      setDataRifa([]);
    } finally {
      setLoading(false);
    }
  }

  const handleDownload = async (rdetailId) => {
    let response = await fetchDetailCustomerRifaByOne(rdetailId);
    response = response.filter((el) => el?.id_rcustomer !== null);
    response = response.map((el) => ({
      "Número rifa": el?.number_rcustomer || "",
      Nombres: `${el?.nombre_cliente || ""} ${el?.apepaterno_cliente || ""} ${
        el?.apematerno_cliente || ""
      }`,
      Comuna: el?.comuna_cliente || "",
      Teléfono: el?.telf_cliente || "",
      Correo: el?.correo_cliente || "",
    }));

    let wb = utils.book_new();
    let ws = utils.json_to_sheet(response);
    let date = dayjs().format("DD-MM-YYYY");
    utils.book_append_sheet(wb, ws, `Reporte`);
    writeFile(wb, `${date}/RIFAS.xlsx`);
  };

  const handleDownloadCSV = async () => {
    const response = dataDetailRifa.map((el) => ({
      Nombres: `${el?.usu_nombre || ""} ${el?.usu_apepaterno || ""} ${
        el?.usu_apematerno || ""
      }`,
      RUT: el?.usu_rut || "",
      Teléfono: el?.usu_phone || "",
      Correo: el?.usu_email || "",
      "Rifas vendidas": el?.amountcustomer_rifa || 0,
      "Fecha de creación": dayjs(el?.created_rdetail).format("DD/MM/YYYY"),
    }));

    let wb = utils.book_new();
    let ws = utils.json_to_sheet(response);
    let date = dayjs().format("DD-MM-YYYY");
    utils.book_append_sheet(wb, ws, `Reporte`);
    writeFile(wb, `${date}/DETALLE-RIFAS.xlsx`);
  };

  const handleOpenValidator = async (rdetailId) => {
    const rDetailFinded = dataDetailRifa.find(
      (el) => el.id_rdetail === rdetailId
    );

    setOpenValidator(true);
    setValidatorData(rDetailFinded);
  };

  useEffect(() => {
    fetchDataRifa();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Template title="Detalle de Rifas">
      <div className="pb-5">
        <RifaBreadCrumb
          name={dataRifa?.name_rifa || ""}
          link={"/rifas"}
          linkName={"Rifas"}
        />
      </div>

      <h2 className="text-1xl font-bold mb-3">Detalle de rifas</h2>
      <div className="grid grid-cols-12 gap-3 bg-slate-300 p-4 rounded-md mb-5 text-sm">
        <div className="col-span-12 sm:col-span-3">
          Nombre:{" "}
          <span className="block text-purple-900 font-semibold">
            {dataRifa?.name_rifa || ""}{" "}
          </span>
        </div>
        <div className="col-span-12 sm:col-span-3">
          Cantidad por rifa:{" "}
          <span className="block text-purple-900 font-semibold">
            {dataRifa?.amount_rifa || 0}
          </span>
        </div>
        <div className="col-span-12 sm:col-span-3">
          Fecha creación:
          <span className="block text-purple-900 font-semibold">
            {dayjs(dataRifa?.createdat_rifa).format("DD/MM/YYYY")}
          </span>
        </div>
        <div className="col-span-12 sm:col-span-3">
          Fecha de sorteo:
          <span className="block text-purple-900 font-semibold">
            {dataRifa?.drawdate_rifa &&
              dayjs(dataRifa?.drawdate_rifa).format("DD/MM/YYYY")}
          </span>
        </div>
        <div className="col-span-12 sm:col-span-3">
          Tipo:
          <span className="block text-purple-900 font-semibold">
            {dataRifa?.type_rifa || ""}{" "}
          </span>
        </div>

        {dataRifa?.type_rifa === "Graduaciones" && (
          <>
            <div className="col-span-12 sm:col-span-3">
              Nombre de colegio:
              <span className="block text-purple-900 font-semibold">
                {dataRifa?.nameschool_rifa || ""}{" "}
              </span>
            </div>
            <div className="col-span-12 sm:col-span-3">
              Curso:
              <span className="block text-purple-900 font-semibold">
                {dataRifa?.namecourse_rifa || ""}{" "}
              </span>
            </div>
          </>
        )}

        <div className="col-span-12 sm:col-span-3">
          Cantidad de tickets vendidos:
          <span className="block text-purple-900 font-semibold">
            {numberRaffleTicketsSold()}
          </span>
        </div>

        <div className="col-span-12 sm:col-span-12">
          Observaciones:
          <span className="block text-purple-900 font-semibold">
            {dataRifa?.observations_rifa || 0}
          </span>
        </div>
      </div>

      <FilterDetailRifa
        onLoadData={loading}
        onSearchData={handleSearch}
        onOpenAddModal={setOpenCrud}
        onDownloadCSV={() => handleDownloadCSV()}
      />

      <ContainerInput className="!p-0">
        <CustomTable
          columns={[
            {
              id: "usu_nombre",
              label: "Nombre",
              minWidth: 90,
              align: "left",
            },
            {
              id: "usu_rut",
              label: "RUT",
              minWidth: 100,
              align: "left",
            },
            {
              id: "amountcustomer_rifa",
              label: "Rifas vendidas",
              minWidth: 100,
              align: "left",
            },
            {
              id: "usu_phone",
              label: "Teléfono",
              minWidth: 100,
              align: "left",
            },
            {
              id: "usu_password",
              label: "Contraseña",
              minWidth: 100,
              align: "left",
            },
            {
              id: "usu_email",
              label: "Correo electrónico",
              minWidth: 100,
              align: "left",
            },
            {
              id: "datevalidated_rdetail",
              label: "Fec. de validación",
              minWidth: 150,
              align: "left",
              format: (el) => {
                return el
                  ? dayjs(el).utc().format("DD/MM/YYYY")
                  : "No validado";
              },
            },
            {
              id: "created_rdetail",
              label: "Fec. de creación",
              minWidth: 150,
              align: "left",
              format: (el) => {
                return dayjs(el).utc().format("DD/MM/YYYY");
              },
            },
            {
              id: "id_rdetail",
              label: "Link",
              minWidth: 300,
              align: "left",
              format: (el) => {
                const link = `${getUrlFront()}/public/my-rifas/${id}/${el}`;
                return (
                  <a
                    rel="noreferrer"
                    without
                    target="_blank"
                    href={link}
                    className="text-blue-500 hover:underline"
                  >
                    {link}
                  </a>
                );
              },
            },
            {
              id: "id_rdetail",
              label: "Validar",
              minWidth: 60,
              align: "left",
              format: (el) => {
                return (
                  <IconButton
                    color="secondary"
                    type="button"
                    onClick={() => handleOpenValidator(el)}
                  >
                    <Check />
                  </IconButton>
                );
              },
            },
            {
              id: "id_rdetail",
              label: "Descargar",
              minWidth: 60,
              align: "center",
              format: (el) => {
                return (
                  <IconButton
                    color="primary"
                    type="button"
                    onClick={() => handleDownload(el)}
                  >
                    <Print />
                  </IconButton>
                );
              },
            },
          ]}
          rows={dataDetailRifa}
          load={loading}
          error={false}
          pagination={true}
          actionEdit={(element) => {
            setOpenCrud(true);
            setUsuarioSelected({ ...element });
          }}
          actionDelete={(element) => {
            setOpenCrud(true);
            setUsuarioSelected({ ...element, delete: true });
          }}
        />
      </ContainerInput>

      <ModallDetailRifa
        id={id}
        userSelected={usuarioSelected}
        clearSelected={() => setUsuarioSelected(null)}
        open={openCrud}
        setOpen={setOpenCrud}
        callBack={() => {
          fetchData();
        }}
      />

      <ValidatorDialog
        open={openValidator}
        setOpen={setOpenValidator}
        clearSelected={() => setValidatorData(null)}
        validatorData={validatorData}
        callBack={() => {
          fetchData();
          fetchDataRifa();
        }}
      />
    </Template>
  );
};

export default DetailRifasCustomer;
