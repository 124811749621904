/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import Template from "../../components/Template";

import { checkToken } from "../../services/auth.services";
import {
  add_producto_pedido,
  change_garzon,
  change_mesa,
  change_status_order,
  delete_products,
  get_pedido_caja,
  get_pedido_id,
  get_pedido_producto,
  get_save_comment,
  join_pedidos,
  motiveDelete,
  saveSplit_account,
  update_discount,
  update_liberados,
  update_payaccount,
} from "../../services/pedidos.services";

import { get_caja } from "../../services/caja.services";

import { getUser } from "../../services/auth.services";

import ContainerInput from "../../components/ContainerInput";
import LoadingPage from "../../components/LoadingPage";

import {
  AttachMoney,
  NoMeals,
  PointOfSale,
  TableBar,
} from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";

import AcctionButtons from "./Components/AcctionButtons";
import Comments from "./Components/Comments";
import DataPedido from "./Components/DataPedido";
import SeeProduct from "./Components/SeeProduct";
import TablePedido from "./Components/TablePedidos";
import TotCount from "./Components/TotCount";

import DialogAddProduct from "./Components/Dialog/DialogAddProduct";
import DialogAperturar from "./Components/Dialog/DialogAperturar";
import DialogApplyDiscount from "./Components/Dialog/DialogApplyDiscount";
import DialogBenefit from "./Components/Dialog/DialogBenefit";
import DialogChangeGarzon from "./Components/Dialog/DialogChangeGarzon";
import DialogChangeTable from "./Components/Dialog/DialogChangeTable";
import DialogCloseAccount from "./Components/Dialog/DialogCloseAccount";
import DialogDeleteProducts from "./Components/Dialog/DialogDeleteProducts";
import DialogIndicator from "./Components/Dialog/DialogIndicator";
import DialogInfoClient from "./Components/Dialog/DialogInfoClient";
import DialogJoinTable from "./Components/Dialog/DialogJoinTable";
import DialogOpenAccount from "./Components/Dialog/DialogOpenAccount";
import DialogPayAccount from "./Components/Dialog/DialogPayAccount";
import DialogPrintAccount from "./Components/Dialog/DialogPrintAccount";
import DialogSplitAccount from "./Components/Dialog/DialogSplitAccount";

import DialogApertura from "../orders/Apertura";

import toast from "react-hot-toast";

import { useReactToPrint } from "react-to-print";

import "./style.checker.scss";

import { Loyalty } from "@mui/icons-material";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/lab";
import { Fab } from "@mui/material";
import "moment/locale/es";
import Moment from "react-moment";
import { nameCamarero } from "../../functions/nameCamarero";

const OrderDetail = (props) => {
  let { pedidoSelected, infoPedido } = props;

  if (pedidoSelected === "" || infoPedido.data.id_estadopedido === 6) {
    return (
      <ContainerInput
        style={{
          textAlign: "center",
          height: "100%",
          background: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ textAlign: "center", margin: "auto" }}>
          <NoMeals style={{ fontSize: 40 }} /> <br />
          <Typography component={"h5"} variant={"h5"}>
            Tiene que seleccionar algún pedido.
          </Typography>
        </div>
      </ContainerInput>
    );
  }

  return <>{props.children}</>;
};

const Checker = (props) => {
  let history = useHistory();
  let { socket } = props;
  const [openSpeedDial, setOpenSpeedDial] = useState(false);

  /**
   * @description: Estados de la página
   **/

  const [infoPedido, setInfoPedido] = useState({
    data: "",
    load: false,
    err: false,
  });

  const [dataPedido, setDataPedido] = useState({
    load: false,
    err: false,
    data: [],
  });

  const [dataPedidoSelected, setDataPedidoSelected] = useState({
    load: false,
    err: false,
    data: [],
  });

  const [openDialog, setOpenDialog] = useState({
    openInfoPedio: false,
    openApplyDiscount: false,
    openPayAccount: false,
    openPrintAccount: false,
    openAccount: false,
    closeAccount: false,
    openAddProduct: false,
    openDeleteProduct: false,
    openSplitAccount: false,
    openAperturar: false,
    openTable: false,
    openGarzon: false,
    openJoinTable: false,
    openBenefit: false,
    openNewTable: false,
  });

  const [dataInfoClient, setDataInfoClient] = useState("");

  const [loadPage, setLoadPage] = useState(false);

  const [pedidoSelected, setPedidoSelected] = useState("");

  const [selectedDelete, setSelectedDelete] = React.useState([]);

  const [cajaData, setCajaData] = useState(true);

  const componentRefPrint = useRef();

  /**
   * @description: Funcion por defecto para obtener los pedidos
   **/

  const handleAperturaCaja = async () => {
    try {
      const param = "oneday";
      const response = await get_caja(param);
      setCajaData(response[0] ? response[0] : false);
    } catch (err) {
      setCajaData(false);
      console.log(err);
    }
  };

  const handleGetPedidos = async (load = true) => {
    if (load) {
      try {
        setLoadPage(load);
        setDataPedido({ ...dataPedido, load: load, err: false }); /* load */

        const response = await get_pedido_caja();
        setDataPedido({ load: false, err: false, data: response }); /* data */

        load && setLoadPage(false);
      } catch (err) {
        setDataPedido({ load: false, err: true, data: [] });
        load && setLoadPage(false);
        console.log(err);
      }
      return;
    }

    try {
      const response = await get_pedido_caja();
      setDataPedido({ load: false, err: false, data: response }); /* data */
    } catch (err) {
      setDataPedido({ load: false, err: true, data: [] });
      console.log(err);
    }
  };

  const handleGetPedidoId = async (data) => {
    const { id_reserva, codigo_pedido, asistentvalue_reserva } = data;

    try {
      setLoadPage(true);
      setDataPedidoSelected({ load: true, err: false, data: [] }); /* load */

      //obtener todos los productos
      const response = await get_pedido_producto(codigo_pedido);

      /**
       * ESTO ES TEMPORAL!
       */
      const mappingNewPriceWithReserva = response.map((item) => ({
        ...item,
        precio_plato: Number(asistentvalue_reserva),
      }));

      // SI EXISTE RESERVA CAMBIA EL PRECIO DEL PRODUCTO POR EL DE LA RESERVA
      const newReponse = id_reserva ? mappingNewPriceWithReserva : response;
      
      console.log("newReponse", newReponse);

      setDataPedidoSelected({
        load: false,
        err: false,
        data: newReponse,
      }); /* data */

      setLoadPage(false);
    } catch (err) {
      setDataPedidoSelected({ load: false, err: true, data: [] }); /* err */
      setLoadPage(false);
      console.log(err);
    }
  };

  const handleGetInfoPedido = async (id, load = true) => {
    try {
      setInfoPedido({ ...infoPedido, load: load, err: false }); /* load */

      const response = await get_pedido_id(id);

      setInfoPedido({
        load: false,
        err: false,
        data: response,
      }); /* data */
    } catch (err) {
      setInfoPedido({ load: false, err: true, data: "" }); /* err */
      console.log(err);
    }
  };

  const handleSelectedOrder = (data) => {
    let { codigo_pedido } = data;

    // seleccionar pedido para ver detalle, el codigo es lo que almacena
    setPedidoSelected(codigo_pedido);

    //trae de la bd los productos de el pedido seleccionado
    handleGetPedidoId(data);

    //trae de la bd los datos del pedido
    handleGetInfoPedido(codigo_pedido);

    //limpiar los productos seleccionados
    setSelectedDelete([]);
  };

  const handleSaveComment = async (data) => {
    try {
      let json = {
        codigo_pedido: infoPedido.data.codigo_pedido,
        text: data,
      };

      await get_save_comment(json);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenDialog = (name, value) => {
    setOpenDialog({
      ...openDialog,
      [name]: value,
    });
  };

  const handleOpenAccount = async () => {
    handleChangeStatus("open");
  };

  const handleCloseAccount = async (values) => {
    await handleSaveDataDeleteAccount(values);
    await handleChangeStatus("cancel");
  };

  /**
   * @description: Funcion para la accion de los botones
   **/

  const handleResetPedido = async () => {
    // obtener todos los pedidos
    await handleGetPedidos(false);

    //refrescar data del pedido seleccionados
    setDataPedidoSelected({ ...dataPedidoSelected, data: [] });

    //refrescar la info del pedido
    setInfoPedido({ ...infoPedido, data: "" });

    //refresar el pedido seleccionado
    setPedidoSelected("");

    // setear los productos señalados
    setSelectedDelete([]);
  };

  const handleSaveApplyDiscount = async (data) => {
    try {
      const json = {
        ...data,
        codigo_pedido: infoPedido.data.codigo_pedido,
      };

      let response = await update_discount(json);

      //si se guardo correctamente
      if (response.affectedRows === 1) {
        // altero el estado para pasar a todo los componenets
        setInfoPedido({
          ...infoPedido,
          data: {
            ...infoPedido.data,
            descuento_pedido: data.numDiscount,
            observaciondescuento_pedido: data.commentDiscount,
            tipodescuento_pedido: data.discountType,
            cover_pedido: data.amountCover,
            form_benefit: data.formBenefit,
            id_ticketBenefit: data.id_ticketBenefit,
          },
        });
      }

      setLoadPage(false);

      return { status: "success" };
    } catch (err) {
      handleOpenDialog("openApplyDiscount", true);
      setLoadPage(false);
      console.log(err);
      return { status: "error" };
    }
  };

  const handleUpdateLiberados = async (amount, price) => {
    setInfoPedido({
      ...infoPedido,
      data: {
        ...infoPedido.data,
        amountliberados_pedido: amount,
        priceliberados_pedido: price,
      },
    });

    await update_liberados({
      codigo_pedido: infoPedido.data.codigo_pedido,
      amountliberados_pedido: amount,
      priceliberados_pedido: price,
    });
  };

  const handleSavePayAccount = async (data) => {
    try {
      setLoadPage(true);

      const json = {
        ...data,
        codigo_pedido: infoPedido.data.codigo_pedido,
        id_ticketbenefit: infoPedido.data?.id_ticketbenefit || "",
      };

      const response = await update_payaccount(json);
      setLoadPage(false);

      //si se guardo correctamente
      if (response.affectedRows === 1) {
        handleResetPedido();
        // refresar todads las ordenes porque se ingreso uno nuevo
        socket.emit("client:refreshOrders", getUser().empresa.id_empsede);

        // refrescar todos los productos para cocina
        socket.emit("client:refreshProducts", getUser().empresa.id_empsede);

        return { status: "success" };
      } else {
        handleOpenDialog("openPayAccount", true);
        return { status: "error" };
      }
    } catch (err) {
      handleOpenDialog("openPayAccount", true);
      setLoadPage(false);
      console.log(err);
      return { status: "error" };
    }
  };

  const handleSaveDataDeleteAccount = async (values) => {
    try {
      const json = {
        codigo_pedido: infoPedido.data.codigo_pedido,
        ...values,
      };

      await motiveDelete(json);
    } catch (err) {
      setLoadPage(false);
      console.log(err);
    }
  };

  const handleChangeStatus = async (value) => {
    try {
      const json = {
        status: value,
        codigo_pedido: infoPedido.data.codigo_pedido,
      };

      setLoadPage(true);
      await change_status_order(json);

      // refrescar todos los productos y el pedido
      await handleGetPedidos(false);

      //refresca el estado de la cuenta a print
      await handleGetInfoPedido(infoPedido.data.codigo_pedido);

      // refrescar todos los productos para cocina
      socket.emit("client:refreshProducts", getUser().empresa.id_empsede);

      // refrescar todos los productos de ese pedido (ID, CODIGO)
      socket.emit("client:refreshProductStatus", {
        id_empsede: getUser().empresa.id_empsede,
        cod: infoPedido.data.codigo_pedido,
      });

      // refresar todads las ordenes porque se ingreso uno nuevo
      socket.emit("client:refreshOrders", getUser().empresa.id_empsede);

      setLoadPage(false);
    } catch (err) {
      setLoadPage(false);
      console.log(err);
    }
  };

  const handleSaveAddProduct = async (json) => {
    setLoadPage(true);
    await add_producto_pedido(json);

    toast.success("Productos agregando correctamente.");

    // refrescar todos los productos para cocina
    socket.emit("client:refreshProducts", getUser().empresa.id_empsede);

    // refrescar todos los productos de ese pedido (ID, CODIGO)
    socket.emit("client:refreshProductStatus", {
      id_empsede: getUser().empresa.id_empsede,
      cod: infoPedido.data.codigo_pedido,
    });

    // refresar todads las ordenes porque se ingreso uno nuevo
    socket.emit("client:refreshOrders", getUser().empresa.id_empsede);

    handleSelectedOrder({ codigo_pedido: infoPedido.data.codigo_pedido });

    setLoadPage(false);
  };

  const handleSelectedDelete = (value) => {
    setSelectedDelete(value);
  };

  const handleDeleteProduct = async (data) => {
    try {
      const json = {
        productsDelete: data,
        id_pedido: infoPedido.data.id_pedido,
        codigo_pedido: infoPedido.data.codigo_pedido,
      };

      setLoadPage(true);

      await delete_products(json);

      // refrescar todos los productos y el pedido
      await handleGetPedidos(false);

      await handleGetInfoPedido(infoPedido.data.codigo_pedido);

      // refrescar todos los productos para cocina
      socket.emit("client:refreshProducts", getUser().empresa.id_empsede);

      // refrescar todos los productos de ese pedido (ID, CODIGO)
      // socket.emit("client:refreshProductStatus", {
      //   id_empsede: getUser().empresa.id_empsede,
      //   cod: infoPedido.data.codigo_pedido,
      // });

      // refresar todads las ordenes porque se ingreso uno nuevo
      socket.emit("client:refreshOrders", getUser().empresa.id_empsede);

      handleSelectedOrder({ codigo_pedido: infoPedido.data.codigo_pedido });

      // setear los productos señalados
      setSelectedDelete([]);

      setLoadPage(false);
    } catch (err) {
      setLoadPage(false);
      console.log(err);
    }
  };

  const handleSaveSplitAccount = async (data) => {
    try {
      const json = {
        infoPedido: {
          id_pedido: infoPedido.data.id_pedido,
          id_reserva: infoPedido.data.id_reserva,
          id_cliente: infoPedido.data.id_cliente,
          id_mesa: infoPedido.data.id_mesa,
          usu_id: infoPedido.data.usu_id,
          codigo_pedido: infoPedido.data.codigo_pedido,
        },
        ...data,
      };

      setLoadPage(true);

      let response = await saveSplit_account(json);

      if (response === true) {
        toast.success("Cuenta dividida correctamente.");
        handleResetPedido();

        // refrescar todos los productos para cocina
        socket.emit("client:refreshProducts", getUser().empresa.id_empsede);

        // refrescar todos los productos de ese pedido (ID, CODIGO)
        socket.emit("client:refreshProductStatus", {
          id_empsede: getUser().empresa.id_empsede,
          cod: infoPedido.data.codigo_pedido,
        });
      } else {
        toast.error("Error al dividir la cuenta, intentalo de nuevo.");
      }

      setLoadPage(false);
    } catch (err) {
      setLoadPage(false);
      console.log(err);
    }
  };

  const handleSaveChangeTable = async (data) => {
    try {
      const json = {
        id_pedido: infoPedido.data.id_pedido,
        codigo_pedido: infoPedido.data.codigo_pedido,
        ...data,
      };

      await change_mesa(json);

      toast.success("Se cambio la mesa correctamente.");
    } catch (err) {
      toast.error("Tuvimos un problema al cambiar la mesa.");
      console.log(err);
    }

    // refrescar todos los productos y el pedido
    await handleGetPedidos(false);
    // await handleGetPedidoId(infoPedido.data.codigo_pedido);
    await handleGetInfoPedido(infoPedido.data.codigo_pedido);
  };

  const handleSaveChangeGarzon = async (data) => {
    try {
      const json = {
        id_pedido: infoPedido.data.id_pedido,
        codigo_pedido: infoPedido.data.codigo_pedido,
        ...data,
      };

      await change_garzon(json);
      toast.success(`Se cambio el ${nameCamarero(false)} correctamente.`);
    } catch (err) {
      toast.error(`Tuvimos un problema al cambiar el ${nameCamarero(false)}.`);
      console.log(err);
    }

    // refrescar todos los productos y el pedido
    await handleGetPedidos(false);
    await handleGetInfoPedido(infoPedido.data.codigo_pedido);
  };

  const handleSaveJoinTable = async (data) => {
    try {
      setLoadPage(true);
      await join_pedidos(data);
      toast.success("Se unieron los pedidos correctamente.");

      await handleGetPedidos(false);
      await handleGetInfoPedido(infoPedido.data.codigo_pedido);
      await handleGetPedidoId(infoPedido.data);
      setLoadPage(false);
    } catch (err) {
      toast.error("Tuvimos un problema al unir los pedidos.");
      console.log(err);
    }

    // refrescar todos los productos y el pedido

    // refresar todads las ordenes porque se ingreso uno nuevo
    socket.emit("client:refreshOrders", getUser().empresa.id_empsede);

    // refrescar todos los productos para cocina
    socket.emit("client:refreshProducts", getUser().empresa.id_empsede);
  };

  /**
   * @description: Funcion para ejecutar todas las acciones de los botones
   **/

  const handleOpenOrder = () => {
    handleOpenDialog("openAccount", true);
  };

  const handleCloseOrder = () => {
    handleOpenDialog("closeAccount", true);
  };

  const handleCancelProduct = () => {
    if (selectedDelete.length === 0) {
      toast.error("Seleccione un producto para eliminar.");
      return;
    }
    handleOpenDialog("openDeleteProduct", true);
  };

  const handleAddProduct = () => {
    handleOpenDialog("openAddProduct", true);
  };

  const handleApplyDiscount = () => {
    handleOpenDialog("openApplyDiscount", true);
  };

  const handleSplitAccount = () => {
    handleOpenDialog("openSplitAccount", true);
  };

  const handlePrintAccount = useReactToPrint({
    content: () => componentRefPrint.current,
    documentTitle: "Orden",
    onBeforePrint: () => {
      console.log("imprimiendo before");
      handleChangeStatus("print");
    },
  });

  const handleInfoClient = async () => {
    setDataInfoClient(infoPedido.data);

    handleOpenDialog("openInfoPedio", true);
  };

  const handlePayAccount = () => {
    handleOpenDialog("openPayAccount", true);
  };

  const handleAperturar = () => {
    handleOpenDialog("openAperturar", true);
  };

  const handleChangeTable = () => {
    handleOpenDialog("openTable", true);
  };

  const handleChangeGarzon = () => {
    handleOpenDialog("openGarzon", true);
  };

  const handleJoinTable = () => {
    handleOpenDialog("openJoinTable", true);
  };

  /**
   * @description: Efectos de carga de la página
   **/

  useEffect(() => {
    async function getChecker() {
      await handleAperturaCaja();
      await handleGetPedidos(true);
    }

    getChecker();
  }, []);

  // refresca todos los pedidos
  useEffect(() => {
    const handlerRefreshOrders = (elements) => {
      console.log("refreshOrders", elements);

      if (elements.id_empsede === getUser().empresa.id_empsede) {
        setDataPedido({ data: elements.data, load: false, err: false });
      }
    };

    socket.on("server:refreshOrders", handlerRefreshOrders);
    return () => {
      socket.off("server:refreshOrders", handlerRefreshOrders);
    };
  }, []);

  // refresca los productos del pedido seleccionado
  useEffect(() => {
    const handleRefreshProduct = (response) => {
      if (
        response.codigo_pedido === pedidoSelected &&
        response.id_empsede === getUser().empresa.id_empsede
      ) {
        setDataPedidoSelected({
          data: response.data,
          load: false,
          err: false,
        });

        // recargar los datos del pedido
        handleGetInfoPedido(pedidoSelected, false);

        // refrescar todos los productos y el pedido
        handleGetPedidos(false);
      }
    };

    socket.on("server:refreshProductStatus", handleRefreshProduct);

    return () => {
      socket.off("server:refreshProductStatus", handleRefreshProduct);
    };
  }, [pedidoSelected]);

  //CHECK TOKEN
  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }
  }, []);

  //si no existe una caja abierta
  if (!cajaData) {
    return (
      <Template title="Pedidos" classmaincontainer="mainContainerTmp">
        <Fab
          onClick={() => handleOpenDialog("openIndicator", true)}
          color="secondary"
          size="large"
          aria-label="add"
          style={{
            position: "fixed",
            right: 14,
            bottom: 17,
            zIndex: 1,
            padding: 35,
          }}
        >
          <AttachMoney />
        </Fab>
        <LoadingPage open={loadPage} setOpen={setLoadPage} />

        <ContainerInput
          style={{
            textAlign: "center",
            height: "100%",
            background: "transparent",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", margin: "auto" }}>
            <PointOfSale style={{ fontSize: 40 }} /> <br />
            <Typography component={"h5"} variant={"h5"}>
              Tiene que aperturar una caja
            </Typography>
            <br />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleAperturar()}
            >
              Aperturar
            </Button>
          </div>
        </ContainerInput>

        <DialogAperturar
          open={openDialog.openAperturar}
          setOpen={(value) => handleOpenDialog("openAperturar", value)}
          handleCajaRefresh={() => handleAperturaCaja()}
        />
      </Template>
    );
  }

  //si existe la caja y ya esta cerrada
  if (cajaData && cajaData.status_caja === "CERRADO") {
    return (
      <Template title="Pedidos" classmaincontainer="mainContainerTmp">
        <Fab
          onClick={() => handleOpenDialog("openIndicator", true)}
          color="secondary"
          size="large"
          aria-label="add"
          style={{
            position: "fixed",
            right: 14,
            bottom: 17,
            zIndex: 1,
            padding: 35,
          }}
        >
          <AttachMoney />
        </Fab>
        <LoadingPage open={loadPage} setOpen={setLoadPage} />

        <ContainerInput
          style={{
            textAlign: "center",
            height: "100%",
            background: "transparent",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", margin: "auto" }}>
            <PointOfSale style={{ fontSize: 40 }} /> <br />
            <Typography component={"h6"} variant={"h6"}>
              La caja se encuentrá cerrada, hora de cierre:{" "}
              <Moment format="HH:mm:ss">{cajaData.created_cierre_caja}</Moment>
            </Typography>
            <br />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleAperturar()}
            >
              Volver aperturar
            </Button>
          </div>
        </ContainerInput>

        <DialogAperturar
          open={openDialog.openAperturar}
          setOpen={(value) => handleOpenDialog("openAperturar", value)}
          handleCajaRefresh={(value) => setCajaData(value)}
        />
      </Template>
    );
  }

  return (
    <Template title="Pedidos" classmaincontainer="mainContainerTmp">
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: "absolute", bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        onClose={() => setOpenSpeedDial(false)}
        onOpen={() => setOpenSpeedDial(true)}
        open={openSpeedDial}
        color="secondary"
      >
        <SpeedDialAction
          key={"Ventas"}
          icon={<AttachMoney />}
          tooltipTitle={"Ventas"}
          tooltipOpen
          onClick={() => {
            setOpenSpeedDial(false);
            handleOpenDialog("openIndicator", true);
          }}
        />
        <SpeedDialAction
          key={"Beneficios"}
          icon={<Loyalty />}
          tooltipTitle={"Beneficios"}
          tooltipOpen
          onClick={() => {
            setOpenSpeedDial(false);
            handleOpenDialog("openBenefit", true);
          }}
        />
        <SpeedDialAction
          key={"Mesas"}
          icon={<TableBar />}
          tooltipTitle={"Mesas"}
          tooltipOpen
          onClick={() => {
            setOpenSpeedDial(false);
            handleOpenDialog("openNewTable", true);
          }}
        />
      </SpeedDial>
      <LoadingPage open={loadPage} setOpen={setLoadPage} />
      <Grid container spacing={2} className="containerGrid">
        <Grid item xs={4} style={{ height: "100%" }}>
          <ContainerInput
            style={{
              textAlign: "center",
              height: "100%",
              overflow: "auto",
            }}
          >
            <TablePedido
              pedidoSelected={pedidoSelected}
              dataPedido={dataPedido}
              handleSelectedOrder={handleSelectedOrder}
            />
          </ContainerInput>
        </Grid>
        <Grid item xs={8} style={{ height: "100%", overflow: "auto" }}>
          <OrderDetail pedidoSelected={pedidoSelected} infoPedido={infoPedido}>
            <div
              style={{
                textAlign: "center",
                padding: "10px",
                marginBottom: 10,
                position: "sticky",
                top: "-8px",
                zIndex: 1,
                background: "#fff",
              }}
            >
              <AcctionButtons
                infoPedido={infoPedido.data}
                actions={{
                  handleOpenOrder,
                  handleCloseOrder,
                  handleCancelProduct,
                  handleAddProduct,
                  handleApplyDiscount,
                  handleSplitAccount,
                  handlePrintAccount,
                  handleInfoClient,
                  handlePayAccount,
                  handleChangeTable,
                  handleChangeGarzon,
                  handleJoinTable,
                }}
              />
            </div>
            <ContainerInput
              style={{
                textAlign: "center",
                padding: "10px",
                marginBottom: 10,
              }}
            >
              <DataPedido infoPedido={infoPedido} />
            </ContainerInput>

            <ContainerInput
              style={{
                textAlign: "center",
                padding: "10px",
                marginBottom: 10,
              }}
            >
              <SeeProduct
                selectedDelete={selectedDelete}
                handleSelectedDelete={handleSelectedDelete}
                dataProducts={dataPedidoSelected}
                setDataProducts={(data) =>
                  setDataPedidoSelected({ ...dataPedidoSelected, data })
                }
                infoPedido={infoPedido}
              />
            </ContainerInput>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Comments
                  infoPedido={infoPedido}
                  handleSaveComment={handleSaveComment}
                />
              </Grid>
              <Grid item xs={6}>
                <ContainerInput
                  style={{
                    textAlign: "center",
                    padding: "10px",
                  }}
                >
                  <TotCount
                    infoPedido={infoPedido}
                    dataProducts={dataPedidoSelected}
                    handleUpdateLiberados={handleUpdateLiberados}
                  />
                </ContainerInput>
              </Grid>
            </Grid>
          </OrderDetail>
        </Grid>
      </Grid>

      <DialogInfoClient
        open={openDialog.openInfoPedio}
        setOpen={(value) => handleOpenDialog("openInfoPedio", value)}
        data={dataInfoClient}
        callback={() => handleGetInfoPedido(infoPedido.data.codigo_pedido)}
      />

      <DialogOpenAccount
        open={openDialog.openAccount}
        setOpen={(value) => handleOpenDialog("openAccount", value)}
        callback={handleOpenAccount}
      />

      <DialogCloseAccount
        open={openDialog.closeAccount}
        setOpen={(value) => handleOpenDialog("closeAccount", value)}
        callback={handleCloseAccount}
      />

      <DialogApplyDiscount
        infoPedido={infoPedido}
        open={openDialog.openApplyDiscount}
        setOpen={(value) => handleOpenDialog("openApplyDiscount", value)}
        handleAction={handleSaveApplyDiscount}
      />

      <DialogDeleteProducts
        productsDelete={dataPedidoSelected.data}
        selectedDelete={selectedDelete}
        open={openDialog.openDeleteProduct}
        setOpen={(value) => handleOpenDialog("openDeleteProduct", value)}
        callback={handleDeleteProduct}
      />

      <DialogPayAccount
        infoPedido={infoPedido}
        dataProducts={dataPedidoSelected}
        open={openDialog.openPayAccount}
        setOpen={(value) => handleOpenDialog("openPayAccount", value)}
        callback={handleSavePayAccount}
      />

      <DialogAddProduct
        infoPedido={infoPedido}
        open={openDialog.openAddProduct}
        setOpen={(value) => handleOpenDialog("openAddProduct", value)}
        callback={handleSaveAddProduct}
      />

      <DialogSplitAccount
        infoPedido={infoPedido}
        dataProducts={dataPedidoSelected}
        open={openDialog.openSplitAccount}
        setOpen={(value) => handleOpenDialog("openSplitAccount", value)}
        callback={handleSaveSplitAccount}
      />

      <DialogChangeTable
        infoPedido={infoPedido}
        dataProducts={dataPedidoSelected}
        open={openDialog.openTable}
        setOpen={(value) => handleOpenDialog("openTable", value)}
        callback={handleSaveChangeTable}
      />

      <DialogChangeGarzon
        infoPedido={infoPedido}
        dataProducts={dataPedidoSelected}
        open={openDialog.openGarzon}
        setOpen={(value) => handleOpenDialog("openGarzon", value)}
        callback={handleSaveChangeGarzon}
      />

      <DialogJoinTable
        infoPedido={infoPedido}
        dataPedido={dataPedido}
        open={openDialog.openJoinTable}
        setOpen={(value) => handleOpenDialog("openJoinTable", value)}
        callback={handleSaveJoinTable}
      />

      <DialogIndicator
        open={openDialog.openIndicator}
        setOpen={(value) => handleOpenDialog("openIndicator", value)}
        socket={socket}
      />

      <DialogBenefit
        open={openDialog.openBenefit}
        setOpen={(value) => handleOpenDialog("openBenefit", value)}
        socket={socket}
      />

      <DialogApertura
        open={openDialog.openNewTable}
        setOpen={(value) => handleOpenDialog("openNewTable", value)}
        socket={socket}
        onRedirect={null}
      />

      <div style={{ display: "none" }}>
        <DialogPrintAccount
          open={openDialog.openPrintAccount}
          ref={componentRefPrint}
          infoPedido={infoPedido}
          dataProducts={dataPedidoSelected}
        />
      </div>
    </Template>
  );
};

export default Checker;
