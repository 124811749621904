import * as React from "react";

import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

import LoadingPage from "../../../../components/LoadingPage";

import { change_status_product } from "../../../../services/pedidos.services";

import toast from "react-hot-toast";

import "moment/locale/es";
import Moment from "react-moment";
import NotFound from "../../../../atomic/organisms/NotFound.organisms";
import { formatMoney } from "../../../../functions/formatMoney";
import { getUser } from "../../../../services/auth.services";
import ContainerInput from "../../../../components/ContainerInput";
import dayjs from "dayjs";

const ButtonsCard = ({
  isCover,
  existCover,
  row,
  handleUpdateStatus,
  handleCoverApply,
}) => {
  if (isCover) {
    return (
      <Grid
        container
        spacing={1}
        style={{
          margin: 0,
          width: "auto",
        }}
      >
        <Grid item xs={12}>
          {row.status_plapedido === "ENTREGADO" ? (
            <Button disabled variant="contained" fullWidth>
              Producto en la mesa.
            </Button>
          ) : (
            <Button
              style={{ background: "red", color: "white", fontSize: 12 }}
              fullWidth
              onClick={() => handleUpdateStatus(row.id_plapedido)}
            >
              ¿Producto en mesa?
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          {existCover ? (
            <Button
              style={{ color: "white", fontSize: 12 }}
              fullWidth
              variant="contained"
              color="warning"
              onClick={() => handleCoverApply(row.id_plapedido, "remove")}
            >
              Remover Cover
            </Button>
          ) : (
            <Button
              style={{ color: "white", fontSize: 12 }}
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => handleCoverApply(row.id_plapedido, "apply")}
            >
              Aplicar Cover
            </Button>
          )}
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      spacing={1}
      style={{
        margin: 0,
        width: "auto",
      }}
    >
      <Grid item xs={12}>
        {row.status_plapedido === "ENTREGADO" ? (
          <Button disabled variant="contained" fullWidth>
            Producto en la mesa.
          </Button>
        ) : (
          <Button
            style={{ background: "red", color: "white", fontSize: 12 }}
            fullWidth
            onClick={() => handleUpdateStatus(row.id_plapedido)}
          >
            ¿Producto en mesa?
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

const FormCuadro = (props) => {
  let { rows, handleUpdateStatus, handleCoverApply } = props;

  // QUITAR EL 3
  const IS_COVER =
    getUser()?.empresa?.id_emp === 2 || getUser()?.empresa?.id_emp === 3;

  return (
    <Grid container justifyContent="left" alignItems="left" spacing={1}>
      {rows.map((row, idx) => {
        const descuento = row.descuento_plapedido || 0;

        const totPrecio = row.precio_plato * row.amount_plapedido;

        const result = totPrecio - descuento;

        const total = result < 0 ? 0 : result;

        return (
          <Grid key={idx} item xs={12} sm={6} md={4} lg={4} zeroMinWidth>
            <ContainerInput
              className="text-xs p-2 !rounded-lg"
              style={{
                background:
                  row.status_plapedido !== "ENTREGADO" && "red !important",
              }}
            >
              <div
                style={{
                  textTransform: "uppercase",
                  textAlign: "left",
                  padding: 5,
                }}
              >
                <b>Nombre:</b> {row.nombre_plato}
              </div>
              <div
                style={{
                  textTransform: "uppercase",
                  textAlign: "left",
                  padding: 5,
                }}
              >
                <b>Hora:</b> {dayjs.utc(row.fechareg_plapedido).format("HH:mm")}
              </div>
              <div
                style={{
                  textTransform: "uppercase",
                  textAlign: "left",
                  padding: 5,
                }}
              >
                <b>Cantidad:</b> {row.amount_plapedido}
              </div>
              <div
                style={{
                  textTransform: "uppercase",
                  textAlign: "left",
                  padding: 5,
                }}
              >
                <b>Promo 2x1:</b> {row?.dosxuno_plapedido ? "SI" : "NO"}
              </div>
              <div
                style={{
                  textTransform: "uppercase",
                  textAlign: "left",
                  padding: 5,
                }}
              >
                <b>Precio:</b> {formatMoney(row.precio_plato)}
              </div>
              {row.tipodescuento_plapedido === "COVER" && (
                <div
                  style={{
                    textTransform: "uppercase",
                    textAlign: "left",
                    padding: 5,
                  }}
                >
                  <b>Cover:</b> {formatMoney(row.descuento_plapedido)}
                </div>
              )}
              <div
                style={{
                  textTransform: "uppercase",
                  textAlign: "left",
                  padding: 5,
                }}
              >
                <b>Total:</b> {formatMoney(total)}
              </div>

              <ButtonsCard
                isCover={IS_COVER}
                existCover={row.tipodescuento_plapedido === "COVER"}
                row={row}
                handleUpdateStatus={handleUpdateStatus}
                handleCoverApply={handleCoverApply}
              />
            </ContainerInput>
          </Grid>
        );
      })}
    </Grid>
  );
};

const ListProductCuadro = (props) => {
  const { handleUpdateState, dataProducts, handleCoverApply, mobile } = props;

  const rows = dataProducts.data;
  const loading = dataProducts.load;
  const [loadPage, setLoadPage] = React.useState(false);

  if (loading) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (rows.length === 0) {
    return <NotFound texto="No se encontró registros." />;
  }

  const handleUpdateStatus = async (id) => {
    try {
      const json = {
        id_plapedido: id,
        status_plapedido: "ENTREGADO",
      };

      setLoadPage(true);
      await change_status_product(json);
      handleUpdateState(id, "ENTREGADO");
      toast.success("Se actualizó correctamente.");

      setLoadPage(false);

      // refrescar todos los productos para cocina
      // socket.emit("client:refreshProducts", getUser().empresa.id_empsede);
    } catch (err) {
      toast.error("Hubo problemas al actualizar.");
      console.log(err);
    }
  };

  return (
    <>
      <LoadingPage open={loadPage} setOpen={setLoadPage} />
      <FormCuadro
        handleUpdateStatus={handleUpdateStatus}
        handleCoverApply={handleCoverApply}
        rows={rows}
        mobile={mobile}
      />
    </>
  );
};

export default ListProductCuadro;
