import axios from "axios";
import { config } from "../config";

import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

export const get_reserva = (id) => {
  const url = `${baseurl}/api/reserva/get/`;

  return axios
    .get(url, axiosHeader({ empsede: id }))
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_comunas = () => {
  const url = `${baseurl}/api/reserva/get_comunas/`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const search_reserva = (params, id) => {
  const url = `${baseurl}/api/reserva/search/`;

  return axios
    .post(url, params, axiosHeader({ empsede: id }))
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const add_reserva = (params, id) => {
  const url = `${baseurl}/api/reserva/add/`;

  return axios
    .post(url, params, axiosHeader({ empsede: id }))
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const add_public_reserva = (params, id) => {
  const url = `${baseurl}/api/reserva/add-public`;

  return axios
    .post(url, params, axiosHeader({ empsede: id }))
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const upload_reserva = (params) => {
  const url = `${baseurl}/api/reserva/upload/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const update_reserva = (params) => {
  const url = `${baseurl}/api/reserva/update_reserva/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const update_reserva_form = (params) => {
  const url = `${baseurl}/api/reserva/update/`;

  return axios
    .put(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getTotCalendar = (id_subsidiary) => {
  const url = `${baseurl}/api/reserva/getTotCalendar/${id_subsidiary}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getTotDay = (params, id) => {
  const url = `${baseurl}/api/reserva/getTotDay/`;

  return axios
    .post(url, params, axiosHeader({ empsede: id }))
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getTotDayPublic = (params, empSedeId) => {
  const url = `${baseurl}/api/reserva/get-today-public`;

  return axios
    .post(url, params, axiosHeader({ empsede: empSedeId }))
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const searchReservaWithCorreo = (params) => {
  const url = `${baseurl}/api/reserva/searchReservaWithCorreo/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const update_status_arrived = (params) => {
  const url = `${baseurl}/api/reserva/update_status_arrived/`;

  return axios
    .put(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_garzon = () => {
  const url = `${baseurl}/api/reserva/get_garzon/`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const add_reserva_person = (params) => {
  const url = `${baseurl}/api/reserva/add/clientes`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_clientReserva = (idReserva) => {
  const url = `${baseurl}/api/reserva/get/clientes/${idReserva}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const delete_clientReserva = (idReservaClient, idCliente) => {
  const url = `${baseurl}/api/reserva/delete/clientes/${idReservaClient}/${idCliente}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const save_reminderDay = (params) => {
  const url = `${baseurl}/api/reserva/save/dayReminder`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const save_reminderObservation = (params) => {
  const url = `${baseurl}/api/reserva/save/observationReminder`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_reminder = (idReserva) => {
  const url = `${baseurl}/api/reserva/get/reminder/${idReserva}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_group = () => {
  const url = `${baseurl}/api/reserva/get/group`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const add_group = (params) => {
  const url = `${baseurl}/api/reserva/add/group`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const modify_group = (params) => {
  const url = `${baseurl}/api/reserva/modify/group`;

  return axios
    .put(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const delete_group = (id) => {
  const url = `${baseurl}/api/reserva/delete/group/${id}`;

  return axios
    .delete(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const login_myreservation = (params) => {
  const url = `${baseurl}/api/reserva/my-reservations/login`;

  return axios
    .post(url, params)
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const fetch_myreservation = (id) => {
  const url = `${baseurl}/api/reserva/my-reservations/fetch/${id}`;

  return axios
    .get(url)
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const fetch_myreservation_by_reserva = (id_reserva) => {
  const url = `${baseurl}/api/reserva/my-reservations/fetch-reserva/${id_reserva}`;

  return axios
    .get(url)
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const add_menu_reserva = (params) => {
  const url = `${baseurl}/api/reserva/add/menu`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_menu_reserva = (id) => {
  const url = `${baseurl}/api/reserva/get/menu/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const update_myreservation = (id, status) => {
  const url = `${baseurl}/api/reserva/my-reservations/update/${id}`;

  return axios
    .put(url, {
      status,
    })
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
