import * as React from "react";

import { get_mesas } from "../../../../services/mesas.services";
import { get_user_today } from "../../../../services/user.services";
import FilterPedido from "./FilterPedido.tablepedido";
import ListPedido from "./ListPedido.tablepedidos";

/*
  TABLE 
*/

const handlePedidoTransform = (data) => {
  return data;
};

const TablePedido = (props) => {
  let { dataPedido, pedidoSelected, handleSelectedOrder } = props;

  const [valueFilter, setValueFilter] = React.useState({
    idGarzon: "",
    idMesa: "",
  });

  const [dataTable, setDataTable] = React.useState([]);
  const [dataGarzon, setDataGarzon] = React.useState([]);

  const [newDataPedido, setNewDataPedido] = React.useState([]);

  const handleFilter = (valueFilter) => {
    const { idGarzon, idMesa } = valueFilter;

    /**
     * IF EMPTY, CLEAR ALL
     */

    if (idGarzon === "" && idMesa === "") {
      const newDataPedido = handlePedidoTransform(dataPedido.data);
      setNewDataPedido(newDataPedido);
      return;
    }

    /**
     * IF GARZON
     */

    if (idGarzon !== "" && idMesa === "") {
      const filterNewDataPedido = handlePedidoTransform(
        dataPedido.data.filter((el) => el.usu_id === parseInt(idGarzon))
      );
      setNewDataPedido(filterNewDataPedido);
      return;
    }

    /**
     * IF TABLE
     */

    if (idGarzon === "" && idMesa !== "") {
      //FALTA MODIFICAR EL FILTRO
      const filterNewDataPedido = handlePedidoTransform(
        dataPedido.data.filter((el) => el.id_mesa === parseInt(idMesa))
      );
      setNewDataPedido(filterNewDataPedido);
      return;
    }

    const filterNewDataPedido = handlePedidoTransform(
      dataPedido.data.filter(
        (el) =>
          el.usu_id === parseInt(idGarzon) && el.id_mesa === parseInt(idMesa)
      )
    );

    setNewDataPedido(filterNewDataPedido);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    const newValue = { ...valueFilter, [name]: value };
    setValueFilter(newValue);

    /**
     * FILTRAR LA DAATA
     */
    handleFilter(newValue);
  };

  const getTable = async () => {
    try {
      const response = await get_mesas();
      const newResponse = response.map((el) => ({
        label: el.codigo_mesa,
        value: el.id_mesa,
      }));

      setDataTable(newResponse);
    } catch (err) {
      setDataTable(false);
      console.log(err.message);
    }
  };

  const getGarzones = async () => {
    try {
      const response = await get_user_today();

      const filterGarzon = response
        .filter((el) => el.usu_active_today === 1)
        .map((el) => ({
          label: el.usu_nombre,
          value: el.usu_id,
        }));

      setDataGarzon(filterGarzon);
    } catch (err) {
      setDataGarzon(false);
      console.log(err.message);
    }
  };

  React.useEffect(() => {
    getTable();
    getGarzones();
  }, []);

  React.useEffect(() => {
    handleFilter(valueFilter);
  }, [dataPedido.data]);

  return (
    <>
      <FilterPedido
        valueFilter={valueFilter}
        handleChange={handleChange}
        dataTable={dataTable || []}
        dataGarzon={dataGarzon || []}
      />
      <br />
      <ListPedido
        loadListPedidos={dataPedido.load}
        newDataPedido={newDataPedido}
        pedidoSelected={pedidoSelected}
        handleSelectedOrder={handleSelectedOrder}
      />
    </>
  );
};

export default TablePedido;
