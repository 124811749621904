import React from "react";

import TableProducto from "./Table";

import { useDispatch, useSelector } from "react-redux";
import { actionTypesPlato } from "../../../redux/plato/actions";


import ModalDeleteProduct from "./Dialog/ConfirmDelete/confirmDeleteProduct";
import ModalAddEdit from "./Dialog/CrudForm";
import ModalProducts from "./Dialog/Insumos";

import "./style.plato.scss";

import { Add, Search } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import InputDefault from "../../../components/Forms/Input";
import { get_plato } from "../../../services/plato.services";

const Productos = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState("");

  const { dataPlato } = useSelector((state) => state.reducer_plato);
  const { dataSelected_categoria } = useSelector((state) => state.reducer_menu);

  const handlenGetPlatos = (data) =>
    dispatch({
      type: actionTypesPlato.getDataPlato,
      payload: data,
    });

  const onOpenProduct = (data) =>
    dispatch({
      type: actionTypesPlato.openModalAddEdit_plato,
      payload: data,
    });

  const onSearchData = async (inputSearch) => {
    handlenGetPlatos({
      data: dataPlato.data,
      load: true,
      err: false,
    });

    const dataPlatos = await get_plato();

    let filter = dataPlatos.filter(
      (el) => el.id_categoriapla === dataSelected_categoria?.id_categoriapla
    );

    if (inputSearch) {
      filter = filter.filter((el) =>
        el.nombre_plato.toLowerCase().includes(inputSearch.toLowerCase())
      );
    }

    handlenGetPlatos({ data: filter, load: false, err: false });
  };

  const addActiveButton = !dataSelected_categoria?.id_categoriapla;

  return (
    <>
      <div className="mb-4 w-full grid grid-cols-12 gap-2 p-1">
        <div className="col-span-8">
          <form
            className="!w-full flex gap-2"
            onSubmit={(e) => {
              e.preventDefault();
              onSearchData(search);
            }}
          >
            <InputDefault
              title={"Buscar producto..."}
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              disabled={dataPlato.load}
            />
            <IconButton type="submit" color="warning" disabled={dataPlato.load}>
              <Search />
            </IconButton>
          </form>
        </div>
        <div className="col-span-4 flex end-auto justify-end">
          <Button
            variant="contained"
            color="secondary"
            disabled={addActiveButton}
            style={{
              opacity: `${addActiveButton ? "0.5" : "1"} !important`,
            }}
            onClick={() => {
              onOpenProduct(true);
            }}
          >
            Agregar <Add />
          </Button>
        </div>
      </div>

      <div className="px-2">
        <TableProducto dataPlato={dataPlato} />
      </div>

      <ModalAddEdit />
      <ModalDeleteProduct />
      <ModalProducts />
    </>
  );
};

export default Productos;
