import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs from "dayjs";
import * as React from "react";
import { updateDetailRifa } from "../../../services/rifa.services";

export default function ValidatorDialog({
  open,
  setOpen,
  validatorData,
  callBack,
  clearSelected,
}) {
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      await updateDetailRifa(validatorData?.id_rdetail, {
        datevalidated_rdetail: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      });

      await callBack();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      clearSelected();
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Validar rifa a{" "}
          <span className="uppercase text-purple-800">
            {validatorData?.usu_nombre}
          </span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Está seguro de que desea validar la rifa a{" "}
            <span className="uppercase text-purple-800">
              {validatorData?.usu_nombre}
            </span>{" "}
            con RUT{" "}
            <span className="uppercase text-purple-800">
              {validatorData?.usu_rut}
            </span>
            ? Esta acción no se puede deshacer.
            <br />
          </DialogContentText>
          <p className="text-red-500 pt-2">
            Recuerde que una vez validada la rifa, no podrá ser editada.
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit" disabled={loading}>
            Cerrar
          </Button>
          <Button
            onClick={handleSubmit}
            autoFocus
            variant="contained"
            color="success"
            disabled={loading}
          >
            Validar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
