import dayjs from "dayjs";
import { useEffect, useState } from "react";
import CustomTable from "../../atomic/organisms/Table.organisms";
import ContainerInput from "../../components/ContainerInput";
import Template from "../../components/Template";
import { fetchRifa } from "../../services/rifa.services";
import FilterRifa from "./filters.rifa";
import ModalRifa from "./modal-rifa";
import { Link } from "react-router-dom";

const Rifas = () => {
  const [dataRifa, setDataRifa] = useState([]);

  const [loading, setLoading] = useState(false);
  const [openCrud, setOpenCrud] = useState(false);
  const [rifaSelected, setRifaSelected] = useState(null);

  async function fetchData() {
    setLoading(true);
    try {
      const response = await fetchRifa();

      setDataRifa(response);
    } catch (err) {
      setDataRifa([]);
    } finally {
      setLoading(false);
    }
  }

  async function handleSearch(value) {
    let response = await fetchRifa();

    if (value?.searchName !== "") {
      response = response.filter((el) => {
        const name_rifa = el?.name_rifa ? el?.name_rifa.toLowerCase() : "";
        const observations_rifa = el?.observations_rifa
          ? el?.observations_rifa.toLowerCase()
          : "";

        return (
          name_rifa.includes(value.searchName.toLowerCase()) ||
          observations_rifa.includes(value.searchName.toLowerCase())
        );
      });
    }

    setDataRifa(response);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Template title="Rifa">
      <FilterRifa
        onLoadData={loading}
        onSearchData={handleSearch}
        onOpenAddModal={setOpenCrud}
      />

      <ContainerInput className="!p-0">
        <CustomTable
          columns={[
            {
              id: "id_rifa",
              label: "Código",
              minWidth: 30,
              align: "left",
              format: (el) => {
                const findRifa = dataRifa.find(
                  (rifa) => Number(rifa.id_rifa) === Number(el)
                );
                const firstWord =
                  findRifa?.type_rifa === "Graduaciones" ? "G" : "M";
                return `${firstWord}${el}`;
              },
            },
            {
              id: "id_rifa",
              label: "Nombre",
              minWidth: 160,
              align: "left",
              format: (el) => {
                let findRifa = dataRifa.find((rifa) => rifa.id_rifa === el);

                return (
                  <Link
                    to={`/rifas/${findRifa?.id_rifa || ""}`}
                    className="text-xs underline text-blue-800 hover:text-blue-800 font-semibold"
                  >
                    {findRifa?.name_rifa || ""}
                  </Link>
                );
              },
            },
            {
              id: "customeramount_rifa",
              label: "Cantidad por rifa",
              minWidth: 50,
              align: "left",
            },
            {
              id: "observations_rifa",
              label: "Observaciones",
              minWidth: 80,
              align: "left",
            },
            {
              id: "type_rifa",
              label: "Tipo de rifa",
              minWidth: 80,
              align: "left",
            },
            {
              id: "nameschool_rifa",
              label: "Colegio",
              minWidth: 100,
              align: "left",
            },
            {
              id: "namecourse_rifa",
              label: "Curso",
              minWidth: 100,
              align: "left",
            },
            {
              id: "drawdate_rifa",
              label: "Fecha de sorteo",
              minWidth: 80,
              align: "left",
              format: (el) => {
                return el && dayjs(el).utc().format("DD/MM/YYYY");
              },
            },
            {
              id: "createdat_rifa",
              label: "Fecha de creación",
              minWidth: 80,
              align: "left",
              format: (el) => {
                return dayjs(el).utc().format("DD/MM/YYYY");
              },
            },
          ]}
          rows={dataRifa}
          load={loading}
          error={false}
          pagination={true}
          actionEdit={(element) => {
            setOpenCrud(true);

            setRifaSelected({
              ...element,
              drawdate_rifa: dayjs(element.drawdate_rifa).format("YYYY-MM-DD"),
            });
          }}
          actionDelete={(element) => {
            setOpenCrud(true);
            setRifaSelected({ ...element, delete: true });
          }}
        />
      </ContainerInput>

      <ModalRifa
        rifaSelected={rifaSelected}
        clearSelected={() => setRifaSelected(null)}
        open={openCrud}
        setOpen={setOpenCrud}
        callBack={() => {
          fetchData();
        }}
      />
    </Template>
  );
};

export default Rifas;
