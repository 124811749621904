import "moment/locale/es";
import { createContext, useContext, useEffect, useState } from "react";
import {
  getHistoryOrders,
  searchHistoryOrders,
} from "../../services/historialCajas.services";
import {
  best_seller,
  best_seller_tip,
  best_selling_category,
  best_selling_product,
  best_selling_table,
  pay_type,
  tot_apertura,
  tot_cover,
  tot_sale,
} from "../../services/pedidos.services";

// import { useDebounce } from "../../hooks/useDebounce";

const init = {
  /* Values */
  data: [],
  isLoadData: false,
  idLoadDataIndicator: false,
  dataExcel: [],
  openModal: false,
  selected: {
    created_apertura_caja: "",
    created_cierre_caja: "",
    id_caja: null,
    id_empsede: 0,
    status_caja: "",
    tipousu_id: 0,
    totapertura_caja: 0,
    totcierre_caja: 0,
    usu_nombre: "",
  },
  dataIndicators: {
    bestSellingProduct: [],
    bestSellingCategory: [],
    bestSellingTable: [],
    bestSeller: [],
    bestSellerTip: [],
    payType: [],
    totSale: [],
    totApertura: { tot_apertura: 0 },
    totCover: {
      tot_cover: 0,
      descuenTot_cover: 0,
    },
    totIncome: 0,
    totCoverIncome: 0,
    totDiscountIncomeTeoric: 0,
    totUtilityCover: 0,
  },
  /* Methods */
  handleSearch: (value) => {},
  handleModal: () => {},
};

const AppContext = createContext(init);

export default function OrdersHistoryState({ children }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState({});
  const [dataIndicators, setDataIndicators] = useState(init.dataIndicators);

  const dataSet = data;

  async function handleSearch(params) {
    setLoading(true);
    const response = await searchHistoryOrders(params);
    setData(response || []);
    setLoading(false);
  }

  async function handleObtain() {
    setLoading(true);
    const response = await getHistoryOrders();
    setData(response || []);
    setLoading(false);
  }

  async function handleObtainIndicator(params) {
    setLoadingIndicator(true);

    const TEORIC_DISCOUNT = 5000;

    const bestSellingProduct =
      (await best_selling_product(params?.id_caja)) || [];

    const bestSellingCategory =
      (await best_selling_category(params?.id_caja)) || [];

    const bestSellingTable = (await best_selling_table(params?.id_caja)) || [];

    const bestSeller = (await best_seller(params?.id_caja)) || [];

    const bestSellerTip = (await best_seller_tip(params?.id_caja)) || [];

    const payType = (await pay_type(params?.id_caja)) || [];

    const totSale = (await tot_sale(params?.id_caja)) || [];

    const totApertura = (await tot_apertura(params?.id_caja)) || [];

    const totCover = (await tot_cover(params?.id_caja)) || {
      tot_cover: 0,
      descuenTot_cover: 0,
    };

    const totBestSellerTip = bestSellerTip.reduce((acc, el) => {
      return acc + el.pagopropina_pedido;
    }, 0);

    const totIncome =
      (totSale[0]?.tot_consumo || 0) +
      totCover?.tot_cover * 7000 +
      totBestSellerTip;

    const totCoverIncome = totCover?.tot_cover * 7000;

    const totDiscountIncomeTeoric = totCover?.tot_cover * TEORIC_DISCOUNT;

    const totUtilityCover = totCoverIncome - totCover?.descuenTot_cover;

    setLoadingIndicator(false);

    setDataIndicators({
      ...dataIndicators,
      bestSellingProduct,
      bestSellingCategory,
      bestSellingTable,
      bestSeller,
      bestSellerTip,
      payType,
      totSale,
      totApertura,
      totCover,
      totIncome,
      totCoverIncome,
      totDiscountIncomeTeoric,
      totUtilityCover,
    });
  }

  async function handleModal(row) {
    setSelected(row || init.selected);
    setOpenModal(!openModal);

    row && (await handleObtainIndicator(row));
  }

  useEffect(() => {
    handleObtain();
  }, []);

  return (
    <AppContext.Provider
      value={{
        /* Values */
        data,
        isLoadData: loading,
        idLoadDataIndicator: loadingIndicator,
        dataIndicators,
        dataExcel: dataSet,
        openModal,
        selected,
        /* Methods */
        handleModal,
        handleSearch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
