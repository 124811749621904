import axios from "axios";
import { config } from "../config";

import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

const BASE_URL = `${baseurl}/api/rifa`;

export const fetchRifa = () => {
  const url = `${BASE_URL}/find`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const fetchRifaPublic = (empSedeId) => {
  const url = `${BASE_URL}/public/find`;

  return axios
    .get(
      url,
      axiosHeader({
        empsede: empSedeId,
      })
    )
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const createRifa = (data) => {
  const url = `${BASE_URL}/create`;

  return axios
    .post(url, data, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const updateRifa = (data) => {
  const url = `${BASE_URL}/update`;

  return axios
    .put(url, data, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteRifa = (id) => {
  const url = `${BASE_URL}/delete/${id}`;

  return axios
    .delete(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * DETAIL RIFA
 */

export const fetchDetailRifa = () => {
  const url = `${BASE_URL}/detail/find`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const fetchDetailRifaByOne = (id) => {
  const url = `${BASE_URL}/detail/find/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const fetchDetailRifaByOnePublic = (id) => {
  const url = `${BASE_URL}/public/detail/find/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const createDetailRifa = (data) => {
  const url = `${BASE_URL}/detail/create`;

  return axios
    .post(url, data, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const updateDetailRifa = (id, data) => {
  const url = `${BASE_URL}/detail/update/${id}`;

  return axios
    .put(url, data, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteDetailRifa = (userId, rifaDetailId) => {
  const url = `${BASE_URL}/detail/delete/${userId}/${rifaDetailId}`;

  return axios
    .delete(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * RIFA DETAIL CUSTOMER
 */

export const fetchDetailCustomerRifaByOne = (id) => {
  const url = `${BASE_URL}/detail-customer/find/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const fetchDetailCustomerRifaByOnePublic = (id) => {
  const url = `${BASE_URL}/public/detail-customer/find/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const createDetailCustomerRifa = (data) => {
  const url = `${BASE_URL}/detail-customer/create`;

  return axios
    .post(url, data, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
export const updateDetailCustomerRifa = (data) => {
  const url = `${BASE_URL}/detail-customer/update`;

  return axios
    .put(url, data, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const createDetailCustomerRifaPublic = (data, empId, empSedeId) => {
  const url = `${BASE_URL}/public/detail-customer/create`;

  return axios
    .post(
      url,
      data,
      axiosHeader({
        empsede: empSedeId,
        id_emp: empId,
      })
    )
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
export const updateDetailCustomerRifaPublic = (data, empId, empSedeId) => {
  const url = `${BASE_URL}/public/detail-customer/update`;

  return axios
    .put(
      url,
      data,
      axiosHeader({
        empsede: empSedeId,
        id_emp: empId,
      })
    )
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteDetailCustomerRifaPublic = (
  rifaCustomerDetailId,
  empId,
  empSedeId
) => {
  const url = `${BASE_URL}/public/detail-customer/delete/${rifaCustomerDetailId}`;

  return axios
    .delete(
      url,
      axiosHeader({
        id_emp: empId,
        empsede: empSedeId,
      })
    )
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
