/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";

import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { Checkbox, Grid, Typography } from "@mui/material/";

import { Alert, Collapse } from "@mui/material";

import Input from "../../../../../components/Forms/Input";

import { Controller, useForm } from "react-hook-form";

import { Close } from "@mui/icons-material";

import { actionTypes } from "../../../../../redux/menu/actions";
import { actionTypesPlato } from "../../../../../redux/plato/actions";

import toast from "react-hot-toast";

import { useDispatch, useSelector } from "react-redux";

import {
  add_plato,
  update_plato,
} from "../../../../../services/plato.services";

import { get_area_pedidos } from "../../../../../services/areapedidos.services";
import { get_categoria } from "../../../../../services/categoria_plato.services";
import { get_menu } from "../../../../../services/menu.services";
import { getUser } from "../../../../../services/auth.services";

const ErrorMsg = ({ wrong, setWrong, message }) => {
  return (
    <Collapse in={wrong}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setWrong(false);
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
        severity="error"
      >
        {message}
      </Alert>
    </Collapse>
  );
};

const DialogCrud = () => {
  const dispatch = useDispatch();

  const { dataPlato, openAddEdit_plato, dataSelected } = useSelector(
    (state) => state.reducer_plato
  );

  // const state = useSelector((state) => state.reducer_plato);
  const { dataSelected_categoria } = useSelector((state) => state.reducer_menu);

  const [dataCategoria, setDataCategoria] = React.useState([]);

  const { control, reset, handleSubmit, watch } = useForm({
    defaultValues: {},
  });

  const [wrong, setWrong] = React.useState({ open: false, message: "" });
  const [areasPedidos, setAreaPedidos] = React.useState([]);

  const FLAG_TO_ADD = dataSelected.id_plato === "";

  const onOpen = (data) => {
    dispatch({
      type: actionTypesPlato.openModalAddEdit_plato,
      payload: data,
    });
  };
  const onLoad = (data) =>
    dispatch({
      type: actionTypesPlato.setLoading,
      payload: data,
    });

  const onGet = (data) =>
    dispatch({
      type: actionTypesPlato.getDataPlato,
      payload: data,
    });

  const onGetMenu = (data) =>
    dispatch({
      type: actionTypes.getDataMenu,
      payload: data,
    });

  const onSetDataSelected = (data) =>
    dispatch({
      type: actionTypesPlato.setDataSelected,
      payload: data,
    });

  const handleModal = (value) => {
    onOpen(value);

    if (!value) {
      onSetDataSelected({
        ...dataSelected,
        id_plato: "",
        id_categoriapla: "",
        nombre_plato: "",
        orden_plato: "",
        precio_plato: "",
        descripcion_plato: "",
        priority_plato: false,
        keep_price_plato: false,
        id_area: "",
        fondo_plato: "",
      });
    }
  };

  async function obtainDataMenu() {
    try {
      let response = await get_menu();
      onGetMenu({ load: false, data: response, err: false });
    } catch (err) {
      console.log(err);
      onGetMenu({ load: false, data: [], err: true });
    }
  }

  const handleAdd = async (data) => {
    try {
      onGet({ ...dataPlato, load: true, data: [], err: false });
      onLoad(true);
      handleModal(false);

      if (data.id_plato) {
        let { response, platos } = await update_plato(data);
        if (response.affectedRows === 1) {
          toast.success("Plato modificado correctamente");

          const filter = platos.filter(
            (el) =>
              el.id_categoriapla === dataSelected_categoria.id_categoriapla
          );

          onGet({ ...dataPlato, load: false, data: filter, err: false });
        } else {
          toast.error("No se pudo modificar el plato");
        }
      } else {
        let { platos } = await add_plato(data);

        obtainDataMenu();

        toast.success("Plato creada correctamente");

        if (dataSelected_categoria?.id_categoriapla) {
          const filter = platos.filter(
            (el) =>
              el.id_categoriapla === dataSelected_categoria.id_categoriapla
          );

          onGet({ ...dataPlato, load: false, data: filter, err: false });
        } else {
          onGet({ ...dataPlato, load: false, data: platos, err: false });
        }
      }

      onLoad(false);
    } catch (err) {
      console.error(err);
      toast.error("Hubo un error al crear el plato");
      handleModal(true);
      onLoad(false);
      onGet({ ...dataPlato, load: false, data: [], err: false });
    }
  };

  React.useEffect(() => {
    const handleGetAreaPedido = async () => {
      try {
        const response = await get_area_pedidos();

        setAreaPedidos(
          response.map((el) => ({
            value: el.id_area,
            label: el.nombre_area,
          }))
        );
      } catch (err) {
        console.log(err);
      }
    };

    handleGetAreaPedido();
  }, []);

  React.useEffect(() => {
    if (openAddEdit_plato) {
      const handleGetCategory = async () => {
        try {
          const response = await get_categoria();

          setDataCategoria(
            response.map((el) => ({
              value: el.id_categoriapla,
              label: el.nombre_categoriapla,
            }))
          );
        } catch (err) {
          console.log(err);
        }
      };

      handleGetCategory();
    }
  }, [openAddEdit_plato]);

  React.useEffect(() => {
    if (openAddEdit_plato) {
      reset({
        ...dataSelected,
        id_categoriapla: dataSelected_categoria.id_categoriapla || "",
        priority_plato:
          parseInt(dataSelected.ispriority_plato) === 1 ? true : false,
        keep_price_plato:
          parseInt(dataSelected.iskeep_price_plato) === 1 ? true : false,
      });
    }
  }, [openAddEdit_plato]);

  return (
    <>
      <Dialog
        open={openAddEdit_plato}
        onClose={() => handleModal(false)}
        fullWidth
        maxWidth={"sm"}
      >
        <form onSubmit={handleSubmit(handleAdd)} autoComplete="off">
          <DialogTitle>
            {dataSelected.id_plato !== ""
              ? "Editar"
              : `Agregar en ${dataSelected_categoria.nombre_categoriapla}`}
            <IconButton
              aria-label="close"
              onClick={() => handleModal(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <br />
            <ErrorMsg
              wrong={wrong.open}
              setWrong={(el) => setWrong({ ...wrong, open: el })}
              message={wrong.message}
            />

            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="orden_plato"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value}
                      onChange={onChange}
                      type="number"
                      title="Orden"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="nombre_plato"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value}
                      onChange={onChange}
                      type="text"
                      title="Nombre"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="precio_plato"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value}
                      onChange={onChange}
                      type="number"
                      title="Precio"
                    />
                  )}
                />
              </Grid>
              {!FLAG_TO_ADD && (
                <Grid item xs={12} sm={12} lg={12}>
                  <Controller
                    name="id_categoriapla"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        select
                        required
                        value={value || ""}
                        onChange={onChange}
                        type="select"
                        options={dataCategoria}
                        title="Categoría"
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={4} lg={4}>
                <Controller
                  name="id_area"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      fullWidth
                      required
                      value={value}
                      onChange={onChange}
                      type="select"
                      title="Área del producto"
                      options={areasPedidos}
                    />
                  )}
                />
              </Grid>

              {Number(watch("id_area")) === 1 && (
                <Grid item xs={12} sm={4} lg={4}>
                  <Controller
                    name="fondo_plato"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        fullWidth
                        required
                        value={value}
                        onChange={onChange}
                        type="select"
                        title="Fondos"
                        options={[
                          {
                            value: "Cuarto parrilla",
                            label: "Cuarto parrilla",
                          },
                          {
                            value: "Cuarto caliente",
                            label: "Cuarto caliente",
                          },
                          {
                            value: "Cuarto frío",
                            label: "Cuarto frío",
                          },
                        ]}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={12} lg={12}></Grid>

              <Grid item xs={12} sm={4} lg={4}>
                <Controller
                  name="priority_plato"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <Typography variant="inherit" component={"label"}>
                        Alta demora? {value}
                      </Typography>
                      <Checkbox
                        checked={value}
                        value={value}
                        onChange={onChange}
                        inputProps={{ "aria-label": "checkbox" }}
                      />
                    </>
                  )}
                />
              </Grid>

              {/**
               * SOLO PARA DH
               */}

              {(getUser().empresa.id_empsede === 5 ||
                getUser().empresa.id_empsede === 9) && (
                <Grid item xs={12} sm={4} lg={4}>
                  <Controller
                    name="keep_price_plato"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <Typography variant="inherit" component={"label"}>
                          Mantener precio {value}
                        </Typography>
                        <Checkbox
                          checked={value}
                          value={value}
                          onChange={onChange}
                          inputProps={{ "aria-label": "checkbox" }}
                        />
                      </>
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="descripcion_plato"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      type="textarea"
                      title="Descripción"
                      minRows={3}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="info"
              variant="text"
              onClick={() => {
                handleModal(false);
              }}
            >
              Salir
            </Button>
            <Button type="submit" color="success" variant="contained">
              Aceptar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default DialogCrud;
