import moment from "moment";

import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import {
  getv2_capacidad,
  getv2_capacidad_public,
} from "../../services/capacidad.services";

import { formatPhoneNumber } from "../../functions/formatPhoneNumber";

import { Alert, Button, Grid, InputAdornment } from "@mui/material";

import InputDefault from "../../atomic/atoms/input.atoms";
import {
  add_public_reserva,
  add_reserva,
  getTotDay,
  getTotDayPublic,
} from "../../services/reservas.services";
import DateReserva from "./DateReserva";
import ErrorMsg from "./errorMsgForm";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { toast } from "react-hot-toast";

dayjs.extend(utc);

const FormReserva = ({
  idUser,
  idEmp,
  idEmpSede,
  callback,
  isPublic = false,
}) => {
  const valuesForm = {
    id_reserva: "",
    id_usuario: "",
    id_cliente: "",
    region: "",
    comuna: "",
    fechareserva_reserva: "",
    horareserva_reserva: "",
    nombre_reserva: "",
    apellido_paterno_reserva: "",
    apellido_materno_reserva: "",
    telefono_reserva: "",
    correo_reserva: "",
    pax_reserva: "",
    estado_reserva: "confirmado",
    pograma_reserva: "",
    motivo_reserva: "",
    observacion_reserva: "",
    nombrecumpleanera_reserva: "",
    detalle_reserva: "",
    id_rservagrupa: "",
  };

  const [capacidad, setCapacidad] = useState([]);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const [currentCapacity, setCurrentCapacity] = useState(0);
  const [wrong, setWrong] = useState({ open: false, message: "" });

  const { control, reset, watch, handleSubmit } = useForm({
    defaultValues: valuesForm,
  });

  const USER_ID = idUser || 310;

  const capacityMaxDays =
    capacidad.find(
      (item) =>
        dayjs.utc(item.date_capacidadate).format("YYYY-MM-DD") ===
        watch("fechareserva_reserva")
    )?.quantity_capacidate || 0;

  const availableCapacity =
    capacityMaxDays - (currentCapacity + Number(watch("pax_reserva")));

  const handleReservar = async (data) => {
    setWrong({
      open: false,
      message: ``,
    });

    if (!data.fechareserva_reserva || data.fechareserva_reserva === "") {
      toast.error("La fecha de reserva es obligatorio (*)");

      setWrong({
        ...wrong,
        open: true,
        message: "La fecha de reserva es obligatorio",
      });
      return;
    }

    if (data.telefono_reserva.length < 9) {
      toast.error("El número telefónico tiene que tener 9 caracteres (*)");

      setWrong({
        ...wrong,
        open: true,
        message: "El número telefónico tiene que tener 9 caracteres",
      });
      return;
    }

    if (availableCapacity < 0) {
      toast.error("Lo sentimos no tenemos capacidad.", {
        position: "top-center",
      });

      setWrong({
        open: true,
        message: `No hay capacidad disponible para la fecha ${moment(
          data.fechareserva_reserva
        ).format("DD-MM-YYYY")}`,
      });
      return;
    }

    let response = [];

    if (isPublic) {
      response =  add_public_reserva(
        {
          ...data,
          id_usuario: USER_ID,
        },
        idEmpSede
      );
    } else {
      response =  add_reserva(
        {
          ...data,
          id_usuario: USER_ID,
        },
        idEmpSede
      );
    }

    toast.promise(response, {
      loading: "Cargando...",
      success: () => {
        setRedirect(true);
        return "Felicitaciones, tu reserva se ha realizado con éxito.";
      },
      error: "Ops! Ocurrió un error al realizar la reserva.",
    });

    reset(valuesForm);
  };

  useEffect(() => {
    if (redirect) {
      callback();
    }
  }, [redirect]);

  useEffect(() => {
    const handleObtainCurrentCapacity = async () => {
      try {
        console.log("idEmpSede", idEmpSede);
        if (watch("fechareserva_reserva") && idEmpSede > 0) {
          setLoading(true);

          let response = [];

          if (isPublic) {
            response = await getTotDayPublic(
              {
                fecha: watch("fechareserva_reserva"),
              },
              idEmpSede
            );
          } else {
            response = await getTotDay(
              {
                fecha: watch("fechareserva_reserva"),
              },
              idEmpSede
            );
          }

          setLoading(false);

          const cCapacity = response.reduce((acc, el) => {
            return (
              acc + (el.estado_reserva !== "cancelado" ? el.pax_reserva : 0)
            );
          }, 0);

          setCurrentCapacity(cCapacity);
        }
      } catch (err) {
        console.error(err);
      }
    };

    handleObtainCurrentCapacity();
  }, [watch("fechareserva_reserva")]);

  useEffect(() => {
    const handleObtainCapacidad = async () => {
      try {
        let response = [];

        if (isPublic) {
          response = await getv2_capacidad_public(idEmpSede);
        } else {
          response = await getv2_capacidad(idEmpSede);
        }

        setCapacidad(response);
      } catch (err) {
        console.error(err);
      }
    };

    if (idEmpSede > 0) {
      handleObtainCapacidad();
    }

    return () => setCapacidad([]);
  }, []);

  return (
    <form
      onSubmit={handleSubmit(handleReservar)}
      autoComplete="off"
      style={{ textAlign: "left" }}
    >
      <ErrorMsg
        wrong={wrong.open}
        setWrong={(el) => setWrong({ ...wrong, open: el })}
        message={wrong.message}
      />
      <Grid container spacing={1}>
        {watch("fechareserva_reserva") && (
          <Grid item xs={12} sm={12} lg={12}>
            <Alert severity="warning">
              {loading ? (
                "Cargando..."
              ) : (
                <>
                  Capacidad disponible:{" "}
                  {availableCapacity >= 0 ? availableCapacity : 0}
                </>
              )}
            </Alert>
          </Grid>
        )}

        <Grid item xs={12} sm={12} lg={12}>
          <div
            style={{
              textAlign: "center",
              marginBottom: "10px",
              background: "white",
              padding: "10px",
            }}
          >
            Selecciona tu fecha de reserva
          </div>
          <Controller
            name="fechareserva_reserva"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <DateReserva
                daysCapacidad={capacidad}
                value={value}
                setValue={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <Controller
            name="nombre_reserva"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                onChange={onChange}
                fullWidth
                placeholder={"Nombres"}
                variant="outlined"
                type="text"
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <Controller
            name="apellido_paterno_reserva"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                value={value}
                onChange={onChange}
                type="text"
                placeholder="Apellidos"
              />
            )}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} lg={6}>
          <Controller
            name="apellido_materno_reserva"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                value={value}
                onChange={onChange}
                type="text"
                placeholder="Apellidos Materno"
              />
            )}
          />
        </Grid> */}
        {/* <Grid item xs={12} sm={12} lg={12}>
          <Controller
            name="correo_reserva"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                value={value}
                onChange={onChange}
                type="email"
                placeholder="Correo electrónico"
              />
            )}
          />
        </Grid> */}
        <Grid item xs={12} sm={12} lg={12}>
          <Controller
            name="telefono_reserva"
            control={control}
            title="Teléfono"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                value={value}
                onChange={onChange}
                inputProps={{
                  maxLength: 9,
                  minLength: 9,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span style={{ fontSize: 13 }}>
                        {formatPhoneNumber()}
                      </span>
                    </InputAdornment>
                  ),
                }}
                type="tel"
                placeholder="Teléfono"
                onInput={(e) => {
                  parseInt(e.target.value) > 0
                    ? (e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 9))
                    : parseInt(e.target.value);
                }}
                min={9}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <Controller
            name="pax_reserva"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                required
                value={value}
                inputProps={{
                  maxLength: 3,
                  minLength: 1,
                }}
                onChange={onChange}
                type="number"
                placeholder="Cantidad de asistentes"
              />
            )}
          />
        </Grid>
        {/* <Grid item xs={12} sm={12} lg={12}>
          <Controller
            name="observacion_reserva"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                value={value}
                onChange={onChange}
                type="textarea"
                placeholder="¿Quieres dejarnos un comentario?"
                rows={5}
              />
            )}
          />
        </Grid> */}

        <Grid item xs={12} sm={12} lg={12}>
          <Button
            className="button-reserve"
            type="submit"
            disabled={availableCapacity < 0}
            variant="contained"
            fullWidth
            style={{
              color: "black",
              fontWeight: "bold",
              marginTop: "20px",
              display: "block",
              margin: "auto",
              opacity: availableCapacity < 0 ? 0.5 : 1,
            }}
          >
            ENVÍA TU RESERVA
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default FormReserva;
