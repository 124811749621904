import axios from "axios";
import { config } from "../config";

import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

const URI = `${baseurl}/api/report`;

export const getCustomer = (params) => {
  const url = `${URI}/get_customers?companyId=${params?.companyId}&filterMonth1=${params?.filterMonth[0]}&filterMonth2=${params?.filterMonth[1]}&program=${params?.program}&group=${params?.group}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
