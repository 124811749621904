/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import * as React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { Chip, Grid, Stack } from "@mui/material/";

import { Controller, useForm } from "react-hook-form";

import { Close } from "@mui/icons-material";

import InputDefault from "../../atomic/atoms/input.atoms";
import toast from "react-hot-toast";
import {
  createRifa,
  deleteRifa,
  updateRifa,
} from "../../services/rifa.services";

const defaultValues = {
  id_rifa: 0,
  name_rifa: "",
  amount_rifa: 0,
  drawdate_rifa: null,
  type_rifa: "",
  nameschool_rifa: "",
  namecourse_rifa: "",
  observations_rifa: "",
};

const ModalRifa = ({
  open,
  setOpen,
  rifaSelected,
  clearSelected,
  callBack,
}) => {
  const { control, reset, handleSubmit, watch } = useForm({
    defaultValues,
  });

  const [loadPage, setLoadPage] = React.useState(false);

  const handleAddEdit = async (data) => {
    try {
      setLoadPage(true);
      if (data.id_rifa) {
        await updateRifa(data);

        toast.success("Rifa modificado correctamente");
      } else {
        await createRifa(data);

        toast.success("Rifa creada correctamente");
      }

      await callBack();
    } catch (err) {
      toast.error("Hubo un error al crear la rifa");
    } finally {
      setLoadPage(false);
      setOpen(false);
    }
  };

  const handleDelete = async (data) => {
    try {
      setLoadPage(true);
      await deleteRifa(data.id_rifa);
      setLoadPage(false);

      toast.success("El producto fue eliminado correctamente");

      await callBack();

      setLoadPage(false);
      setOpen(false);
    } catch (err) {
      toast.error("Hubo un error al eliminar el producto");
      setLoadPage(false);
    }
  };

  React.useEffect(() => {
    if (open && rifaSelected?.id_rifa > 0) {
      console.log(rifaSelected);

      reset(rifaSelected);
    } else {
      reset(defaultValues);
      clearSelected();
    }
  }, [open]);

  if (rifaSelected?.delete) {
    return (
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth={"sm"}
      >
        <form onSubmit={handleSubmit(handleDelete)} autoComplete="off">
          <DialogTitle>¡Importante!</DialogTitle>
          <DialogContent>
            ¿Estas seguro de eliminar el producto{" "}
            <b className="uppercase text-red-600">{rifaSelected?.name_rifa}</b>?
          </DialogContent>
          <DialogActions>
            <Button
              color="inherit"
              variant="text"
              disabled={loadPage}
              onClick={() => {
                setOpen(false);
              }}
            >
              Salir
            </Button>
            <Button
              type="submit"
              color="error"
              variant="contained"
              disabled={loadPage}
            >
              Eliminar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth={"sm"}
      >
        <form onSubmit={handleSubmit(handleAddEdit)} autoComplete="off">
          <DialogTitle>
            {rifaSelected?.id_rifa ? "Editar rifa" : "Agregar rifa"}
            <IconButton
              aria-label="close"
              onClick={() => setOpen(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={1} className="!mt-2">
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="name_rifa"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputDefault
                      required
                      value={value || ""}
                      onChange={onChange}
                      type="text"
                      title="Nombre"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={6}>
                <Controller
                  name="amount_rifa"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputDefault
                      required
                      value={value || ""}
                      onChange={onChange}
                      type="number"
                      title="Cantidad de rifas"
                      step="any"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <Controller
                  name="drawdate_rifa"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputDefault
                      required
                      value={value || ""}
                      onChange={onChange}
                      type="date"
                      title="Fecha de sorteo"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="type_rifa"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputDefault
                      required
                      value={value || ""}
                      onChange={onChange}
                      type="select"
                      title="Tipo de rifa"
                      options={[
                        { value: "Gran Malón 2", label: "Gran Malón 2" },
                        { value: "Graduaciones", label: "Graduaciones" },
                      ]}
                    />
                  )}
                />
              </Grid>
              {watch("type_rifa") === "Graduaciones" && (
                <>
                  <Grid item xs={12} sm={6} lg={6}>
                    <Controller
                      name="nameschool_rifa"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <InputDefault
                          required
                          value={value || ""}
                          onChange={onChange}
                          type="text"
                          title="Nombre de colegio"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6}>
                    <Controller
                      name="namecourse_rifa"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <InputDefault
                          required
                          value={value || ""}
                          onChange={onChange}
                          type="text"
                          title="Curso"
                        />
                      )}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="observations_rifa"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputDefault
                      value={value || ""}
                      onChange={onChange}
                      type="textarea"
                      title="Descripción"
                      minRows={3}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="inherit"
              variant="text"
              disabled={loadPage}
              onClick={() => {
                setOpen(false);
              }}
            >
              Salir
            </Button>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              disabled={loadPage}
            >
              {rifaSelected?.id_rifa ? "Editar" : "Agregar"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ModalRifa;
