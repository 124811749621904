import { Print, Search } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { utils, writeFile } from "xlsx";
import InputDefault from "../../atomic/atoms/input.atoms";
import { getUser } from "../../services/auth.services";
import { useAppContext } from "./state.orderHistory";
import dayjs from "dayjs";
// import { Print } from "@mui/icons-material";
// import ReactExport from "react-data-export";
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const OrdersSearch = () => {
  const state = useAppContext();

  const { control, watch, handleSubmit } = useForm({
    defaultValues: {
      date_start: "",
      date_end: "",
    },
  });

  const NAME_COMPANY = getUser()?.empresa?.alias_empsede;
  const NOW_DATE = new Date().toISOString().slice(0, 10);

  const DATE =
    watch("date_start") && watch("date_end")
      ? `(${watch("date_start")}/${watch("date_end")})`
      : `(${NOW_DATE})`;

  const handlePrint = () => {
    const dataformatted = state.dataExcel.map((el) => {
      return {
        Fecha: dayjs(el.created_cierre_caja).format("DD/MM/YYYY"),
        "Nro Caja": el.id_caja,
        Efectivo: el?.totCashPay,
        Tarjeta: el?.totCardPay,
        Propinas: el?.totTip,
        Cover: el.totCover?.tot_cover || 0,
        "Consumo total": el?.totcierre_caja || 0,
      };
    });

    let wb = utils.book_new();
    let ws = utils.json_to_sheet(dataformatted);
    utils.book_append_sheet(wb, ws, `Reporte`);
    writeFile(wb, `${NAME_COMPANY || "REPORT"}-${DATE}.xlsx`);
  };

  return (
    <form onSubmit={handleSubmit(state.handleSearch)} autoComplete="off">
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Controller
            name="date_start"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                fullWidth
                required // This is the important part
                value={value}
                onChange={onChange}
                type="date"
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="date_end"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                fullWidth
                required // This is the important part
                value={value}
                onChange={onChange}
                type="date"
              />
            )}
          />
        </Grid>
        <Grid item xs={2} style={{ textAlign: "left" }}>
          <IconButton
            type="submit"
            size="large"
            color="primary"
            style={{ background: "#eee", marginRight: "10px" }}
          >
            <Search />
          </IconButton>
          <IconButton
            size="large"
            color="primary"
            style={{ background: "#eee" }}
            onClick={handlePrint}
          >
            <Print />
          </IconButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default OrdersSearch;
